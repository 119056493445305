import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { useEffect, useState } from 'react'

const HTMLInputFormatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
}).format

export default function ProjectDatePicker({
    dateType,
    initialDate,
    mutation,
}: {
    dateType: 'start_date' | 'target_date'
    initialDate: string
    mutation: any
}) {
    const parsedDate = initialDate ? parseDateToHTMLInput(new Date(initialDate)) : ''
    const [date, setDate] = useState(parsedDate)
    function parseDateToHTMLInput(date: Date) {
        const dateString = HTMLInputFormatter(date)
        const [month, day, year] = dateString.split('/')
        return `${year}-${month}-${day}`
    }
    function parseHTMLInputToDate(htmlDate: string) {
        return htmlDate ? new Date(htmlDate).toISOString() : ''
    }
    const handleChangeDueAt = () => {
        const selectedDate = parseHTMLInputToDate(date)
        mutation.mutate({
            [dateType]: selectedDate,
        })
    }

    useEffect(() => {
        setDate(parsedDate)
    }, [initialDate])
    return (
        <div className='flex gap-1'>
            <input
                className={cn(
                    'leading-none rounded p-1 w-full disabled:opacity-50',
                    mutation.isPending && 'pointer-events-none'
                )}
                type='date'
                id='due_at'
                name='due_at'
                value={date}
                onChange={({ target }) => setDate(target.value)}
            />
            {date !== parsedDate && (
                <Button onClick={handleChangeDueAt} className='h-fit p-1'>
                    Confirm
                </Button>
            )}
        </div>
    )
}

import { TASK_PRIORITIES } from 'tasksV2/constants/tasks'

export default function RecurringTaskPriority({
    priority,
    handleChange,
}: {
    priority?: string
    handleChange: (...args: any) => void
}) {
    return (
        <select
            className='border border-lightgrey dark:border-darkgrey rounded p-1 w-full'
            id='priority'
            name='priority'
            value={priority ?? ''}
            onChange={handleChange}
        >
            <option value=''>No Priority Selected</option>
            {TASK_PRIORITIES.map((priority: any) => (
                <option value={priority}>{priority}</option>
            ))}
        </select>
    )
}

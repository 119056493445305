import { useDebounce } from 'helpers'
import { useState } from 'react'
import { useGetOrders } from 'tasksV2/api/useQueries'

export default function OrderAssociationBuilder({
    associations,
    toggleAssociation,
}: {
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
}) {
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)
    const ordersSearchQuery = useGetOrders(debouncedSearch)
    const orders = ordersSearchQuery.data?.orders || []

    return (
        <div>
            <h2 className='font-bold mb-2'>Choose Orders To Associate</h2>
            <input
                className='focus:outline-none py-1 px-2 w-full border border-lightgrey dark:border-darkgrey rounded'
                id='search'
                name='search'
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                placeholder='Search for an order...'
            />
            <div className='flex flex-col gap-1 h-[200px] overflow-auto'>
                {ordersSearchQuery.isLoading ? (
                    <p className='p-2'>Loading...</p>
                ) : (
                    <>
                        {orders.map((order: any) => {
                            const customer = JSON.parse(order.customer || '{}')
                            return (
                                <div
                                    onClick={() =>
                                        toggleAssociation({
                                            resource_name: 'order',
                                            resource_id: order.id,
                                            readable_name: order.name,
                                        })
                                    }
                                    className='p-1 border border-lightgrey dark:border-darkgrey rounded flex items-center gap-2 cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                                >
                                    <div
                                        className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                            associations.find(
                                                (association: any) =>
                                                    association.resource_name === 'order' &&
                                                    association.resource_id === order.id
                                            )
                                                ? 'bg-accent1 dark:bg-darkaccent1'
                                                : ''
                                        }`}
                                    ></div>
                                    <span>
                                        {order.name} - {customer.first_name} {customer.last_name} ({customer.email})
                                    </span>
                                </div>
                            )
                        })}
                    </>
                )}
            </div>
        </div>
    )
}

import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { cn } from 'helpers'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function TasksTable({ tableData, columns, columnCount, showDepartment, showHeaders }: any) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const navigate = useNavigate()

    const table = useReactTable({
        columns,
        data: tableData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
        initialState: {
            columnVisibility: {
                department_id: showDepartment,
            },
        },
    })

    const columnInsert: string[] = []
    for (let index = 0; index < columnCount; index++) {
        columnInsert.push('1fr')
    }

    return (
        <table className='w-full bg-blue  p-2'>
            {showHeaders && (
                <thead className='sticky top-0'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                            key={headerGroup.id}
                            className={cn(
                                columnInsert
                                    ? `grid-cols-[600px_30px_150px_150px_150px_150px_150px_80px_150px_150px]`
                                    : 'grid-cols-10',
                                ' bg-lightgrey text-text1 px-2 dark:bg-darkbg2 dark:text-darktext1 sticky top-[54px]'
                            )}
                        >
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    className='text-start  px-1 font-semibold capitalize'
                                >
                                    {header.isPlaceholder ? null : (
                                        <div
                                            className={
                                                header.column.getCanSort()
                                                    ? 'cursor-pointer select-none flex gap-1'
                                                    : 'flex gap-1'
                                            }
                                            onClick={header.column.getToggleSortingHandler()}
                                            title={
                                                header.column.getCanSort()
                                                    ? header.column.getNextSortingOrder() === 'asc'
                                                        ? 'Sort ascending'
                                                        : header.column.getNextSortingOrder() === 'desc'
                                                          ? 'Sort descending'
                                                          : 'Clear sort'
                                                    : undefined
                                            }
                                        >
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                            {{
                                                asc: (
                                                    <div className='self-center min-h-[18px]'>
                                                        <CaretUpIcon />
                                                    </div>
                                                ),
                                                desc: (
                                                    <div className='self-center min-h-[18px]'>
                                                        <CaretDownIcon />
                                                    </div>
                                                ),
                                            }[header.column.getIsSorted() as string] ?? null}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
            )}
            <tbody className='w-full'>
                {table.getRowModel().rows.map((row) => {
                    const task: any = table.getRow(row.id).original
                    const due = task.string_due_at
                    const overdue = new Date(due) < new Date()
                    return (
                        <tr
                            key={row.id}
                            className={cn(
                                `w-full bg-bg1 dark:bg-darkbg1 text-black dark:text-offwhite border-b-[2px] px-2 border-lightgrey dark:border-gray-500 hover:bg-lightgrey cursor-pointer dark:hover:bg-darkbg2`,
                                overdue && ''
                            )}
                            onClick={() => navigate(`/tasksv2/${task.id}`)}
                        >
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <td style={{ width: cell.column.getSize() }} key={cell.id} className='p-1 '>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

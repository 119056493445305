import { Cross2Icon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { cn, useDebounce } from 'helpers'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function TasksScreenV2SearchContainer({ setDebouncedSearch }: { setDebouncedSearch: any }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [search, setSearch] = useState('')

    const debouncedSearch = useDebounce(search.trim(), 400)
    useEffect(() => {
        setDebouncedSearch(debouncedSearch)
    }, [debouncedSearch])

    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.set('page', '1')
            return prev
        })
        setSearch('')
    }

    return (
        <div
            className={cn(
                'w-full border h-fit  border-lightgrey  dark:border-darkgrey items-center rounded-md dark:bg-darkaccent'
            )}
        >
            <div className='w-full flex items-center h-fit  relative px-1 '>
                <input
                    type='text'
                    className='w-full bg-transparent dark:text-white focus:outline-none px-2'
                    placeholder={'I dont worky'}
                    value={search}
                    onChange={({ target }) => {
                        setSearch(target.value)
                    }}
                />
                <div className='self-center py-[2px]'>
                    <Button
                        disabled={search ? false : true}
                        variant='ghost'
                        onClick={handleClearSearch}
                        className={`${cn(
                            !search && 'text-darkgrey dark:text-darkgrey opacity-50 bg-bg1 dark:bg-darkbg1',
                            search && 'text-danger'
                        )} h-6 px-1 flex items-center `}
                    >
                        <Cross2Icon className='h-4 w-4' />
                    </Button>
                </div>
            </div>
            <div className='relative shrink-0 flex gap-[8px] justify-end items-center'></div>
        </div>
    )
}

import { TASK_STATUSES } from 'tasksV2/constants/tasks'

export default function ProjectStatus({
    projectId,
    status,
    mutation,
}: {
    projectId: number
    status?: string
    mutation: any
}) {
    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        mutation.mutate({
            id: projectId,
            status: target.value,
        })
    }
    return (
        <select
            className='border border-lightgrey dark:border-darkgrey rounded p-1 w-full'
            id='task_estimate_id'
            name='task_estimate_id'
            value={status}
            onChange={handleChange}
        >
            <option value=''>No Status Selected</option>
            {TASK_STATUSES.map((status: any) => (
                <option value={status}>{status}</option>
            ))}
        </select>
    )
}

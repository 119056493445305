// TODO: TYPES FOR ANY TYPE VALUES
import React from 'react'
import { SyntheticEvent, createContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { UserInit } from '../users/users.types'
import { OrderNoteInit } from '../orders/orders.types'
import { FP_SIGNATURE_LOGO_URL } from '../tasks/constants'
import clsx, { ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const activateSeth = () => {
    const seth = document.querySelector('#seth')! as HTMLElement
    const sethContainer = document.querySelector('#seth-container')! as HTMLElement
    const sethBubble = document.querySelector('#seth-bubble')! as HTMLElement
    sethContainer.classList.remove('hidden-seth')
    document.querySelector('#easter-egg')!.classList.add('hidden-seth')

    let stage = 3
    let left = 50
    const walkFunc = () => {
        left += 12
        if (stage < 11) {
            stage++
            seth.style.objectPosition = `${(stage / 11) * 100}% 0`
            sethContainer.style.left = `${left}px`
        } else {
            stage = 0
            seth.style.objectPosition = `${(stage / 11) * 100}% 0`
            sethContainer.style.left = `${left}px`
        }
    }
    let walk = setInterval(() => {
        walkFunc()
        if (left > window.innerWidth / 3) {
            sethBubble.classList.add('seth-bubble--active')
            clearInterval(walk)
            stage = 0
            seth.style.objectPosition = `${(stage / 11) * 100}% 0`
            setTimeout(() => {
                sethContainer.classList.add('hidden-seth')
            }, 3000)
        }
    }, 75)
}

// types
export type PriceSet = {
    presentment_money: {
        amount: string
        currency_code: string
    }
    shop_money: {
        amount: string
        currency_code: string
    }
}
export type Department = 'CS' | 'S' | 'A' | 'OF'
export class TimeHandler {
    timeZone: string
    timeZoneFormatter: Intl.DateTimeFormat
    utcFormatter: Intl.DateTimeFormat
    localFormatter: Intl.DateTimeFormat

    timeZoneModifier: string

    constructor() {
        this.timeZone = 'America/Chicago'
        this.timeZoneFormatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'America/Chicago',
        })
        this.utcFormatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'UTC',
        })
        this.localFormatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/Chicago',
        })

        const dateNow = new Date()
        const ISOTime = new Date(this.utcFormatter.format(dateNow)).getTime()
        const formattedTime = new Date(this.timeZoneFormatter.format(dateNow)).getTime()
        const timeString = ((formattedTime - ISOTime) / 60000 / 60).toFixed(2)
        const hours = timeString.split('.')[0]
        const parsedHours = hours.includes('-')
            ? `-${`0${hours.replace('-', '')}`.slice(-2)}`
            : `+${`0${hours}`.slice(-2)}`
        const minutes = `0${parseFloat(timeString.split('.')[1]) * 0.6}`.slice(-2)
        this.timeZoneModifier = `${parsedHours}:${minutes}`
    }

    getISOString(
        year: number,
        month: number,
        date: number,
        hours: number = 0,
        minutes: number = 0,
        seconds: number = 0
    ) {
        console.log(
            `${year}-${`0${month}`.slice(-2)}-${`0${date}`.slice(-2)}T${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(
                -2
            )}:${`0${seconds}`.slice(-2)}.000${this.timeZoneModifier}`
        )
        const timeSince1970 = new Date(
            `${year}-${`0${month}`.slice(-2)}-${`0${date}`.slice(-2)}T${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(
                -2
            )}:${`0${seconds}`.slice(-2)}.000${this.timeZoneModifier}`
        ).getTime()

        return new Date(timeSince1970).toISOString()
    }

    getLocalYMD(time: string) {
        const [month, day, year] = this.localFormatter.format(new Date(time)).split('/')
        return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`
    }
}
type vFetchOptionTypes = {
    method?: string
    contentType?: string
    body?: any
    cb?: Function
    catchCb?: Function
    abortController?: AbortController
    signal?: AbortSignal
    Authorization?: string | null
    'x-store-id'?: string | null
    skipToast?: boolean
    skipCatchToast?: boolean
    reThrowErr?: boolean
    reThrowMessage?: string
}
type relevantSearchWeighting = {
    property: string
    points: number
    minLength?: number
}
export type DayMap = {
    Sun: 'Sunday'
    Mon: 'Monday'
    Tue: 'Tuesday'
    Wed: 'Wednesday'
    Thu: 'Thursday'
    Fri: 'Friday'
    Sat: 'Saturday'
}
export type MonthMap = {
    Jan: 'January'
    Feb: 'February'
    Mar: 'March'
    Apr: 'April'
    May: 'May'
    Jun: 'June'
    Jul: 'July'
    Aug: 'August'
    Sep: 'September'
    Oct: 'October'
    Nov: 'November'
    Dec: 'December'
}

// maps

export const defaultColorsFP = {
    danger: '#B40000',
    scroll: '#1d212d',
    table1: 'undefined',
    table2: 'undefined',
    accent1: '#1e1e2a',
    accent2: '#ffa500',
    accent3: 'undefined',
    success: '#007a5c',
    warning: 'undefined',
    darkDanger: '#FF0000',
    darkScroll: '#ffa500',
    darkTable1: 'undefined',
    darkTable2: 'undefined',
    darkAccent1: '#42efd0',
    darkAccent2: '#ffa500',
    darkAccent3: 'undefined',
    darkSuccess: '#007a5c',
    darkWarning: 'undefined',
    textPrimary: '#4A4A4A',
    buttonPrimary: '#007a5c',
    textSecondary: '#FFFFFF',
    buttonSecondary: 'undefined',
    darkTextPrimary: '#EEEFEA',
    scrollBackground: '#ffffff',
    backgroundPrimary: '#FFFFFF',
    darkButtonPrimary: 'undefined',
    darkTextSecondary: '#05111c',
    backgroundSecondary: '#FFFFFF',
    darkButtonSecondary: 'undefined',
    darkScrollBackground: '#13212d',
    darkBackgroundPrimary: '#1d212d',
    darkBackgroundSecondary: '#05111c',
}

// color settings correlate with css variables/tailwind config. Alter carefully
export const colorSettingsReadable: any = {
    backgroundPrimary: 'Background Primary',
    backgroundSecondary: 'Background Secondary',
    buttonPrimary: 'Button Primary',
    buttonSecondary: 'Button Secondary',
    textPrimary: 'Text Primary',
    textSecondary: 'Text Secondary',
    accent1: 'Accent One',
    accent2: 'Accent Two',
    accent3: 'Accent Three',
    table1: 'Table One',
    table2: 'Table Two',
    warning: 'Warning',
    danger: 'Danger',
    success: 'Success',
    scroll: 'Scroll Bar',
    scrollBackground: 'Scroll Bar Background',
    darkBackgroundPrimary: 'Dark Background Primary',
    darkBackgroundSecondary: 'Dark Background Secondary',
    darkButtonPrimary: 'Dark Button Primary',
    darkButtonSecondary: 'Dark Button Secondary',
    darkTable1: 'Dark Table One',
    darkTable2: 'Dark Table Two',
    darkTextPrimary: 'Dark Text Primary',
    darkTextSecondary: 'Dark Text Secondary',
    darkAccent1: 'Dark Accent One',
    darkAccent2: 'Dark Accent Two',
    darkAccent3: 'Dark Accent Three',
    darkWarning: 'Dark Warning',
    darkDanger: 'Dark Danger',
    darkSuccess: 'Dark Success',
    darkScroll: 'Dark Scroll Bar',
    darkScrollBackground: 'Dark Scroll Bar Background',
}
export const colorSettingsLight = [
    'backgroundPrimary',
    'backgroundSecondary',
    'buttonPrimary',
    'buttonSecondary',
    'textPrimary',
    'textSecondary',
    'accent1',
    'accent2',
    'accent3',
    'table1',
    'table2',
    'success',
    'warning',
    'danger',
    'scroll',
    'scrollBackground',
]
export const colorSettingsDark = [
    'darkBackgroundPrimary',
    'darkBackgroundSecondary',
    'darkButtonPrimary',
    'darkButtonSecondary',
    'darkTextPrimary',
    'darkTextSecondary',
    'darkAccent1',
    'darkAccent2',
    'darkAccent3',
    'darkTable1',
    'darkTable2',
    'darkSuccess',
    'darkWarning',
    'darkDanger',
    'darkScroll',
    'darkScrollBackground',
]
export const dayMap = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
}
export const dayMapArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
export const locationMap: any = {
    '649429017': 'FactoryPure - 13814 Lookout Rd.',
    '68461527229': 'Dropship',
}
export const monthMap = {
    Jan: 'January',
    Feb: 'February',
    Mar: 'March',
    Apr: 'April',
    May: 'May',
    Jun: 'June',
    Jul: 'July',
    Aug: 'August',
    Sep: 'September',
    Oct: 'October',
    Nov: 'November',
    Dec: 'December',
}

// regexes
export const emailRegex = /[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+/
export const poBoxRegex = /^[pP]\.?[Oo]\.?\W*[boxBOX]*\W*\d+$/
export const fpdashRegex = /https:\/\/fpdash.com\/tasks\/\d+\n|https:\/\/fpdash.com\/tasks\/\n/
// v1 /(\w{1,2}:\d+\n)+/
export const taskIdRegex = /\n?(\w{1,2}:\d+\n)+|\n?(\w{1,2}:\d+)+$/g
export const secondsRegex = /:\d{2}\s/
export const notNumberRegex = /[^\d\.]/g
export const notIntegerRegex = /[^\d]/g
// export const fpdashTaskSplittingRegex = /https:\/\/fpdash.com\/tasks\/\d+/
// export const fpdashAndTypesRegex = /https:\/\/fpdash.com\/tasks\/\n([A-Z]{1,2}:\d+\n)+\n/

// This is probably a better timestamp regex, but I'm not changing it until there is an issue.
// v1 /\({0,1}\d{1,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,4}\W\d{1,2}[\-\/\\\:]\d{1,2} {0,1}[aAmMpP]{2}\){0,1} [A-Z]{2,3}\n{0,1} {0,1}\w{0,2}\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} [A-Z]{1,2}\n{0,2}/
// v2 /\({0,1}\d{1,2}\W\d{1,2}\W\d{1,4}\W\d{1,2}\W\d{1,2} {0,1}[aAmMpP]{2}\){0,1} [A-Z\-]{2,3}\n{0,1} {0,1}\w{0,2}\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} [A-Z]{1,2}\n{0,2}/
// v3 /\({0,1}\d{1,2}\W\d{1,2}\W\d{1,4}\W\d{1,2}\W\d{1,2}(\W\d{1,2}){0,1} {0,1}[aAmMpP]{2}\){0,1} [A-Z\-]{2,3}\){0,1} {0,1}[A-Z\-]{0,2}\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} [A-Z]{1,2}\n{0,2}/
// v4 /\({0,1}\d{1,2}\W\d{1,2}\W\d{1,4}\W\d{1,2}\W\d{1,2}(\W\d{1,2}){0,1} {0,1}([aAmMpP]{2}){0,1}\){0,1} [A-Z\-]{2,3}\){0,1} {0,1}[A-Z\-]{0,2}\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} [A-Z]{1,2}\n{0,2}/
// v5 /\({0,1}\d{1,2}\W\d{1,2}\W\d{1,4}\W\d{1,2}\W\d{1,2}(\W\d{1,2}){0,1} {0,1}([aAmMpP]{2}){0,1}\){0,1} [aA-zZ\-]+\){0,1} {0,1}[A-Z\-]{0,2}\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} [A-Z]{1,2}\n{0,2}/
// v6 /\({0,1}\d{1,2}\W\d{1,2}\W\d{1,4}\W\d{1,2}\W\d{1,2}(\W\d{1,2}){0,1} {0,1}([aAmMpP]{2}){0,1}\){0,1} [aA-zZ\-]+\){0,1} {0,1}\d?[A-Z\-]+\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} \d?[A-Z]+\n{0,2}/g
// v7 /\({0,1}(\d{1,2}\W){2}\d{1,4}\W\d{1,2}\W\d{1,2}(\W\d{1,2}){0,1} {0,1}([aAmMpP]{2}){0,1}\){0,1} {0,1}[aA-zZ\-]+\){0,1} {0,1}\d*[A-Z\-]*\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} \d?[A-Z]+\n{0,2}/g
export const timestampRegex =
    /\({0,1}(\d{1,2}[w\-\/]){2}\d{1,4}\W\d{1,2}\W\d{1,2}(\W\d{1,2}){0,1} {0,1}([aAmMpP]{2}){0,1}\){0,1} {0,1}[aA-zZ\-]+\){0,1} {0,1}\d*[A-Z\-]*\n{0,2}|\d{0,2}[\-\/\\]\d{1,2}[\-\/\\]\d{1,2} \d?[A-Z]+\n{0,2}/g

// v1 / [a-zA-Z\-]{2} {0,1}[a-zA-Z]{0,2}/
// v2 / [a-zA-Z\-]+ {0,1}\d?[a-zA-Z]{0,2}/
export const authorAndTypeRegex = /(\)| )[a-zA-Z\-]+ {0,1}\d*[a-zA-Z]{0,2}/
// v1 /\d{1,2}\W\d{1,2}\W\d{1,2}\W\d{1,2}:\d{1,2}[ampAMP]{0,2}|\d{1,2}\W\d{1,2}\W\d{1,2}/
export const dateTimeRegex =
    /\d{1,4}\W\d{1,2}\W\d{1,4}\W\d{1,2}:\d{1,2}(:\d{1,2}){0,1}[ampAMP]{0,2}|\d{1,4}\W\d{1,2}\W\d{1,4}/
export const departments = {
    CS: { name: 'Customer Service', color: 'text-[rgb(0,136,204)]' },
    S: { name: 'Sales', color: 'text-[#41a112]' },
    A: { name: 'Accounting', color: 'text-purple-300' },
    OF: { name: 'Order Fulfillment', color: 'text-fire' },
}

// type checking
export function isISOString(string: string) {
    const isIsoRegex = /\d{4}-\d{2}-\d{2}[ T]{1}\d{2}:\d{2}:\d{2}\.{1}\d{3}Z/gm
    const match = String(string).match(isIsoRegex)
    if (!match) return false
    return match[0] === string
}
export function isJSONString(str: string) {
    try {
        return typeof JSON.parse(str) === 'object'
    } catch (e) {
        return false
    }
}
export const isPrimObject = (arg: any) =>
    typeof arg === 'object' &&
    !(arg instanceof Date) &&
    !Array.isArray(arg) &&
    arg !== null &&
    !(arg instanceof Uint8Array) &&
    !(arg.current instanceof HTMLElement)

const isRef = (arg: any) =>
    typeof arg === 'object' &&
    !(arg instanceof Date) &&
    !Array.isArray(arg) &&
    arg !== null &&
    !(arg instanceof Uint8Array) &&
    !(arg instanceof HTMLElement) &&
    arg.current instanceof HTMLElement

// dont know how to categorize these
export function closePopout(
    e: SyntheticEvent | MouseEvent,
    classNames: string[],
    setShowPopout: Function = () => {},
    value: any = false,
    cleanup = () => {}
) {
    let element = e.target as HTMLElement
    let inPopout = false
    ;(() => {
        do {
            for (let name of classNames) {
                if (element.classList.contains(name)) return (inPopout = true)
            }
            element = element.parentNode as HTMLElement
            if (!element) break
        } while (element.parentNode)
    })()
    if (!inPopout) {
        setShowPopout(value)
        cleanup()
    }
    return !inPopout
}
export function closePopoutAdvanced(
    e: SyntheticEvent | MouseEvent,
    classNames: string[],
    excludedClassNames?: string[],
    setShowPopout: Function = () => {},
    value = false,
    cleanup = () => {}
) {
    let element = e.target as HTMLElement
    let inPopout = false
    let inExcluded = false
    ;(() => {
        do {
            if (excludedClassNames) {
                for (let name of excludedClassNames) {
                    if (element.classList.contains(name)) return (inExcluded = true)
                }
            }
            for (let name of classNames) {
                if (element.classList.contains(name)) return (inPopout = true)
            }
            element = element.parentNode as HTMLElement
            if (!element) break
        } while (element.parentNode)
    })()
    if (inExcluded) return inExcluded
    if (!inPopout) {
        setShowPopout(value)
        cleanup()
    }
    return !inPopout
}
export const findImage = (id: number) => {
    const gid = `gid://shopify/Product/${id}`
    return window.IMAGE_MAP[gid]
}
export function getBusinessDays(startDate: string | Date, endDate: string | Date) {
    // this is not really accurate.

    let current = startDate instanceof Date ? startDate : new Date(startDate)
    const end = endDate instanceof Date ? endDate : new Date(endDate)

    let businessDays = 0

    // monday holidays will only be right every 7 years
    const holidays: any = {
        1: {
            1: "New Year's Day",
            16: 'Martin Luther King, Jr. Day',
        },
        2: {
            20: "President's Day",
        },
        4: {
            29: 'Memorial Day',
        },
        5: {
            19: 'Juneteenth',
        },
        6: {
            4: 'Independence Day',
        },
        8: {
            4: 'Labor Day',
        },
        9: {
            4: 'Columbus Day',
        },
        10: {
            10: 'Veterans Day',
            23: 'Thanksgiving Day',
            24: 'Day After Thanksgiving',
        },
        11: {
            24: 'Christmas Eve',
            25: 'Christmas',
            31: "New Year's Eve",
        },
    }
    for (let i = 0; i < 12; i++) {
        if (!holidays[i]) holidays[i] = {}
    }

    while (current.getTime() < end.getTime()) {
        if (current.getDay() !== 0 && current.getDay() !== 6 && !holidays[current.getMonth()][current.getDate()]) {
            businessDays++
        }
        current = new Date(current.setDate(current.getDate() + 1))
    }
    return businessDays
}

// change this to object and pass all options
export function sendToast(resOrErr: any, autoClose: any = 4000) {
    toast(resOrErr.message, {
        position: 'bottom-right',
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
    })
}
export function sendToastErr(resOrErr: any) {
    toast.error(resOrErr.message, {
        position: 'bottom-right',
        autoClose: 12000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
    })
}
export function sendToastWarning(resOrErr: any) {
    toast.warning(resOrErr.message, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: localStorage.getItem('theme') === 'dark' ? 'dark' : 'light',
    })
}
export function typify(arg: any, typeName: string = 'Unnamed') {
    const arrayTypeRegex = /\(\{([\sa-zA-Z:\w\"\(\)\[\]])+\}\)\[\]/gm
    const arrayTypeRegex2 = /\([a-zA-Z\:\w \|\{\n\t\,\"\[\]\}]+\)\[\]/gm
    let string = 'type '
    let defaultString = 'export const '

    function step(pathLength: number) {
        const tab = '\t'
        const steps = []
        let i = 0
        while (i < pathLength) {
            steps.push(tab)
            i++
        }
        return steps.join('')
    }

    function dig(arg: any, path: string[], argName: string) {
        string += string !== 'type ' ? `${step(path.length)}${argName}: ` : `${argName}Init = `
        defaultString +=
            defaultString === 'export const '
                ? `default${argName}:${argName}Init = `
                : `${step(path.length)}${argName}: `
        if (isPrimObject(arg)) {
            string += '{\n'
            defaultString += '{\n'
            Object.keys(arg)
                .sort((a, b) => sortByAlphanumeric(a, b))
                .forEach((k, i) => {
                    dig(arg[k], [...path, k], k)
                })
            string += `${step(path.length)}}`
            defaultString += `${step(path.length)}}`
        } else if (isRef(arg)) {
            string += 'any /* This must useRef(null)  */'
            defaultString += 'undefined'
        } else if (arg instanceof Date) {
            string += 'Date'
            defaultString += 'Date'
        } else if (Array.isArray(arg)) {
            const elTypesInArray: string[] = []
            arg.forEach((el) => {
                if (el === null && !elTypesInArray.includes('null')) elTypesInArray.push('null')
                else if (!elTypesInArray.includes(typeof el) && el !== null && !Array.isArray(el) && !isPrimObject(el))
                    elTypesInArray.push(typeof el)
                else if (typeof el === 'object' && !elTypesInArray.includes('object')) {
                    function digArrayObjects(arg: any, path: string[], argName: string) {
                        let objectString = ''
                        let defaultObjectString = ''
                        objectString += argName ? `${step(path.length)}${argName}: ` : ''
                        defaultObjectString = objectString
                        if (isPrimObject(arg)) {
                            objectString += '{\n'
                            Object.keys(arg)
                                .sort((a, b) => sortByAlphanumeric(a, b))
                                .forEach((k, i) => {
                                    objectString += digArrayObjects(arg[k], [...path, k], k)
                                })
                            objectString += `${step(path.length)}}`
                        } else if (arg instanceof Date) {
                            objectString += 'Date'
                        } else if (Array.isArray(arg)) {
                            const elTypesInArrayObjectArray: string[] = []
                            arg.forEach((el) => {
                                if (el === null && !elTypesInArrayObjectArray.includes('null'))
                                    elTypesInArrayObjectArray.push('null')
                                else if (
                                    !elTypesInArrayObjectArray.includes(typeof el) &&
                                    el !== null &&
                                    !Array.isArray(el) &&
                                    !isPrimObject(el)
                                )
                                    elTypesInArrayObjectArray.push(typeof el)
                                else if (
                                    typeof el === 'object' &&
                                    !elTypesInArrayObjectArray.includes(digArrayObjects(el, path, '').trim())
                                ) {
                                    elTypesInArrayObjectArray.push(digArrayObjects(el, path, '').trim())
                                    objectString +=
                                        elTypesInArrayObjectArray.length > 0
                                            ? `(${elTypesInArrayObjectArray.join(' | ')})[]`
                                            : `any[]`
                                }
                            })
                            objectString +=
                                elTypesInArrayObjectArray.length > 0
                                    ? `(${elTypesInArrayObjectArray.join(' | ')})[]`
                                    : 'any[]'
                        } else if (arg === null) {
                            objectString += 'null'
                        } else if (typeof arg === 'number') {
                            objectString += `${typeof arg} | undefined`
                        } else if (typeof arg === 'function') {
                            objectString += 'any'
                        } else {
                            objectString += `${typeof arg}`
                        }
                        objectString += '\n'
                        return objectString
                    }

                    const objectString: string = digArrayObjects(el, [...path], '').trim()
                    if (!elTypesInArray.includes(objectString) && objectString) elTypesInArray.push(objectString)
                }
            })
            string += elTypesInArray.length > 0 ? `(${elTypesInArray.join(' | ')})[]` : `any[]`
            defaultString += elTypesInArray.length > 0 ? `(${elTypesInArray.join(' | ')})[]` : `any[]`
        } else if (arg === null) {
            string += 'null'
            defaultString += 'null'
        } else if (typeof arg === 'number') {
            string += `${typeof arg} | undefined`
            defaultString += `undefined`
        } else if (typeof arg === 'function') {
            string += 'any'
            defaultString += '() => { console.log("replace me with a function" )}'
        } else {
            string += `${typeof arg}`
            defaultString += `${typeof arg}`
        }
        string += '\n'
        defaultString += '\n'
    }
    dig(arg, [], typeName)

    function formatDefaultString(defaultString: string) {
        while (defaultString.match(arrayTypeRegex)) {
            defaultString = defaultString
                .trim()
                .replaceAll('string', JSON.stringify(''))
                .replaceAll('number | undefined', 'undefined')
                .replaceAll(arrayTypeRegex, '[]')
                .replaceAll('boolean', 'false')
        }
        while (defaultString.match(arrayTypeRegex2)) {
            defaultString = defaultString
                .trim()
                .replaceAll('string', JSON.stringify(''))
                .replaceAll('number | undefined', 'undefined')
                .replaceAll(arrayTypeRegex2, '[]')
        }
        return defaultString
            .trim()
            .replaceAll('string', JSON.stringify(''))
            .replaceAll('number | undefined', 'undefined')
            .replaceAll('Date', 'new Date()')
            .replaceAll('any[]', '[]')
            .replaceAll('boolean', 'false')
            .split('\n')
            .map((s) => (s[s.length - 1] === '{' ? s + '\n' : s + ',\n'))
            .join('')
            .trim()
            .slice(0, -1)
    }

    return (() => {
        console.log('\n')
        console.log([string.trim(), formatDefaultString(defaultString)].join('\n\n'))
        console.log('\n')
        return [string.trim(), formatDefaultString(defaultString)]
    })()
}
export const useDebounce = (value: any, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)
        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])
    return debouncedValue
}
export const useDebouncedFunction = (dependancies: any[], fn: () => void | Promise<void>, delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => {
            fn()
        }, delay)
        return () => {
            clearTimeout(handler)
        }
    }, [dependancies])
}

// sorting
export function sortByAlphanumeric(a: any, b: any, property?: string | any, property2?: string | any) {
    function numbersOnly(input: any) {
        return String(input).replaceAll(/[^\d|\.]/gm, '')
    }
    if (!property && !property2) {
        if ([a, b].some((v) => !v)) return !a ? -1 : 1
        if ([a, b].every((v) => typeof v === 'number')) return a < b ? -1 : 1
        if (
            [a, b].some((v) => typeof v === 'number') ||
            (typeof a === 'string' && !isNaN(Number(a[0]))) ||
            (typeof b === 'string' && !isNaN(Number(b[0])))
        ) {
            if (numbersOnly(a) === numbersOnly(b)) {
                if (Number(numbersOnly(a)) === a) return -1
                else {
                    const upperA = String(a).toUpperCase()
                    const upperB = String(b).toUpperCase()
                    let i = 0
                    while (upperA[i] === upperB[i] && upperA[i]) i++
                    if (isNaN(upperA.charCodeAt(i))) return -1
                    return upperA.charCodeAt(i) < upperB.charCodeAt(i) ? -1 : 1
                }
            }
            if (!numbersOnly(a) || !numbersOnly(b)) return !!numbersOnly(a) ? -1 : 1
            return Number(numbersOnly(a)) < Number(numbersOnly(b)) ? -1 : 1
        }
        const upperA = String(a).toUpperCase()
        const upperB = String(b).toUpperCase()
        let i = 0
        while (upperA[i] === upperB[i] && upperA[i]) i++
        if (isNaN(upperA.charCodeAt(i))) return -1
        return upperA.charCodeAt(i) < upperB.charCodeAt(i) ? -1 : 1
    }
    let i = 0
    if (a[property] && b[property] && [a[property], b[property]].every((v) => typeof v === 'number'))
        return a[property] < b[property] ? -1 : 1
    if (a[property] === b[property] && property2) {
        if ([a[property2], b[property2]].some((v) => v === null || v === undefined))
            return a[property2] !== null && a[property2] !== undefined ? -1 : 1
        if ([a[property2], b[property2]].some((v) => typeof v === 'number')) return a > b ? -1 : 1
        while (String(a[property2])[i] === String(b[property2])[i] && String(a[property2])[i]) i++
        if (isNaN(a[property2].charCodeAt(i))) return -1
        return a[property2].toUpperCase().charCodeAt(i) < b[property2].toUpperCase().charCodeAt(i) ? -1 : 1
    }
    while (String(a[property])[i] === String(b[property])[i] && a[property][i]) i++
    if (isNaN(String(a[property]).charCodeAt(i))) return -1
    if (isNaN(String(b[property]).charCodeAt(i))) return 1
    return String(a[property]).toUpperCase().charCodeAt(i) < String(b[property]).toUpperCase().charCodeAt(i) ? -1 : 1
}
export function sortRelevantSearch(
    search: any,
    a: any,
    b: any,
    reverse = false,
    weightSet?: relevantSearchWeighting[]
) {
    const lSearch = Array.isArray(search) ? search.map((phrase) => String(phrase).toLowerCase()) : search.toLowerCase()

    if (weightSet) {
        let aScore = 0
        let bScore = 0
        function score(phrase: string) {
            for (let weighting of weightSet!) {
                const { property, points, minLength = 0 } = weighting
                if (phrase.length >= minLength) {
                    const aString = String(a[property] === undefined ? '' : a[property]).toLowerCase()
                    const bString = String(b[property] === undefined ? '' : b[property]).toLowerCase()
                    const aIndex = aString.indexOf(phrase) >= 0 ? aString.indexOf(phrase) : Infinity
                    const bIndex = bString.indexOf(phrase) >= 0 ? bString.indexOf(phrase) : Infinity
                    aScore += points / (aIndex + 1 || 1)
                    bScore += points / (bIndex + 1 || 1)
                    // double points if it is an exact match
                    if (aString === phrase) aScore += points
                    if (bString === phrase) bScore += points
                }
            }
        }
        if (Array.isArray(search)) for (let phrase of search) score(phrase)
        else score(lSearch)

        if (aScore > bScore) return -1
        if (aScore < bScore) return 1
        if (aScore === bScore) {
            for (let weighting of weightSet) {
                const { property } = weighting
                let i = 0
                const aString = String(a[property] === undefined ? '' : a[property]).toLowerCase()
                const bString = String(b[property] === undefined ? '' : b[property]).toLowerCase()
                const aIndex = aString.indexOf(lSearch) >= 0 ? aString.indexOf(lSearch) : Infinity
                const bIndex = bString.indexOf(lSearch) >= 0 ? bString.indexOf(lSearch) : Infinity
                if (aIndex >= 0 || bIndex >= 0) {
                    if (aIndex < bIndex) return -1
                    if (aIndex > bIndex) return 1
                }
                if (typeof a[property] === 'number' && typeof b[property] === 'number')
                    return a < b ? (reverse ? 1 : -1) : reverse ? -1 : 1
                if (
                    a[property] &&
                    b[property] &&
                    Number(a[property]) !== 0 &&
                    Number(b[property]) !== 0 &&
                    !isNaN(Number(a[property])) &&
                    !isNaN(Number(b[property]))
                )
                    return Number(a[property]) < Number(b[property]) ? (reverse ? 1 : -1) : reverse ? -1 : 1
                while (aString[i] === bString[i] && aString[i]) i++
                if (isNaN(aString.charCodeAt(i))) return -1
                if (isNaN(bString.charCodeAt(i))) return 1
                if (aString.toUpperCase().charCodeAt(i) < bString.toUpperCase().charCodeAt(i)) return -1
                if (aString.toUpperCase().charCodeAt(i) > bString.toUpperCase().charCodeAt(i)) return 1
            }
        }
        return -1
    } else {
        if (typeof a === 'number' && typeof b === 'number') return a < b ? (reverse ? 1 : -1) : reverse ? -1 : 1
        if (a && b && Number(a) !== 0 && Number(b) !== 0 && !isNaN(Number(a)) && !isNaN(Number(b)))
            return Number(a) < Number(b) ? (reverse ? 1 : -1) : reverse ? -1 : 1
        const aString = String(a).toLowerCase()
        const bString = String(b).toLowerCase()
        const aIndex = aString.indexOf(lSearch) >= 0 ? aString.indexOf(lSearch) : Infinity
        const bIndex = bString.indexOf(lSearch) >= 0 ? bString.indexOf(lSearch) : Infinity
        if (aIndex < bIndex) return -1
        if (aIndex > bIndex) return 1
        let i = 0
        while (aString[i] && aString[i] === bString[i]) i++
        if (!aString[i]) return 1
        if (!bString[i]) return -1
        return (aString.charCodeAt(i) < bString.charCodeAt(i) ? -1 : 1) * (reverse ? -1 : 1)
    }
}

// formatting
export const buildEmailFromTemplate = (template: any, objects: any) => {
    try {
        const replaceTemplatedValues = (str: string, newlineToBr: boolean) => {
            if (newlineToBr) {
                str = str.replaceAll('\n', '<br>')
            }
            for (let i = 0; i < str.length - 1; i++) {
                const check = `${str[i]}${str[i + 1]}`
                if (check === '{{') {
                    const start = i
                    i = i + 2
                    let object = ''
                    let key = ''
                    while (str[i] !== '.') {
                        object += str[i]
                        i++
                    }
                    i++
                    while (str[i] !== '}') {
                        key += str[i]
                        i++
                    }
                    const end = i + 2
                    str = str.slice(0, start) + objects[object.trim()][key.trim()] + str.slice(end)
                    i = start
                }
            }
            return str
        }
        const subject = replaceTemplatedValues(template.subject, false)
        const greeting = replaceTemplatedValues(template.greeting, false)
        const body = replaceTemplatedValues(template.html, true)
        const html = greeting + '\n\n' + body

        return { subject, body, greeting, html }
    } catch (err) {
        console.error(err)
        return { subject: '', body: '', greeting: '', html: '' }
    }
}
export const createOrderNotesArray = (orderNotes: string | null = '', userList: any[]) => {
    if (orderNotes) {
        let unparsedNotes = orderNotes.replace(fpdashRegex, '').replaceAll(taskIdRegex, '').trim()
        let parsedNotes = []

        while (unparsedNotes.match(timestampRegex)) {
            if (unparsedNotes.match(timestampRegex)) {
                const timestamp = unparsedNotes.match(timestampRegex)![0]
                const endOfNote = unparsedNotes.indexOf(timestamp)
                const note = unparsedNotes.slice(0, endOfNote)
                const authorAndType = timestamp.match(authorAndTypeRegex)
                    ? timestamp.match(authorAndTypeRegex)![0].replace(')', '').trim().split(' ')
                    : ''
                const parsedDate = formatCSTTimestampToDate(timestamp)

                const fromName =
                    departments[authorAndType[1] as Department]?.name ||
                    userList.find(
                        (u) =>
                            [(u.first_name, u.last_name)]
                                .filter((v: any) => v)
                                .map((w: any) => w[0].toUpperCase())
                                .join('') === authorAndType[0] || u.user_id === authorAndType[0]
                    )?.store_name ||
                    `error: ${authorAndType[1]}`
                parsedNotes.push({
                    from: authorAndType[1]
                        ? {
                              name: fromName,
                              color: departments[authorAndType[1] as Department]?.color || (fromName ? '' : 'text-red'),
                          }
                        : { name: 'Order Note' },
                    note: note.trim(),
                    created_at: parsedDate,
                    author: authorAndType[0],
                })
                unparsedNotes = unparsedNotes.replace(note + timestamp, '')
            }
        }
        if (unparsedNotes.trim())
            parsedNotes.push({
                from: { name: 'Order Note' },
                note: unparsedNotes.trim(),
                created_at: undefined,
                author: undefined,
            })
        return parsedNotes
            .filter((n) => n.note)
            .map((n) => {
                return { ...n, normalized_event_type: 'Order Note' }
            }) as OrderNoteInit[]
    } else {
        return [] as OrderNoteInit[]
    }
}
export function formatDateToLocale(DateOrISO: Date | string) {
    const date: Date = DateOrISO instanceof Date ? DateOrISO : new Date(DateOrISO)
    if (isNaN(date.getTime())) return `Undefined date:${JSON.stringify(DateOrISO)}`

    return `${Object.keys(monthMap)[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${date
        .toLocaleString()
        .replace(/\d{0,2}\/\d{0,2}\/\d{0,4}, /, '')
        .replace(/:\d{2} /, ' ')}`
}
export function formatISOString(DateOrISO: string | Date, remove = 'millisecond') {
    const ISO = DateOrISO instanceof Date ? DateOrISO.toISOString() : DateOrISO
    if (remove.includes('millisecond'))
        return String(ISO)
            .replaceAll(/\.\d+Z|T|undefined/g, ' ')
            .trim()
    if (remove.includes('second'))
        return String(ISO)
            .replaceAll(/\:\d+\.\d+Z|T|undefined/g, ' ')
            .trim()
    if (remove.includes('minute'))
        return String(ISO)
            .replaceAll(/\:\d+\:\d+\.\d+Z|T|undefined/g, ' ')
            .trim()
    if (remove.includes('hour'))
        return String(ISO)
            .replaceAll(/\d+\:\d+\:\d+\.\d+Z|T|undefined/g, ' ')
            .trim()
    if (remove.includes('day'))
        return String(ISO)
            .replaceAll(/\d+T\d+\:\d+\:\d+\.\d+Z|T|undefined/g, ' ')
            .trim()
    if (remove.includes('month'))
        return String(ISO)
            .replaceAll(/\d+-\d+T\d+\:\d+\:\d+\.\d+Z|T|undefined/g, ' ')
            .trim()
}
export function formatCSTTimestampToDate(timestamp: string) {
    const timeHandler = new TimeHandler()
    const unparsedDateArray = timestamp.match(dateTimeRegex)![0].replaceAll('/', '-').split(' ')[0].split('-')
    const unparsedTime = timestamp.match(dateTimeRegex)
        ? timestamp.match(dateTimeRegex)![0].replaceAll('/', '-').split(' ')[1] || '00:00 AM'
        : '00:00 AM'

    const year = Number(`20${unparsedDateArray[2].slice(-2)}`)
    const month = `0${unparsedDateArray[0]}`.slice(-2)
    const day = `0${unparsedDateArray[1]}`.slice(-2)

    const unparsedHour = Number(unparsedTime.split(':')[0])
    const hour =
        (Number(unparsedHour) == 12 && unparsedTime.toLowerCase().includes('am')) || Number(unparsedHour) == 0
            ? '00'
            : `0${unparsedHour + (unparsedTime.toLowerCase().includes('pm') && unparsedHour < 12 ? 12 : 0)}`.slice(-2)
    const minute = unparsedTime.split(':')[1].slice(0, 2)
    const parsedDate = timeHandler.getISOString(year, parseInt(month), parseInt(day), parseInt(hour), parseInt(minute))
    return new Date(parsedDate)
}
export function formatISOToCSTTimestamp(time: Date | string = new Date()) {
    const ISO = time instanceof Date ? time.toISOString() : time
    const centralTime = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    }).format(new Date(ISO))
    const [date, timeString] = centralTime.split(', ')
    const [month, day, year] = date.split('/')
    const [clockTime, hour12Identifier] = timeString.split(' ')
    const [hour, minute] = clockTime.split(':')

    return `(${month}-${day}-${year} ${hour}:${minute}${hour12Identifier})`
}
export const formatMoney = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
}).format
export function formatNumberInput(input: string) {
    const onlyNums = input.replaceAll(notNumberRegex, '').replaceAll('..', '.')
    const output = onlyNums.match(/[\d]*\.[\d]+\.|\d+\.\./g)
    if (output) return output[0].slice(0, -1)
    else return onlyNums
}
export function parseResObject(resObject: any) {
    if (!resObject) return sendToast({ message: 'resObject not defined.' })
    const parsedObject = resObject
    function traverseObjectAndConvertISOToDate(object: any) {
        if (Array.isArray(object)) object.forEach((el, i) => traverseObjectAndConvertISOToDate(object[i]))
        else if (isPrimObject(object))
            Object.keys(object).forEach((key) => {
                if (isPrimObject(object[key])) return traverseObjectAndConvertISOToDate(object[key])
                if (isISOString(object[key])) return (object[key] = new Date(object[key]))
                if (isJSONString(object[key])) return (object[key] = JSON.parse(object[key]))
            })
    }
    Object.keys(parsedObject).forEach((key) => {
        if (isJSONString(parsedObject[key])) {
            parsedObject[key] = JSON.parse(parsedObject[key])
            traverseObjectAndConvertISOToDate(parsedObject[key])
        } else if (isISOString(parsedObject[key])) parsedObject[key] = new Date(parsedObject[key])
        else parsedObject[key] = parsedObject[key]
    })
    return parsedObject
}
export const getDataSizeMB = (arg: string | Uint8Array | any) => {
    if (Array.isArray(arg)) {
        const size: number = arg.reduce((cur: number, acc: number) => cur + getDataSizeMB(acc), 0)
        return size
    }
    if (arg instanceof Uint8Array) return arg.length / 1024 / 1024
    if (isPrimObject(arg) && arg.data instanceof File) return arg.data.size / 1024 / 1024
    if (isPrimObject(arg) && arg.data instanceof Uint8Array) return arg.data.length / 1024 / 1024
    const data: string = typeof arg === 'string' ? arg : JSON.stringify(arg)
    return data.length / 1024 / 1024
}
export const numBytesToString = (arg: string | number) => {
    let num = Number(arg)
    if (isNaN(num))
        return sendToast({
            message: `numBytesToString Error: ${arg} is not a number. Remove all characters except for the numbers.`,
        })
    const prefixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']
    let i = 0
    while (num / 1024 > 1) {
        num /= 1024
        i++
    }
    return `${Number(num.toFixed(2))} ${prefixes[i]}`
}
export const userShopifyRepName = (user: UserInit) =>
    user.shopify_rep_name || `${user.first_name.trim()} ${user.last_name.trim()}`.trim()

// fetches
/**
 *
 * Path is the only required value. All other values will be reassigned a default value, in the case one is not provided.\
 * If the cb option is left blank, res.json() will be returned.
 * If the catchCb arg is left blank, any caught error will be returned.
 *
 * ## Examples
 *
 * ### /task/:id GET request example
 * vFetch (\"/tasks/123"\)\
 * returns task WHERE id = 123
 *
 * ### /task/:id DELETE request example
 * vFetch ("/tasks/123", {\
 * &nbsp;&nbsp;method: "delete"\
 * })\
 * deletes task WHERE id = 123
 *
 * ## <u>Custom Callbacks</u>
 *
 * Response and error message handling is automatic, but either can be passed a callback function to handle error messages or return specific values.
 *
 * ### processingTimesScreen.tsx getMessages() request example
 * vFetch ("/processingTimes", {\
 * &nbsp;&nbsp;cb: (res:any) => res.processingTimes || [] as ProcessingTimesRow[],\
 * &nbsp;&nbsp;catchCb: () => [],\
 * &nbsp;&nbsp;abortController: abortController\
 * })\
 * If success: returns an array of processingTimesRows\
 * If error: returns an empty array
 */
export async function vFetch(path: string, options: vFetchOptionTypes = {}) {
    const defaultCallback = async (resOrErr: any) => resOrErr
    const reThrowCallback = async (err: any) => {
        return Promise.reject(err)
    }
    const {
        body,
        skipToast,
        skipCatchToast,
        abortController,
        signal,
        method = 'GET',
        cb = defaultCallback,
        catchCb = defaultCallback,
        reThrowErr = false,
        reThrowMessage = '',
    } = options
    // DO NOT REMOVE THE CHECK FOR "skip"!! ----- this needs to include "skip" as a potential option so that content-type doesn't get added for request that use FormData objects
    const contentType =
        options && options.contentType
            ? options.contentType === 'skip'
                ? undefined
                : options.contentType
            : 'application/json'
    const Authorization =
        options?.Authorization === null ? null : options?.Authorization || localStorage.getItem('session_token')
    const store_id =
        options?.['x-store-id'] || options?.['x-store-id'] === null ? null : localStorage.getItem('store_id')
    const headers: any = { Authorization, 'x-store-id': store_id }
    if (contentType) headers['Content-Type'] = contentType

    const MODE = process.env.REACT_APP_MODE
    const API_URL =
        MODE === 'production'
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : MODE === 'development'
              ? process.env.REACT_APP_DEVELOPMENT_API_URL
              : process.env.REACT_APP_LOCAL_API_URL

    return await fetch(`${API_URL}${path}`, {
        headers,
        method,
        body,
        signal: signal ?? abortController?.signal,
    })
        .then(async (res) => {
            const refreshToken = res.headers.get('X-Refresh-Token')
            if (refreshToken) {
                handleLocalStorage.setItem('session_token', refreshToken)
            }
            if (reThrowErr && !res.ok) {
                const status = res.status
                const parsedRes = await res.json()
                parsedRes.status = status
                parsedRes.skipToast = skipToast
                throw parsedRes
            }
            if (reThrowErr && res.status >= 500) {
                const parsedRes = await res.json()
                throw parsedRes
            }
            return res.json()
        })
        .then(async (res) => {
            if (res.logout) {
                handleLocalStorage.removeItem('session_token')
            }
            if (!res.success) {
                throw new Error(`${res.message || 'Something went wrong: request failed'}`)
            }
            if (!skipToast && res.message) {
                sendToast(res)
            }
            return await cb(res as any)
        })
        .catch(async (err) => {
            if (
                !reThrowErr &&
                err.message !== 'The user aborted a request.' &&
                err.message !== 'signal is aborted without reason' &&
                !skipCatchToast
            ) {
                sendToast(err)
            }
            if (reThrowErr) {
                if (err.logout) {
                    handleLocalStorage.removeItem('session_token')
                }
                return await reThrowCallback(err)
            }
            return await catchCb(err as any)
        })
}

export const handleLocalStorage = {
    setItem: (name: string, value: string) => {
        localStorage.setItem(name, value)
        window.dispatchEvent(new Event('storage'))
    },
    getItem: (name: string) => {
        localStorage.getItem(name)
    },
    removeItem: (name: string) => {
        localStorage.removeItem(name)
        window.dispatchEvent(new Event('storage'))
    },
}

export const useLocalStorageKeyWatcher = (key: string) => {
    const [value, setValue] = useState(localStorage.getItem(key))

    useEffect(() => {
        function listenToStorage() {
            setValue(localStorage.getItem(key))
        }

        window.addEventListener('storage', listenToStorage)

        return () => window.removeEventListener('storage', listenToStorage)
    }, [])

    return value
}

export async function fetchAllUsers(setUserList?: Function, hasRole: string[] = [], doesNotHaveRole: string[] = []) {
    return await vFetch(
        `/v1/users/reducedPermissions?roles=${[...hasRole, ...doesNotHaveRole.map((r) => '!NOT_' + r)]}`,
        {
            cb: (res: any) => {
                if (res.success) {
                    const resUsers = res.users.map((u: any) => parseResObject(u))
                    if (setUserList) setUserList(resUsers)
                    return resUsers
                }
            },
        }
    )
}
export async function fetchActiveUsers(setUserList?: Function, hasRole: string[] = [], doesNotHaveRole: string[] = []) {
    return await vFetch(
        `/v1/users/reducedPermissions?roles=${[
            ...hasRole,
            ...doesNotHaveRole.map((r) => '!NOT_' + r),
            '!NOT_suspended',
        ]}`,
        {
            cb: (res: any) => {
                if (res.success) {
                    const resUsers = res.users.map((u: any) => parseResObject(u))
                    if (setUserList) setUserList(resUsers)
                    return resUsers
                }
            },
        }
    )
}
export const signatureHTMLString = (user: any) => {
    return `<div>
        <p><strong>${user.firstName} with FactoryPure</strong><br/>
        <strong>Customer Service: 888-978-4993 (M-F 8-5 PM CT)</strong><br/>
        <strong>Pre-Sales After Hours: 888-999-1522</strong></p>
        <img 
            src="${FP_SIGNATURE_LOGO_URL}" 
            alt="FactoryPure Logo"
            width="120"
            height="40"
        />
        <div>
            <u>
                <span style="font-size:10pt;color:#0563c1">
                    <a style="color:#1155cc" href="mailto:sales@factorypure.com" rel="noopener" target="_blank">
                        sales@factorypure.com
                    </a>
                </span>
            </u>
            <span style="font-size:10pt;color:#1f497d">&nbsp;&nbsp;</span>
            <span style="font-size:10pt">|&nbsp;&nbsp;</span>
            <u>
                <span style="font-size:10pt;color:#0563c1">
                    <span style="color:#0563c1">
                        <a style="color:#1155cc" title="FactoryPure" href="http://www.factorypure.com/" rel="noopener" target="_blank">
                            www.factorypure.com
                        </a>
                    </span>
                </span>
            </u>
        </div>
    </div>`
}

// components
export const LoadingGear = ({ color = 'orange', width = '300px', height = '300px' }) => {
    return (
        <svg
            style={{ width, height }}
            version='1.1'
            id='L2'
            xmlns='http://www.w3.org/2000/svg'
            x='0px'
            y='0px'
            viewBox='0 0 100 100'
            enableBackground='new 0 0 100 100'
        >
            <circle fill='none' stroke={color} strokeWidth='4' strokeMiterlimit='10' cx='50' cy='50' r='48' />
            <line
                fill='none'
                strokeLinecap='round'
                stroke={color}
                strokeWidth='4'
                strokeMiterlimit='10'
                x1='50'
                y1='50'
                x2='85'
                y2='50.5'
            >
                <animateTransform
                    attributeName='transform'
                    dur='2s'
                    type='rotate'
                    from='0 50 50'
                    to='360 50 50'
                    repeatCount='indefinite'
                />
            </line>
            <line
                fill='none'
                strokeLinecap='round'
                stroke={color}
                strokeWidth='4'
                strokeMiterlimit='10'
                x1='50'
                y1='50'
                x2='49.5'
                y2='74'
            >
                <animateTransform
                    attributeName='transform'
                    dur='15s'
                    type='rotate'
                    from='0 50 50'
                    to='360 50 50'
                    repeatCount='indefinite'
                />
            </line>
        </svg>
    )
}
export class ErrorBoundary extends React.Component<{}, { hasError: boolean; sentAlert: boolean; eventList: any[] }> {
    constructor(props: any) {
        super(props)
        this.state = {
            hasError: false,
            sentAlert: false,
            eventList: [],
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.addEvent)
    }

    componentWillUnmount() {
        // this.clearError()
        window.removeEventListener('click', this.addEvent)
    }

    // clearError() {
    //     this.setState((state: any) => {
    //         return {
    //             ...state,
    //             hasError: false,
    //         }
    //     })
    // }
    static getDerivedStateFromError(error: any) {
        return { hasError: true }
    }

    addEvent = (event: any) => {
        this.setState((state: any) => {
            if (this.state.hasError && !this.state.sentAlert) {
                vFetch(`/v1/slack`, {
                    method: 'POST',
                    body: JSON.stringify({
                        message: `LAST ELEMENT CLICKED: ${event.target.outerHTML}`,
                    }),
                })
                return {
                    ...state,
                    sentAlert: true,
                    eventList: [...state.eventList, event.target.outerHTML],
                }
            }
            return {
                ...state,
                eventList: [...state.eventList, event.target.outerHTML],
            }
        })
    }

    componentDidCatch(error: any, info: any) {
        const eventList = this.state.eventList
        vFetch(`/v1/slack`, {
            method: 'POST',
            body: JSON.stringify({
                message: `${error.message}\n\n${
                    window.location.href
                }\n\nLAST 5 ELEMENTS CLICKED BEFORE ERROR:\n${eventList
                    .slice(-5)
                    .map((el) => ` - ${el}`)
                    .join('\n')}`,
            }),
        })
    }

    render() {
        if ((this.state as any).hasError) {
            // You can render any custom fallback UI
            return (this.props as any).fallback
        }

        return (this.props as any).children
    }
}
const WebSocketContext = createContext(null)
export { WebSocketContext }
export const WebsocketProvider = ({ children }: any) => {
    const user = useSelector((state: any) => state.user)
    const sessionToken = localStorage.getItem('session_token')
    const [ws, setWs] = useState<WebSocket | null>(null)
    const [wsInterval, setWsInterval] = useState(setInterval(() => {}, 1000))
    const [pathname, setPathname] = useState('')
    const [usersOnPath, setUsersOnPath] = useState([])
    const [notifications, setNotifications] = useState([])
    const [refreshingSocket, setRefreshingSocket] = useState(true)

    useEffect(() => {
        if (user && user.user_id) {
            vFetch(`/v1/notifications?user_id=${user.user_id}`, {
                cb: (res: any) => {
                    if (res.success) setNotifications(res.notifications)
                },
            })
        }
        if (user && user.user_id && refreshingSocket) {
            const tempSocket = new WebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}?user_id=${user.user_id}`)
            tempSocket.onmessage = (msg) => {
                const data = JSON.parse(msg.data || '{}')
                if (data.notifications) {
                    setNotifications(data.notifications)
                }
                if (data.usersOnPath) {
                    setUsersOnPath(data.usersOnPath)
                }
            }
            tempSocket.onopen = () => {
                setWs(tempSocket)
                setRefreshingSocket(false)
            }
            return () => {
                if (tempSocket.readyState === 2 || tempSocket.readyState === 3) {
                    tempSocket.close()
                }
            }
        }
    }, [user, refreshingSocket])

    useEffect(() => {
        if (pathname.length > 0 && ws && user) {
            if (ws.readyState === 2 || ws.readyState === 3) {
                setRefreshingSocket(true)
            } else {
                ws.send(JSON.stringify({ pathname, heartbeat: false }))
                setWsInterval((previousInterval) => {
                    clearInterval(previousInterval)
                    return setInterval(() => {
                        ws.send(JSON.stringify({ pathname, heartbeat: true }))
                    }, 5000)
                })
            }
        }
    }, [ws, pathname])

    return (
        <WebSocketContext.Provider
            value={
                {
                    ws,
                    setPathname,
                    usersOnPath,
                    setNotifications,
                    notifications,
                } as any
            }
        >
            {children}
        </WebSocketContext.Provider>
    )
}

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

type FormattedErrorFields = {
    error: any
    message: string
    serverErrMessage: string
    queryKey?: any[]
    clientErrMessage: string
    pathname: string
    functionName: string
    success?: boolean
    variables?: any
    errno?: any
}

export function formatErrorBlocks({ formattedErrorFields }: { formattedErrorFields: FormattedErrorFields }) {
    const {
        error,
        message,
        serverErrMessage = '',
        clientErrMessage = '',
        pathname,
        functionName,
        success,
        variables,
        queryKey,
        errno,
    } = formattedErrorFields

    return [
        // {
        //     type: 'header',
        //     text: {
        //         type: 'plain_text',
        //         text: ' A dashboard error has been caught :sunglasses:',
        //         emoji: true,
        //     },
        // },
        // {
        //     type: 'context',
        //     elements: [
        //         {
        //             type: 'mrkdwn',
        //             text: '(akokko@factorypure.com) Fri Sep 13, 7:28 PM',
        //         },
        //     ],
        // },
        {
            type: 'divider',
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `*Client Message:* \n>${clientErrMessage} - ${serverErrMessage}`,
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `*Raw Message:* \n>${message}`,
            },
        },
        // errno
        //     ? {
        //           type: 'section',
        //           text: {
        //               type: 'mrkdwn',
        //               text: `*Error No:* \n>${errno}`,
        //           },
        //       }
        //     : null,
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `*Path name:* \n<https://fpdash.com${pathname}|${pathname}>`,
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `*Function name:* \n${functionName}`,
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `*${queryKey ? 'Query Key' : 'Variables'}:* \n${
                    queryKey ? JSON.stringify(queryKey) : JSON.stringify(variables)
                }`,
            },
        },
    ]
}
export function formatSlackMessage({ formattedErrorFields }: { formattedErrorFields: FormattedErrorFields }) {
    const {
        error,
        message,
        serverErrMessage = '',
        clientErrMessage = '',
        pathname,
        functionName,
        success,
        variables,
        queryKey,
        errno,
    } = formattedErrorFields

    const slackMessage = `Client Error Message: ${formattedErrorFields.clientErrMessage} - ${
        formattedErrorFields.serverErrMessage
    } \nRaw Message - \`${formattedErrorFields.message}\` \nPath name - ${
        formattedErrorFields.pathname
    } \nFunction Name - ${formattedErrorFields.functionName} \n${queryKey ? 'Query Key' : 'Variables'} - ${
        queryKey ? JSON.stringify(formattedErrorFields.queryKey) : JSON.stringify(formattedErrorFields.variables)
    } \n\n https://fpdash.com${formattedErrorFields.pathname}`

    return slackMessage
}

export function looseIndexOf(arr: any[], value: any) {
    for (var i = 0; i < arr.length; ++i) {
        if (arr[i] == value) {
            return i
        }
    }
    return -1
}

export const capitalize = (str: string) => {
    if (str.length === 0) {
        return str
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
}

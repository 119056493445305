import { InventoryEmailType } from 'edi/EDIScreens.types'
import { vFetch } from '../../helpers'

// Inventory Email Service
export const getInventoryEmails = async ({
    emailIdParam,
    filters,
    search,
}: {
    emailIdParam?: string | undefined
    filters: any | undefined
    search: string | undefined
}) => {
    const queryParams = [
        emailIdParam ? `emailId=${emailIdParam}` : null,
        filters ? `filters=${encodeURIComponent(JSON.stringify(filters))}` : null,
        search ? `search=${encodeURIComponent(search)}` : null,
    ]
        .filter((v) => v)
        .join('&')

    const response = await vFetch(`/v1/email/inventoryEmail?${queryParams}`, {
        reThrowErr: true,
        cb: (res: any) => {
            res.inventoryEmails = res.inventoryEmails.map((inventoryEmail: InventoryEmailType) => {
                return {
                    ...inventoryEmail,
                    text_html: JSON.parse(inventoryEmail.text_html),
                    text_plain: JSON.parse(inventoryEmail.text_plain),
                    updated_items: JSON.parse(inventoryEmail.updated_items),
                    update_failures: JSON.parse(inventoryEmail.update_failures),
                }
            })
            return res
        },
    })
    return response
}
export const manualInventorySheetUpload = async ({
    keyMap,
    headingString,
    src,
    vendors,
    emailId,
}: {
    keyMap: any
    headingString?: string
    src: string
    vendors: string[]
    emailId: string | number
}) => {
    return await vFetch('/v2/products/inventorySheets', {
        method: 'POST',
        body: JSON.stringify({ keyMap, headingString, src, vendors, emailId }),
        reThrowErr: true,
    })
}
export const getFiles = async (files: any) => {
    return await vFetch(`/v2/email/inventoryEmailFiles?fileSources=${encodeURIComponent(JSON.stringify(files))}`, {
        method: 'GET',
        reThrowErr: true,
    })
}
export const saveInventoryMap = async (keyMap: any, companyIds: string[] | number[], headingString: string) => {
    return await vFetch('/v1/email/inventoryEmailMaps', {
        method: 'PUT',
        body: JSON.stringify({ keyMap, companyIds, headingString }),
        reThrowErr: true,
    })
}
export const addCompanySenderAddress = async (from: string, companyId: string | number) => {
    return await vFetch('/v1/email/senderAddresses', {
        method: 'POST',
        body: JSON.stringify({ from, companyId }),
        reThrowErr: true,
    })
}
export const deleteCompanySenderAddress = async (from: string, companyId: string | number) => {
    return await vFetch('/v1/email/senderAddresses', {
        method: 'DELETE',
        body: JSON.stringify({ from, companyId }),
        reThrowErr: true,
    })
}
export const deleteInventoryEmailMap = async (id: string | number) => {
    return await vFetch('/v1/email/inventoryEmailMaps', {
        method: 'DELETE',
        body: JSON.stringify({ id }),
        reThrowErr: true,
    })
}
export const updateEmailStatus = async (emailId: string | number, status: string) => {
    return await vFetch('/v1/email/inventoryEmail', {
        method: 'PUT',
        body: JSON.stringify({ emailId, status }),
        reThrowErr: true,
    })
}
export const updateVariantFields = async (variant: any) => {
    return await vFetch('/v2/variants/identifiers', {
        method: 'PUT',
        body: JSON.stringify(variant),
        reThrowErr: true,
    })
}

export const getProductSetupPrivateEvents = async () => {
    const response = await vFetch(`/v1/productSetupEventsPrivate/latest`, {
        reThrowErr: true,
    })
    return response
}
export const getProducts = async (filters: any, search: string) => {
    const queryParams = [
        filters.id?.length > 0 ? `id=${filters.id.join(',')},` : null,
        filters.statuses?.length > 0 ? `statuses=${filters.statuses.join(',')},` : null,
        filters.vendors?.length > 0 ? `vendors=${encodeURIComponent(filters.vendors.join(','))},` : null,
        search ? `search=${search}` : null,
        filters?.missing_info ? `missing_info=${filters.missing_info}` : null,
        filters?.ignore_auto_updates ? `ignore_auto_updates=${filters.ignore_auto_updates}` : null,
        filters?.after ? `after=${filters.after}` : null,
        filters?.before ? `before=${filters.before}` : null,
    ]
        .filter((v) => v)
        .join('&')

    const res = await vFetch(`/v2/products?${queryParams}`, {
        cb: (res: any) => {
            res.products.forEach((product: any) => {
                product.has_identifying_info = false
                if (product.custom_supplier_model_number || product.custom_supplier_sku || product.custom_upc) {
                    product.has_identifying_info = true
                }
            })
            return res
        },
        reThrowErr: true,
    })
    return res
}
export const groupedProductIdsQuery = async () => {
    const res = await vFetch(`/v2/products/productGroups/groupedProductIds`, {
        reThrowErr: true,
    })
    return res
}
export const getVariants = async (product_id: any) => {
    const res = await vFetch(`/v2/variants?product_id=${product_id}`, {
        reThrowErr: true,
    })
    return res
}

// productGroups

export const getProductGroups = async (filters: any) => {
    const queryParams = [
        filters?.groupId ? `group_id=${filters.groupId}` : null,
        filters?.after ? `after=${filters.after}` : null,
        filters?.before ? `before=${filters.before}` : null,
        filters?.search ? `search=${filters.search}` : null,
    ]
        .filter((v) => v)
        .join('&')

    const res = await vFetch(`/v2/products/productGroups?${queryParams}`, {
        reThrowErr: true,
    })
    return res
}
export const getProductGroupDetail = async (filters: any) => {
    const queryParams = [
        filters.groupId ? `group_id=${filters.groupId}` : null,
        filters.productId ? `product_id=${filters.productId}` : null,
    ]
        .filter((v) => v)
        .join('&')
    const res = await vFetch(`/v2/products/productGroups/detail?${queryParams}`, {
        cb: (res: any) => {
            if (res.group.length < 1) {
                return { ...res }
            }
            const group = res.group[0]
            group.products = JSON.parse(group.products)
            group.options = JSON.parse(group.options)
            group.product_option_values = JSON.parse(group.product_option_values)
            return { ...res, group: [group] }
        },
        reThrowErr: true,
    })
    return res
}

export const getOptions = async (filters: any) => {
    const queryParams = [filters.groupId ? `group_id=${filters.groupId}` : null].filter((v) => v).join('&')

    const res = await vFetch(`/v2/products/productGroups/options?${queryParams}`, {
        reThrowErr: true,
    })
    return res
}
// export const getProductOptions = async (filters: any) => {
//     const queryParams = [filters.groupId ? `group_id=${filters.groupId}` : null].filter((v) => v).join('&')

//     const res = await vFetch(`/v2/products/productGroups/productOptions?${queryParams}`, {
//         reThrowErr: true,
//     })
//     return res
// }

// product group mutations

export const createGroup = async (group_name: string) => {
    return await vFetch('/v2/products/productGroups/detail', {
        method: 'POST',
        body: JSON.stringify({ group_name }),
        reThrowErr: true,
    })
}
export const updateGroup = async (groupId: string | number, name: string) => {
    return await vFetch('/v2/products/productGroups/detail', {
        method: 'PUT',
        body: JSON.stringify({ groupId, name }),
        reThrowErr: true,
    })
}
export const deleteGroup = async (groupId: string | number) => {
    return await vFetch('/v2/products/productGroups/detail', {
        method: 'DELETE',
        body: JSON.stringify({ groupId }),
        reThrowErr: true,
    })
}
export const updateOptions = async (
    groupId: string | number,
    updates: { option_id: string | number; option_name: string }[],
    deletions: string[],
    additions: ({ option_name: string; position: number } | undefined)[]
) => {
    return await vFetch('/v2/products/productGroups/options', {
        method: 'POST',
        body: JSON.stringify({ groupId, updates, deletions, additions }),
        reThrowErr: true,
    })
}
export const updateOptionValues = async (
    groupId: string | number,
    updates: { option_id: string | number; product_link_id: number; option_value: string }[],
    deletions: string[]
) => {
    return await vFetch('/v2/products/productGroups/productOptions', {
        method: 'POST',
        body: JSON.stringify({ groupId, updates, deletions }),
        reThrowErr: true,
    })
}
export const addGroupProductLink = async (groupId: string | number, productId: string | number) => {
    return await vFetch('/v2/products/productGroups/groupProductLink', {
        method: 'POST',
        body: JSON.stringify({ groupId, productId }),
        reThrowErr: true,
    })
}
export const deleteGroupProductLinks = async (groupId: string | number, productIds: (string | number)[]) => {
    return await vFetch('/v2/products/productGroups/groupProductLink', {
        method: 'DELETE',
        body: JSON.stringify({ groupId, productIds }),
        reThrowErr: true,
    })
}
export const getProductSetupEvents = async (productId: string | undefined) => {
    return await vFetch(`/v1/productSetupEvents?product_id=${productId}`, {})
}

// LEAD TIMES

export const getLeadTimes = async ({ leadTimeId, filters, search }: any) => {
    const queryParams = [
        leadTimeId ? `lead_time_id=${leadTimeId}` : null,
        filters.statuses?.length > 0 ? `statuses=${filters.statuses.join(',')}` : null,
        filters.vendors?.length > 0 ? `vendors=${encodeURIComponent(filters.vendors.join(','))}` : null,
        filters?.after ? `after=${filters.after}` : null,
        filters?.before ? `before=${filters.before}` : null,
        search ? `search=${search}` : null,
    ]
        .filter((v) => v)
        .join('&')

    const res = await vFetch(`/v2/leadTimes?${queryParams}`, {
        reThrowErr: true,
    })
    return res
}
export const getLeadTimeDetail = async (leadTimeId: string | undefined) => {
    const queryParams = [leadTimeId ? `lead_time_id=${leadTimeId}` : null].filter((v) => v).join('&')

    const res = await vFetch(`/v2/leadTimes/detail?${queryParams}`, {
        reThrowErr: true,
    })
    return res
}
export const createLeadTime = async ({
    leadTime,
}: {
    leadTime: {
        product_id: number
        variant_id: number
        quantity: number
        date: Date
        preorder_days: number
        inventory_policy: 'deny' | 'continue'
        user_alias: string
        options?: any
    }
}) => {
    return await vFetch('/v2/leadTimes/detail', {
        method: 'POST',
        body: JSON.stringify({ leadTime }),
        reThrowErr: true,
    })
}
export const deleteLeadTime = async (leadTimeId: number | string, variantId: number | string) => {
    return await vFetch('/v2/leadTimes/detail', {
        method: 'DELETE',
        body: JSON.stringify({ leadTimeId, variantId }),
        reThrowErr: true,
    })
}
export const updateLeadTime = async (leadTimeUpdates: any, original: any) => {
    return await vFetch('/v2/leadTimes/detail', {
        method: 'PUT',
        body: JSON.stringify({ leadTimeUpdates, original }),
        reThrowErr: true,
    })
}

import { parseResObject } from 'helpers'
import { useSelector } from 'react-redux'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'

export default function RecurringTaskEstimate({
    departmentId,
    estimateId,
    handleChange,
}: {
    departmentId: number
    estimateId: number
    handleChange: (...args: any) => void
}) {
    const user = useSelector<any, any>((state) => state.user)
    const departmentConfigs = useGetAllDepartmentConfigs(user.id)
    const configs = departmentConfigs.data?.departments || []
    const foundConfig = parseResObject(configs.find((config: any) => config.id === departmentId) || {})
    const foundTypeOption = foundConfig.estimates?.find((estimate: any) => estimate.id === estimateId)

    return (
        <select
            className='border border-lightgrey dark:border-darkgrey rounded p-1 w-full'
            id='estimate_id'
            name='estimate_id'
            value={foundTypeOption?.id ?? ''}
            onChange={handleChange}
        >
            <option value=''>{!foundConfig.estimates ? 'No Estimates Available' : 'No Estimate Selected'}</option>
            {foundConfig?.estimates?.map((estimate: any) => <option value={estimate.id}>{estimate.value}</option>)}
        </select>
    )
}

import { closestCenter, DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { CheckIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'
import RefreshButton from 'custom_components/RefreshButton'
import { cn } from 'helpers'
import { getDateToday } from 'procurement/constants'
import { useEffect, useState } from 'react'
import { TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { tasks } from 'tasksV2/api/queryKeys'
import { TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import { useGetAllDepartmentConfigs, useGetTasks } from '../api/useQueries'
import { V2TaskCalendar } from './components/V2TaskCalendar'
import TaskListGrouped from './lists/TaskListGrouped'
import TaskListSmall from './lists/TaskListSmall'
import TaskListSmallPinned from './lists/TaskListSmallPinned'
import CreateTaskModal from './modals/CreateTaskModal'
import TasksScreenV2SearchContainer from './TasksScreenV2SearchContainer'

export default function TasksScreenV2() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const user = useSelector<any, any>((state) => state.user)
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const tasksListQuery = useGetTasks({})
    const [taskDueDate, setTaskDueDate] = useState<Date | undefined>(getDateToday())
    const stringFilterValues = localStorage.getItem('tasksFilterValues')
    const prioritiesString = searchParams.get('priorities') || ''
    const assignedToString = searchParams.get('assigned_to') || ''
    const statusString = searchParams.get('statuses') || ''
    const [initRender, setInitRender] = useState(true)
    const [filterValues, setFilterValues] = useState<any>(stringFilterValues ? JSON.parse(stringFilterValues) : {})
    const [groupTables, setGroupTables] = useState(stringFilterValues ? JSON.parse(stringFilterValues).grouped : false)
    const [activeId, setActiveId] = useState(null)
    const stringTableSetup = localStorage.getItem('tasksTableSetup')
    const [tableSetup, setTableSetup] = useState(stringTableSetup ? JSON.parse(stringTableSetup) : [])
    const idPositions = tableSetup?.sort(positionSort).map((table: any) => table.id)
    const [debouncedSearch, setDebouncedSearch] = useState('')
    const [dragging, setDragging] = useState(false)

    function positionSort(a: any, b: any) {
        return a.position - b.position
    }

    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)

    const priorityIcons = [TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5]
    const priorityOptions = TASK_PRIORITIES?.map((priority: any, index) => {
        return {
            label: priority,
            value: priority,
            icon: priorityIcons[index],
        }
    })
    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const selectionOptions = [
        { title: 'Completed', id: 'completed' },
        { title: 'Overdue', id: 'overdue' },
    ]
    const userFilterOptions = [
        { title: 'Myself', id: 'showMyself' },
        { title: 'All', id: 'showAll' },
    ]

    const handleTableSetupEdit = (tableSetup: any) => {
        if (Object.keys(tableSetup)?.length) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
            localStorage.setItem('tasksTableSetup', JSON.stringify(tableSetup))
        }
    }

    const handleFilterValuesEdit = (newValue: any) => {
        if (Object.keys(newValue)?.length) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
            localStorage.setItem('tasksFilterValues', JSON.stringify(newValue))
        }
    }

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }
    const { departments } = departmentConfigQuery?.data || {}

    const assignedToValues = decodeURIComponent(assignedToString)
        .split(',')
        .filter((v) => v)

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => !user.roles.includes('suspended'))
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    const filters = {
        userIds: assignedToValues.length ? assignedToValues : filterValues['showMyself'] ? [user.id] : undefined,
        // departmentIds: [department.id],
        // typeIds: typeValues,
        priorities: priorityValues,
        statuses:
            statusValues.length > 0
                ? statusValues
                : filterValues['completed']
                  ? TASK_STATUSES
                  : [...TASK_STATUSES.filter((status) => status != 'Completed')],
        // estimateIds: estimateValues,
        // search: debouncedSearch,
        date: taskDueDate,
        overdue: filterValues['overdue'] ? true : undefined,
        // completed: filterValues['completed'] ? false : undefined,
    }

    const allShown = tableSetup?.filter((setup: any) => setup?.hidden == false).length === tableSetup.length
    const allHidden = tableSetup?.filter((setup: any) => setup?.hidden == true).length === tableSetup.length

    function handleDragStart(event: any) {
        const { active } = event

        setActiveId(active.id)
    }

    function handleDragEnd(event: any) {
        const { active, over } = event

        if (active.id !== over.id) {
            const oldIndex = idPositions.indexOf(active.id)
            const newIndex = idPositions.indexOf(over.id)

            const newPositions = arrayMove(idPositions, oldIndex, newIndex)

            setTableSetup((prev: any) => {
                const newValue: any = []
                newPositions.forEach((id: any, index: number) => {
                    newValue.push({ ...tableSetup.find((table: any) => table.id == id), position: index })
                })
                localStorage.setItem('tasksTableSetup', JSON.stringify(newValue))
                return newValue
            })
        }
        setDragging(false)
        setActiveId(null)
    }

    useEffect(() => {
        departments?.map((department: any) => {
            searchParams.set(`${department.title}_page`, '1')
        })
        searchParams.set('page', '1')
        setSearchParams(searchParams)
    }, [taskDueDate, debouncedSearch])

    useEffect(() => {
        if (!Object.keys(filterValues).length) {
            const defaultFilterValues = {
                completed: false,
                overdue: true,
                showAll: false,
                showMyself: true,
                grouped: false,
            }
            localStorage.setItem('tasksFilterValues', JSON.stringify(defaultFilterValues))
            setFilterValues(defaultFilterValues)
        }
        if (!tableSetup.length) {
            if (departments?.length) {
                const defaultTableSetup = [
                    {
                        id: -1,
                        hidden: false,
                        size: 'sm',
                        position: 0,
                    },
                    ...departments?.map((department: any, index: number) => {
                        return {
                            id: department.id,
                            hidden: false,
                            size: 'sm',
                            position: index + 1,
                        }
                    }),
                ]
                localStorage.setItem('tasksTableSetup', JSON.stringify(defaultTableSetup))
                setTableSetup(defaultTableSetup)
            }
        }
    }, [departments])
    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])
    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
        }
    }, [debouncedSearch])

    return (
        <div className='text-sm mt-[-12px]'>
            <>
                <div className='flex justify-between'>
                    <div className='flex gap-6 items-center w-1/3'>
                        <h1 className='text-2xl font-semibold'>Tasks</h1>
                        <TasksScreenV2SearchContainer setDebouncedSearch={setDebouncedSearch} />
                    </div>
                    <div className='flex gap-4 items-center'>
                        <RefreshButton
                            queryKeys={[tasks.list._def.toString()]}
                            isFetching={tasksListQuery.isFetching}
                        />
                        <Button
                            variant={'outline'}
                            size={'sm'}
                            onClick={() => toggleCreateModal()}
                            className='flex gap-1 items-center'
                        >
                            <p className='mb-[2px]'>+</p>
                            <p> New Task</p>
                        </Button>
                    </div>
                </div>
                <div className='flex justify-between mb-4'>
                    <div className='flex gap-4 items-center'>
                        <div className='flex flex-col '>
                            <div className='flex justify-between'>
                                <div className='h-fit  p-1  gap-x-2 text-sm'>
                                    <div
                                        onClick={() => {
                                            setGroupTables(!groupTables)
                                            setFilterValues((prev: any) => {
                                                const newValue = {
                                                    ...prev,
                                                }
                                                newValue['grouped'] = !newValue['grouped']
                                                handleFilterValuesEdit(newValue)
                                                return newValue
                                            })
                                        }}
                                        className='flex gap-1 cursor-pointer items-center'
                                    >
                                        <div
                                            className={cn(
                                                ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                groupTables
                                                    ? 'bg-primary text-primary-foreground'
                                                    : 'opacity-50 [&_svg]:invisible'
                                            )}
                                        >
                                            <CheckIcon className={cn('h-4 w-4')} />
                                        </div>
                                        <p>Group Departments </p>
                                    </div>
                                </div>
                                <div
                                    onClick={() => {
                                        setTableSetup((prev: any) => {
                                            const newValue: any = []
                                            prev.forEach((item: any) => {
                                                newValue.push({
                                                    ...item,
                                                    hidden: false,
                                                })
                                            })
                                            handleTableSetupEdit(newValue)
                                            return newValue
                                        })
                                    }}
                                    className='flex  gap-1 cursor-pointer items-center'
                                >
                                    <div
                                        className={cn(
                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                            allShown
                                                ? 'bg-primary text-primary-foreground'
                                                : 'opacity-50 [&_svg]:invisible'
                                        )}
                                    >
                                        <CheckIcon className={cn('h-4 w-4')} />
                                    </div>
                                    <p>Show All</p>
                                </div>
                                <div
                                    onClick={() => {
                                        setTableSetup((prev: any) => {
                                            const newValue: any = []
                                            prev.forEach((item: any) => {
                                                newValue.push({
                                                    ...item,
                                                    hidden: true,
                                                })
                                            })
                                            handleTableSetupEdit(newValue)
                                            return newValue
                                        })
                                    }}
                                    className='flex  gap-1 cursor-pointer items-center'
                                >
                                    <div
                                        className={cn(
                                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                            allHidden
                                                ? 'bg-primary text-primary-foreground'
                                                : 'opacity-50 [&_svg]:invisible'
                                        )}
                                    >
                                        <CheckIcon className={cn('h-4 w-4')} />
                                    </div>
                                    <p>Hide All</p>
                                </div>
                            </div>
                            <div className='border rounded-md p-1 grid grid-rows-2 grid-cols-4 gap-1'>
                                {departmentConfigQuery.isLoading &&
                                    [
                                        'accounting',
                                        'development',
                                        'customer service',
                                        'admin',
                                        'accounting',
                                        'procurement',
                                        'admin',
                                    ]?.map((department: any) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setTableSetup((prev: any) => {
                                                        const newValue = [...prev]
                                                        newValue[idPositions.indexOf(department.id)].hidden =
                                                            !newValue[idPositions.indexOf(department.id)].hidden
                                                        handleTableSetupEdit(newValue)
                                                        return newValue
                                                    })
                                                }}
                                                className='flex gap-1 cursor-pointer items-center'
                                            >
                                                <div
                                                    className={cn(
                                                        ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer'
                                                    )}
                                                >
                                                    <CheckIcon className={cn('h-4 w-4')} />
                                                </div>

                                                <SkeletonBasic className='w-fit h-fit '>
                                                    <p className='opacity-0'>{department}</p>
                                                </SkeletonBasic>
                                            </div>
                                        )
                                    })}
                                {!departmentConfigQuery.isLoading && (
                                    <>
                                        <div
                                            onClick={() => {
                                                setTableSetup((prev: any) => {
                                                    const newValue = [...prev]
                                                    newValue[idPositions.indexOf(-1)].hidden =
                                                        !newValue[idPositions.indexOf(-1)].hidden
                                                    handleTableSetupEdit(newValue)
                                                    return newValue
                                                })
                                            }}
                                            className='flex  gap-1 cursor-pointer items-center'
                                        >
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    !tableSetup[idPositions.indexOf(-1)]?.hidden
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                            <p>Pinned</p>
                                        </div>
                                        {departments?.length > 0 &&
                                            departments?.map((department: any) => {
                                                return (
                                                    <div
                                                        onClick={() => {
                                                            setTableSetup((prev: any) => {
                                                                const newValue = [...prev]
                                                                newValue[idPositions.indexOf(department.id)].hidden =
                                                                    !newValue[idPositions.indexOf(department.id)].hidden
                                                                handleTableSetupEdit(newValue)
                                                                return newValue
                                                            })
                                                        }}
                                                        className='flex gap-1 cursor-pointer items-center'
                                                    >
                                                        <div
                                                            className={cn(
                                                                ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                                !tableSetup[idPositions.indexOf(department.id)]?.hidden
                                                                    ? 'bg-primary text-primary-foreground'
                                                                    : 'opacity-50 [&_svg]:invisible'
                                                            )}
                                                        >
                                                            <CheckIcon className={cn('h-4 w-4')} />
                                                        </div>
                                                        <p>{department.title}</p>
                                                    </div>
                                                )
                                            })}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div className='flex gap-4 items-center h-fit'>
                            <div className='flex flex-col h-full justify-end gap-1'>
                                <div></div>
                                <FilterBarSelectorButton
                                    variant='outline'
                                    align='end'
                                    setFilterValues={() => {}}
                                    options={statusOptions}
                                    title={'Status'}
                                    field={'statuses'}
                                    filterValues={statusValues}
                                    searchToggle={false}
                                    editSearchParams={true}
                                    preview={false}
                                />
                                <FilterBarSelectorButton
                                    variant='outline'
                                    align='end'
                                    setFilterValues={() => {}}
                                    options={priorityOptions}
                                    title={'Priority'}
                                    field={'priorities'}
                                    filterValues={priorityValues}
                                    searchToggle={false}
                                    editSearchParams={true}
                                    preview={false}
                                />
                            </div>
                            <div className='flex flex-col gap-[10px] border h-fit rounded-md p-1 px-2  text-sm'>
                                {selectionOptions?.map((option: any) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setFilterValues((prev: any) => {
                                                    const newValue = {
                                                        ...prev,
                                                    }
                                                    newValue[option.id] = !newValue[option.id]
                                                    handleFilterValuesEdit(newValue)
                                                    return newValue
                                                })
                                            }}
                                            className='flex gap-1 cursor-pointer items-center'
                                        >
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    filterValues[option.id]
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                            <p>{option.title}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='flex flex-col'>
                                <div className=' h-fit  p-1 flex justify-end gap-2 text-sm'>
                                    {userFilterOptions?.map((option: any) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    if (filterValues[option.id]) {
                                                        return
                                                    }
                                                    setFilterValues((prev: any) => {
                                                        const newValue = {
                                                            ...prev,
                                                        }
                                                        if (option.id === 'showAll') {
                                                            newValue['showMyself'] = false
                                                        }
                                                        if (option.id === 'showMyself') {
                                                            newValue['showAll'] = false
                                                        }
                                                        newValue[option.id] = !newValue[option.id]
                                                        handleFilterValuesEdit(newValue)
                                                        return newValue
                                                    })
                                                }}
                                                className='flex gap-1 cursor-pointer items-center'
                                            >
                                                <div
                                                    className={cn(
                                                        ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                        filterValues[option.id]
                                                            ? 'bg-primary text-primary-foreground'
                                                            : 'opacity-50 [&_svg]:invisible'
                                                    )}
                                                >
                                                    <CheckIcon className={cn('h-4 w-4')} />
                                                </div>
                                                <p>{option.title}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <FilterBarSelectorButton
                                    variant='outline'
                                    align='end'
                                    setFilterValues={() => {}}
                                    options={userOptions}
                                    title={'Assigned To'}
                                    field={'assigned_to'}
                                    filterValues={assignedToValues}
                                    searchToggle={true}
                                    editSearchParams={true}
                                    preview={false}
                                />
                            </div>
                            <div className='flex flex-col gap-1 justify-end items-end h-full min-w-[200px]'>
                                <div
                                    onClick={() => {
                                        departments?.map((department: any) => {
                                            searchParams.set(`${department.title}_page`, '1')
                                        })
                                        searchParams.set('page', '1')
                                        searchParams.delete('calendarPreviewUser')
                                        searchParams.delete('calendarPreviewType')
                                        setSearchParams(searchParams)
                                        return setTaskDueDate(getDateToday())
                                    }}
                                    className={cn(
                                        'text-text2 dark:text-darktext2 dark:border-darkbg2 border border-bg1 h-fit w-fit rounded-md px-1  text-sm',
                                        taskDueDate?.toDateString() != getDateToday().toDateString() &&
                                            'border-lightgrey text-text1 cursor-pointer dark:text-darktext1 dark:border-lightgrey'
                                    )}
                                >
                                    Reset
                                </div>
                                <V2TaskCalendar date={taskDueDate} setDate={setTaskDueDate} />
                            </div>
                        </div>
                    </div>
                </div>

                {!groupTables && (
                    <div className='grid grid-cols-2 grid-rows-[360px_360px_360px_360px_360px_360px_360px] gap-3 w-full transition-all'>
                        <DndContext
                            collisionDetection={closestCenter}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={idPositions.map((id: (number | string)[]) => {
                                    return { id }
                                })}
                            >
                                {departments?.length > 0 &&
                                    tableSetup
                                        ?.filter((setup: any) => !setup?.hidden)
                                        ?.map((setup: any) => {
                                            const size = setup.size
                                            const foundDepartment =
                                                setup.id === -1
                                                    ? { id: -1, title: 'Pinned' }
                                                    : departments?.find((department: any) => department.id == setup.id)

                                            if (foundDepartment) {
                                                return (
                                                    <div
                                                        className={cn(
                                                            'w-full  transition-all delay-200',
                                                            size === 'wide' && 'col-span-2',
                                                            size === 'tall' && 'row-span-2',
                                                            size === 'lg' && 'col-span-2 row-span-2',
                                                            dragging && 'col-span-1 row-span-1'
                                                        )}
                                                    >
                                                        <TaskListSmall
                                                            department={foundDepartment}
                                                            filters={filters}
                                                            tableSetup={tableSetup}
                                                            setTableSetup={setTableSetup}
                                                            position={setup.position}
                                                            dragging={dragging}
                                                            setDragging={setDragging}
                                                        />
                                                    </div>
                                                )
                                            }
                                            return <div className='hidden'></div>
                                        })}
                            </SortableContext>
                        </DndContext>
                    </div>
                )}
                {groupTables && (
                    <div className='flex flex-col gap-3'>
                        {tableSetup.find((setup: any) => setup.id === -1)?.hidden === false && (
                            <div className='grid grid-cols-2 grid-rows-[300px] gap-3 w-full transition-all'>
                                <div className={cn('w-full  transition-all delay-200 col-span-2')}>
                                    <TaskListSmallPinned
                                        department={{ id: -1, title: 'Pinned' }}
                                        filters={filters}
                                        tableSetup={tableSetup}
                                        setTableSetup={setTableSetup}
                                        position={0}
                                        dragging={dragging}
                                        setDragging={setDragging}
                                    />
                                </div>
                            </div>
                        )}
                        <TaskListGrouped
                            departments={tableSetup?.filter((setup: any) => setup?.hidden == false)}
                            filters={filters}
                        />
                    </div>
                )}
                {/* {groupTables && <TaskList departments={shownDepartments} filters={filters} />} */}
            </>
            {showCreateModal && <CreateTaskModal closeModal={toggleCreateModal} />}
        </div>
    )
}

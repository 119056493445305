import { Button } from 'custom_components/component_Basics/Button'
import { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useAllUsersQuery } from 'users/api/useQueries'

export default function ProjectAssignmentsModal({
    closeModal,
    associations,
    toggleAssociation,
}: {
    closeModal: () => void
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const usersQuery = useAllUsersQuery()
    const users = usersQuery.data?.users || []

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative rounded p-6 bg-bg1 dark:bg-darbg1 min-w-[400px]'>
                <button onClick={closeModal} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <h2 className='font-bold mb-2'>Choose Assigned Users</h2>
                <div className='max-h-[300px] overflow-auto flex flex-col gap-1 pr-2'>
                    {users
                        .filter((user: any) => !user.roles.includes('suspended'))
                        .map((user: any) => (
                            <button
                                className='flex gap-1 items-center p-1 rounded border border-lightgrey dark:borer-darkgrey w-full'
                                onClick={() =>
                                    toggleAssociation({
                                        resource_name: 'user',
                                        resource_id: user.id,
                                        readable_name: `${user.first_name} ${user.last_name}`,
                                    })
                                }
                            >
                                <div
                                    className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                        associations?.find((association: any) => association.resource_id === user.id)
                                            ? 'bg-accent1 dark:bg-darkaccent1'
                                            : ''
                                    }`}
                                ></div>
                                <span>
                                    {user.first_name} {user.last_name}
                                </span>
                            </button>
                        ))}
                </div>
                <Button variant={'outline'} className='block mx-auto mt-2' onClick={closeModal}>
                    Return To Project Editor
                </Button>
            </div>
        </div>
    )
}

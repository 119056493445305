import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import Input from 'procurement/components/Input'
import LabelWithInfo from 'productSetupV2/components/LabelWithInfo'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'
import { useCreateDepartment } from 'tasksV2/api/useQueries'
import { useAllUsersQuery } from 'users/api/useQueries'

export default function CreateTaskDepartmentModal({ closeModal }: { closeModal: () => void }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const usersQuery = useAllUsersQuery()
    const createDepartmentMutation = useCreateDepartment()
    const users = usersQuery.data?.users || []
    const modalRef = useRef<HTMLDivElement>(null)
    const [formData, setFormData] = useState({
        title: '',
        private: false,
        users: [] as number[],
        admins: [] as number[],
    })

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setFormData((prev) => ({
            ...prev,
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const handleToggleAdmin = (userId: number) => {
        setFormData((prev) => {
            const newState = structuredClone(prev)
            if (newState.admins.includes(userId)) {
                newState.admins = newState.admins.filter((id) => id !== userId)
            } else {
                newState.admins.push(userId)
            }
            return newState
        })
    }

    const handleToggleUser = (userId: number) => {
        setFormData((prev) => {
            const newState = structuredClone(prev)
            if (newState.users.includes(userId)) {
                newState.users = newState.users.filter((id) => id !== userId)
            } else {
                newState.users.push(userId)
            }
            return newState
        })
    }

    const handleCreate = () => {
        createDepartmentMutation.mutate(formData, {
            onSuccess: (result) => {
                searchParams.delete('showCreateModal')
                searchParams.set('departmentId', result.id)
                setSearchParams(searchParams)
            },
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md'>
                <button onClick={closeModal} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <h2 className='text-lg font-bold mb-4'>Create New Task Department</h2>
                <div className='flex flex-col gap-2 w-[400px]'>
                    <div className='flex gap-2'>
                        <Input
                            type='text'
                            label='Title'
                            id='title'
                            name='title'
                            onChange={handleChange}
                            value={formData.title}
                        />
                    </div>
                    <div>
                        <LabelWithInfo
                            htmlFor=''
                            info='People who are alerted in response to task escalations'
                            label='Escalation Alert Recipients'
                        />
                        <div className='mt-2 max-h-[150px] overflow-auto'>
                            {users
                                .filter((u: any) => !u.roles.includes('suspended'))
                                .map((user: any) => (
                                    <div
                                        onClick={() => handleToggleAdmin(user.id)}
                                        className='flex items-center gap-2 p-1 border-b border-lightgrey dark:border-darkgrey cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                                    >
                                        <div
                                            className={`border border-lightgrey dark:border-darkgrey rounded w-4 h-4 ${
                                                formData.admins.includes(user.id)
                                                    ? 'bg-accent1 dark:bg-darkaccent2'
                                                    : ''
                                            }`}
                                        >
                                            &nbsp;
                                        </div>
                                        {user.first_name} {user.last_name}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div>
                        <div className='flex gap-2 justify-between'>
                            <LabelWithInfo
                                htmlFor=''
                                info='People who can view and edit tasks in this department'
                                label='View and Edit Permissions'
                            />
                            <div className='flex items-center gap-3'>
                                <LabelWithInfo
                                    htmlFor='private'
                                    info='Only show tasks to people in the department'
                                    label='Private'
                                />
                                <input
                                    type='checkbox'
                                    id='private'
                                    name='private'
                                    checked={formData.private}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div
                            className={cn(
                                'mt-2 max-h-[150px] overflow-auto',
                                !formData.private && 'opacity-50 pointer-events-none'
                            )}
                        >
                            {users
                                .filter((u: any) => !u.roles.includes('suspended'))
                                .map((user: any) => (
                                    <div
                                        onClick={() => handleToggleUser(user.id)}
                                        className='flex items-center gap-2 p-1 border-b border-lightgrey dark:border-darkgrey cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                                    >
                                        <div
                                            className={`border border-lightgrey dark:border-darkgrey rounded w-4 h-4 ${
                                                formData.users.includes(user.id) ? 'bg-accent1 dark:bg-darkaccent2' : ''
                                            }`}
                                        >
                                            &nbsp;
                                        </div>
                                        {user.first_name} {user.last_name}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <Button className='mx-auto block mt-4' variant={'outline'} onClick={handleCreate}>
                    Create Department
                </Button>
            </div>
        </div>
    )
}

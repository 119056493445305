import { Button } from 'custom_components/component_Basics/Button'
import { cn, parseResObject, sendToast } from 'helpers'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import Textarea from 'procurement/components/Textarea'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useCreateTask, useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import TaskAssignmentsModal from './TaskAssignmentsModal'
import TaskAssociationsModal from './TaskAssociationsModal'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'

export default function CreateTaskModal({
    prePopulatedValues = {},
    closeModal,
}: {
    prePopulatedValues?: any
    closeModal: () => void
}) {
    const titleRef = useRef<HTMLInputElement>(null)
    const user = useSelector<any, any>((state) => state.user)
    const [showAssignments, setShowAssignments] = useState(false)
    const [showAssociations, setShowAssociations] = useState(false)
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const departments = departmentConfigQuery.data?.departments || []
    const createTaskMutation = useCreateTask()
    const modalRef = useRef<HTMLDivElement>(null)
    const [task, setTask] = useState<any>({
        associations: [
            {
                resource_name: 'user',
                readable_name: `${user.firstName} ${user.lastName}`,
                resource_id: user.id,
            },
        ],
        ...prePopulatedValues,
    })

    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setTask((prev: any) => {
            const newState = structuredClone(prev)
            if (
                newState.associations?.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                return newState
            }
            if (!newState.associations) {
                newState.associations = []
            }
            newState.associations.push(association)
            return newState
        })
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setTask((prev: any) => {
            if (target.name === 'department_id') {
                return {
                    [target.name]: target.value,
                    associations: prev.associations,
                }
            }
            return {
                ...prev,
                [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
            }
        })
    }

    const handleCreate = () => {
        if (createTaskMutation.isPending) {
            return
        }
        if (!task.department_id) {
            return sendToast({ message: 'Select department' })
        }
        const formattedTask = {
            ...task,
            title: task?.title ? task?.title.trim() : '',
            description: task?.description ? task.description.trim() : '',
        }
        createTaskMutation.mutate(formattedTask, {
            onSuccess: () => {
                setTask((prev: any) => ({
                    ...prev,
                    title: '',
                    description: '',
                }))
                setTimeout(() => {
                    titleRef.current?.querySelector('input')?.focus()
                }, 100)
            },
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    const selectedDepartment = departments.find((department: any) => department.id == task.department_id)
        ? parseResObject(departments.find((department: any) => department.id == task.department_id))
        : undefined
    const departmentTypes = selectedDepartment?.types || []
    const departmentEstimates = selectedDepartment?.estimates || []
    const departmentPriorities = selectedDepartment?.priorities || []

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className={cn('bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md w-full max-w-[600px]')}>
                <div className={cn(createTaskMutation.isPending && 'pointer-events-none opacity-60')}>
                    <button onClick={closeModal} className='absolute top-1 right-1'>
                        <FaTimes className='text-red dark:text-lightred text-lg' />
                    </button>
                    <div className='flex justify-between items-center'>
                        <h2 className='text-lg font-bold mb-4'>Create New Task</h2>
                        <FetchingSpinner isFetching={createTaskMutation.isPending} />
                    </div>
                    <Select
                        label='Department'
                        id='department_id'
                        name='department_id'
                        onChange={handleChange}
                        value={task.department_id}
                    >
                        <option value=''>Select a department</option>
                        {departments.map((department: any) => (
                            <option value={department.id}>{department.title}</option>
                        ))}
                    </Select>
                    <div
                        className={`${
                            !task.department_id ? 'pointer-events-none opacity-50' : ''
                        } flex flex-col gap-2 mt-2`}
                    >
                        <div className='flex flex-col gap-2'>
                            <div className='flex gap-1'>
                                {departmentTypes.length > 0 && (
                                    <Select
                                        label='Type'
                                        id='type_id'
                                        name='type_id'
                                        onChange={handleChange}
                                        value={task.type_id ?? ''}
                                    >
                                        <option value=''>Select a Task Type</option>
                                        {departmentTypes.map((type: any) => (
                                            <option value={type.task_type_id}>{type.value}</option>
                                        ))}
                                    </Select>
                                )}
                                {departmentEstimates.length > 0 && (
                                    <Select
                                        label='Estimate'
                                        id='estimate_id'
                                        name='estimate_id'
                                        onChange={handleChange}
                                        value={task.estimate_id ?? ''}
                                    >
                                        <option value=''>None</option>
                                        {departmentEstimates.map((type: any) => (
                                            <option value={type.task_estimate_id}>{type.value}</option>
                                        ))}
                                    </Select>
                                )}
                                <Select
                                    label='Priority'
                                    id='priority'
                                    name='priority'
                                    onChange={handleChange}
                                    value={task.priority ?? ''}
                                >
                                    <option value=''>None</option>
                                    {TASK_PRIORITIES.map((priority: any) => (
                                        <option value={priority}>{priority}</option>
                                    ))}
                                </Select>
                            </div>
                            <div className='flex gap-1'>
                                <div className='w-3/4' ref={titleRef}>
                                    <Input
                                        type='text'
                                        id='title'
                                        name='title'
                                        onChange={handleChange}
                                        value={task.title ?? ''}
                                        label='Task'
                                        className=''
                                    />
                                </div>
                                <div className='w-1/4'>
                                    <Select
                                        label='Status'
                                        id='status'
                                        name='status'
                                        onChange={handleChange}
                                        value={task.status ?? ''}
                                    >
                                        <option value=''>None</option>
                                        {TASK_STATUSES.map((status: any) => (
                                            <option value={status}>{status}</option>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            <Textarea
                                id='description'
                                name='description'
                                onChange={handleChange}
                                value={task.description ?? ''}
                                label='Additional Details'
                            />
                        </div>
                        <div className='flex gap-1'>
                            <div className='flex flex-col gap-1 w-1/2'>
                                <label className='text-xs font-bold uppercase leading-none'>Assigned To</label>
                                <div className='flex gap-1 flex-wrap'>
                                    {task.associations
                                        ?.filter((association: any) => association.resource_name === 'user')
                                        .map((association: any) => (
                                            <div className='flex items-center gap-2 p-1 rounded border border-lightgrey dark:border-darkgrey'>
                                                <span>{association.readable_name}</span>
                                                <button onClick={() => toggleAssociations(association)}>
                                                    <FaTimes />
                                                </button>
                                            </div>
                                        ))}
                                </div>
                                <div className='flex flex-wrap'>
                                    <Button variant={'outline'} onClick={() => setShowAssignments(true)}>
                                        + Add / Edit Assignments
                                    </Button>
                                </div>
                            </div>
                            <div className='flex flex-col gap-1 w-1/2'>
                                <label className='text-xs font-bold uppercase leading-none'>Associated With</label>
                                <div className='flex gap-1 flex-wrap'>
                                    {task.associations
                                        ?.filter((association: any) => association.resource_name !== 'user')
                                        .map((association: any) => (
                                            <div className='flex items-center gap-2 p-1 rounded border border-lightgrey dark:border-darkgrey'>
                                                <span>
                                                    {association.readable_name} (
                                                    {association.resource_name.replaceAll('_', ' ')})
                                                </span>
                                                <button onClick={() => toggleAssociations(association)}>
                                                    <FaTimes />
                                                </button>
                                            </div>
                                        ))}
                                </div>
                                <div className='flex flex-wrap'>
                                    <Button variant={'outline'} onClick={() => setShowAssociations(true)}>
                                        + Add / Edit Associations
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Button
                        className={cn('mx-auto block mt-4', createTaskMutation.isPending && 'opacity-70')}
                        variant={'outline'}
                        onClick={handleCreate}
                    >
                        {createTaskMutation.isPending ? 'Creating..' : 'Create Task'}
                    </Button>
                </div>
            </div>
            {showAssignments && (
                <TaskAssignmentsModal
                    closeModal={() => setShowAssignments(false)}
                    associations={task.associations}
                    toggleAssociation={toggleAssociations}
                />
            )}
            {showAssociations && (
                <TaskAssociationsModal
                    closeModal={() => setShowAssociations(false)}
                    associations={task.associations}
                    toggleAssociation={toggleAssociations}
                />
            )}
        </div>
    )
}

import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { useEffect, useState } from 'react'
import { useUpdateTask } from 'tasksV2/api/useQueries'

const HTMLInputFormatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
}).format

export default function TaskDueAt({ taskId, dueAt, mutation }: { taskId: number; dueAt: string; mutation: any }) {
    const parsedDueAt = parseDateToHTMLInput(new Date(dueAt || Date.now()))
    const parsedTime = `${`0${new Date(dueAt).getHours()}`.slice(-2)}:${`0${new Date(dueAt).getMinutes()}`.slice(-2)}`
    const [date, setDate] = useState(parsedDueAt)
    const [time, setTime] = useState(parsedTime)
    function parseDateToHTMLInput(date: Date) {
        const dateString = HTMLInputFormatter(date)
        const [month, day, year] = dateString.split('/')
        return `${year}-${month}-${day}`
    }
    function parseHTMLInputToDate(htmlDate: string) {
        return new Date(`${htmlDate}T${time}:00Z`).toISOString()
    }
    const handleChangeDueAt = () => {
        const selectedDate = parseHTMLInputToDate(date)
        mutation.mutate({
            id: taskId,
            due_at: selectedDate,
        })
    }

    useEffect(() => {
        setDate(parsedDueAt)
        setTime(parsedTime)
    }, [dueAt])
    return (
        <div className='flex gap-1'>
            <input
                className={cn(
                    'leading-none rounded p-1 w-full disabled:opacity-50',
                    mutation.isPending && 'pointer-events-none'
                )}
                type='date'
                id='due_at'
                name='due_at'
                value={date}
                onChange={({ target }) => setDate(target.value || parseDateToHTMLInput(new Date()))}
            />
            <input
                className={cn(
                    'leading-none rounded p-1 w-full disabled:opacity-50',
                    mutation.isPending && 'pointer-events-none'
                )}
                type='time'
                id='due_at_time'
                name='due_at_time'
                value={time}
                onChange={({ target }) => setTime(target.value || '08:00')}
            />
            {(date !== parsedDueAt || time !== parsedTime) && (
                <Button onClick={handleChangeDueAt} className='h-fit p-1'>
                    Confirm
                </Button>
            )}
        </div>
    )
}

import { useDebounce } from 'helpers'
import { useState } from 'react'
import { useGetTaxExemptions } from 'taxExemptions/api/useQueries'

export default function TaxExemptionAssociationBuilder({
    associations,
    toggleAssociation,
}: {
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
}) {
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)
    const taxExemptionsSearchQuery = useGetTaxExemptions({ filters: {}, search: debouncedSearch })
    const taxExemptions = taxExemptionsSearchQuery.data?.taxExemptions || []

    return (
        <div>
            <h2 className='font-bold mb-2'>Choose Tax Exemptions To Associate</h2>
            <input
                className='focus:outline-none py-1 px-2 w-full border border-lightgrey dark:border-darkgrey rounded'
                id='search'
                name='search'
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                placeholder='Search for a tax exemption...'
            />
            <div className='flex flex-col gap-1 h-[200px] overflow-auto'>
                {taxExemptionsSearchQuery.isLoading ? (
                    <p className='p-2'>Loading...</p>
                ) : (
                    <>
                        {taxExemptions.map((taxExemption: any) => (
                            <div
                                onClick={() =>
                                    toggleAssociation({
                                        resource_name: 'customer_tax_exemption',
                                        resource_id: taxExemption.id,
                                        readable_name: `Tax Exemption ${taxExemption.id}`,
                                    })
                                }
                                className='p-1 border border-lightgrey dark:border-darkgrey rounded flex items-center gap-2 cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                            >
                                <div
                                    className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                        associations.find(
                                            (association: any) =>
                                                association.resource_name === 'customer_tax_exemption' &&
                                                association.resource_id === taxExemption.id
                                        )
                                            ? 'bg-accent1 dark:bg-darkaccent1'
                                            : ''
                                    }`}
                                ></div>
                                <span>
                                    Tax Exemption {taxExemption.id} - {taxExemption.calculated_full_name} (
                                    {taxExemption.email})
                                </span>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}

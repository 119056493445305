import { useContext, useEffect, useState } from 'react'
import { BsArrowDown } from 'react-icons/bs'
import { useCompanyFilesQuery } from '../../api/useQueries'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../../helpers'
import FilterToolBar from '../FilterToolBar'
import CompanyFileV2 from '../listItems/CompanyFileV2'
import CSVMapper from '../modals/CSVMapper'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { useSearchParams } from 'react-router-dom'
import { useDebounce } from 'helpers'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'

export const fileNameOptions: FileNameOptionsType[] = [
    // 'FP Import Template',
    'Intake Form',
    // 'Dealer Application',
    'Price List / SKUs',
    // 'Product Reviews',
    'Inventory',
    // 'Brochure',
    // 'Credit Card / ACH Info',
    // 'Shipping Weight & Dimensions',
    // 'Warranty & Returns',
]

export type FileNameOptionsType =
    // | 'FP Import Template'
    | 'Intake Form'
    // | 'Dealer Application'
    | 'Price List / SKUs'
    // | 'Product Reviews'
    | 'Inventory'
// | 'Brochure'
// | 'Credit Card / ACH Info'
// | 'Shipping Weight & Dimensions'
// | 'Warranty & Returns'

export default function VendorFileListV2() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { selectedCompany, users } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [showCSVMapper, setShowCSVMapper] = useState(false)
    const [selectedFile, setSelectedFile] = useState()
    const fileTypesString = searchParams.get('fileTypes') || ''
    const searchParam = searchParams.get('search') || ''
    const after = searchParams.get('after')
    const before = searchParams.get('before')
    const page = searchParams.get('page') || 1
    const [cursorList, setCursorList] = useState<any>([0])
    const [initRender, setInitRender] = useState(true)

    const [filters, setFilters] = useState<{ fileTypes: FileNameOptionsType[] }>({
        // new
        fileTypes: ['Price List / SKUs' || 'Inventory'],
    })
    const [search, setSearch] = useState(searchParam)
    const debouncedSearch = useDebounce(search.trim(), 800)

    const fileTypes: string[] = decodeURIComponent(fileTypesString)
        .split(',')
        .filter((v) => v)
    const companyFilesQuery = useCompanyFilesQuery({
        companyId: selectedCompany.id,
        filters: { fileTypes, after, before, page },
        search: debouncedSearch,
    })
    const { files }: { files: any[] } = companyFilesQuery?.data || {}

    const filteredFiles = files?.filter((file) => {
        return (
            file.name?.toLowerCase().includes(search.toLowerCase()) ||
            file.mime_type?.toLowerCase().includes(search.toLowerCase())
        )
    })

    const vendorFilteredFiles = filteredFiles?.filter((file) => {
        return file.type === 'Price List / SKUs' || file.type === 'Inventory' || file.type === 'Intake Form'
    })

    const filterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: fileNameOptions.map((option) => {
                    return {
                        label: option,
                        value: option,
                        icon: undefined,
                    }
                }),
                title: 'File Type',
                field: 'fileTypes',
                values: fileTypes,
                searchToggle: true,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('fileTypes')
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    if (!companyFilesQuery.isFetching && !companyFilesQuery.isLoading) {
        if (after && !(after[2] === null)) {
            if (!cursorList.includes(after)) {
                setCursorList((prev: any[]) => {
                    return [...prev, after]
                })
            }
        }
    }
    function handlePageIncrease() {
        if (files?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.set('after', files[49].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.set('before', files[0].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('before')
                prev.set('page', '1')
                return prev
            })
        }
    }
    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('after')
            prev.delete('search')
            prev.delete('before')
            prev.set('page', '1')
            return prev
        })
        setSearch('')
    }

    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.delete('after')
                prev.set('page', 1)
                return prev
            })
        }
    }, [debouncedSearch])

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    return (
        <div className='px-[16px] pl-0'>
            {!showCSVMapper && (
                <>
                    <div className='mb-[8px] w-full flex gap-[16px]'>
                        <FilterToolBar
                            search={search}
                            setSearch={setSearch}
                            filterBarObject={filterBarObject}
                            handleClearSearch={handleClearSearch}
                        >
                            {filterBarObject.params.length &&
                                filterBarObject.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.field}
                                            searchToggle={param.searchToggle}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={filterBarObject.setFunction}
                                            editSearchParams={param.editSearchParams}
                                        />
                                    )
                                })}
                        </FilterToolBar>
                        <button
                            onClick={() => setShowQuickModal('file')}
                            className='min-h-[32px] self-center shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                        >
                            + New File
                        </button>
                        <a
                            href={
                                'https://fpdash-bucket.s3.amazonaws.com/procurement/1/1782/product_import_template_csv.csv'
                            }
                            className='flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        >
                            <span className='rounded-full border   border-darkgrey dark:border-offwhite'>
                                <BsArrowDown className='text-darkgrey dark:text-offwhite p-[4px] w-[24px] h-[24px]' />
                            </span>
                            <p className='font-medium leading-none uppercase text-[12px] whitespace-nowrap'>
                                Template <br /> Download
                            </p>
                        </a>
                    </div>
                    <div className='flex flex-col overflow-auto'>
                        <div className='sticky top-0 grid grid-cols-[1fr_1fr_1fr_1fr_50px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Type</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>File Type</div>
                            <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>
                                Uploaded On
                            </div>
                        </div>
                        {vendorFilteredFiles?.map((file) => (
                            <CompanyFileV2
                                key={file.id}
                                file={file}
                                setSelectedFile={setSelectedFile}
                                setShowCSVMapper={setShowCSVMapper}
                            />
                        ))}
                        <div className='m-auto mt-20 p-2 opacity-80 rounded-md text-darkgrey dark:text-lightgrey border border-darkgrey dark:border-darkgrey'>
                            Upload a .csv file to import products
                        </div>
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={companyFilesQuery.isLoading || companyFilesQuery.isFetching || files?.length < 50}
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            )}
            {showCSVMapper && <CSVMapper file={selectedFile} setShowCSVMapper={setShowCSVMapper} />}
        </div>
    )
}

import { createColumnHelper } from '@tanstack/react-table'
import { useDebounce } from 'helpers'
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { Link, useSearchParams } from 'react-router-dom'
import { useGetTasks } from 'tasksV2/api/useQueries'
import Assignment from '../components/Assignment'
import Association from '../components/Association'
import Department from '../components/Department'
import TaskType from '../components/TaskType'
import TasksTable from './TasksTable'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from 'react-icons/tb'
import { keepPreviousData } from '@tanstack/react-query'
import { formatDateSimple } from 'procurement/helpers'
import { TaskV2 } from 'tasksV2/constants/tasks'

export default function TaskListGrouped({ departments, filters }: { departments: any; filters: any }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const after = searchParams.get('after')
    const before = searchParams.get('before')
    const searchParam = searchParams.get('search') || ''
    const page = searchParams.get('page') || 1

    const [initRender, setInitRender] = useState(true)
    const [search, setSearch] = useState(searchParam)
    const debouncedSearch = useDebounce(search.trim(), 800)

    // const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    // const departments = departmentConfigQuery.data?.departments || []

    const dateFormat = new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    }).format
    const tasksQuery = useGetTasks(
        { ...filters, departmentIds: [0, departments.map((d: any) => d.id)], page },
        keepPreviousData
    )
    const { tasks } = tasksQuery?.data || {}

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.delete('after')
                prev.set('page', 1)
                return prev
            })
        }
    }, [debouncedSearch])
    useEffect(() => {
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    function handlePageIncrease() {
        if (tasks?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('before')
                prev.set('page', '1')
                return prev
            })
        }
    }

    const tableData = tasks?.length > 0 ? tasks : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<TaskV2>()

    const taskColumns = [
        // {
        //     enableGlobalFilter: false,
        //     id: 'select-col',
        //     header: ({ table }: { table: any }) => (
        //         <div className='flex items-center justify-center mx-auto'>
        //             <input
        //                 className='h-[15px] w-[15px]'
        //                 type='checkbox'
        //                 checked={table.getIsAllRowsSelected()}
        //                 onChange={table.getToggleAllRowsSelectedHandler()}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }: { row: any }) => (
        //         <div
        //             onClick={(e) => {
        //                 row.getToggleSelectedHandler()
        //                 e.stopPropagation()
        //             }}
        //             className=' w-fit flex items-center justify-center mx-auto'
        //         >
        //             <input
        //                 className='h-[15px] w-[15px] '
        //                 type='checkbox'
        //                 checked={row.getIsSelected()}
        //                 disabled={!row.getCanSelect()}
        //                 onChange={row.getToggleSelectedHandler()}
        //             />
        //         </div>
        //     ),
        //     size: 20,
        // },
        columnHelper.accessor('id', {
            enableGlobalFilter: false,
            header: 'id',
            cell: (info) => {
                return <div className='flex font-semibold'>{info.getValue()}</div>
            },
            maxSize: 20,
        }),
        columnHelper.accessor('priority', {
            cell: (info) => {
                const priorityMap = {
                    Low: {
                        icon: TbAntennaBars1,
                        className: '',
                    },
                    Medium: {
                        icon: TbAntennaBars3,
                        className: '',
                    },
                    High: {
                        icon: TbAntennaBars4,
                        className: '',
                    },
                    Urgent: {
                        icon: TbAntennaBars5,
                        className: 'text-red dark:text-lightred',
                    },
                }
                const { icon: Priority, className: priorityClassName } =
                    priorityMap[info.getValue() as keyof typeof priorityMap]
                return (
                    <div className='flex gap-2 items-center'>
                        <Priority
                            // onClick={() => handleTogglePopover('priority')}
                            className={`text-[20px] ${priorityClassName}`}
                        />
                    </div>
                )
            },
            size: 20,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            maxSize: 60,
            cell: (info) => {
                const due_at = info.cell.row.original.due_at
                const overdue = new Date(due_at) < new Date() && info.row.original.status != 'Completed'
                return (
                    <div className='flex gap-2 items-center'>
                        {overdue && <ExclamationTriangleIcon className='text-danger' />}
                        <p>{info.getValue()}</p>
                    </div>
                )
            },
        }),
        columnHelper.accessor('title', {
            header: 'Title',
            cell: (info) => {
                return <Link to={`/tasksv2/${info.row.original.id}`}>{info.getValue()}</Link>
            },
        }),
        columnHelper.accessor('department_id', {
            header: 'Department',
            cell: (info) => {
                return (
                    <div key={info.row.original.department_id}>
                        <Department departmentId={info.getValue()} />
                    </div>
                )
            },
        }),
        columnHelper.accessor('type_id', {
            header: 'Type',
            cell: (info) => {
                return (
                    <div>
                        <TaskType
                            taskId={info.row.original.id}
                            departmentId={info.row.original.department_id}
                            typeId={info.getValue()}
                        />
                    </div>
                )
            },
            maxSize: 60,
        }),
        columnHelper.accessor('associations', {
            header: 'Associations',
            cell: (info) => {
                return (
                    <div key={info.row.original.id} className='flex gap-1 flex-wrap'>
                        {JSON.parse(info.getValue())
                            .filter((association: any) => association.resource_name !== 'user')
                            .map((association: any) => (
                                <Association id={info.row.original.id} association={association} />
                            ))}
                    </div>
                )
            },
        }),
        {
            header: 'Assigned To',
            cell: ({ row }: { row: any }) => {
                return (
                    <div className='flex gap-1 flex-wrap'>
                        {JSON.parse(row.original.associations)
                            .filter((association: any) => association.resource_name === 'user')
                            .map((association: any) => (
                                <Assignment association={association} />
                            ))}
                    </div>
                )
            },
        },
        columnHelper.accessor('due_at', {
            maxSize: 50,
            header: 'Due',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
        }),
        columnHelper.accessor('created_at', {
            maxSize: 50,
            header: 'Created',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
        }),
    ]

    const columnCount = taskColumns?.length.toString() || 10
    return (
        <>
            <div>
                <>
                    <div className='w-full rounded-md'>
                        {tableDataMemo && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness rounded-md'>
                                <TasksTable
                                    columns={taskColumns}
                                    tableData={tableDataMemo}
                                    columnCount={columnCount}
                                    showHeaders={true}
                                />
                                <div className='h-[50px]'></div>
                            </div>
                        )}
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={tasksQuery.isLoading || tasksQuery.isFetching || tasks?.length < 50}
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            </div>
        </>
    )
}

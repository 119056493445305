import { Button } from 'custom_components/component_Basics/Button'
import Select from 'procurement/components/Select'
import { useState } from 'react'
import { FaRegStar, FaStar, FaTimes, FaTrash } from 'react-icons/fa'
import { RiGitRepositoryPrivateFill, RiGitRepositoryPrivateLine } from 'react-icons/ri'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import NotFound from 'routes/NotFound'
import {
    useDeleteRoadmap,
    useGetRoadmap,
    useGetRoadmapEvents,
    useGetRoadmapProjects,
    useUpdateRoadmap,
} from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'
import ProjectDatePicker from 'tasksV2/projects/components/ProjectDatePicker'
import CreateProjectModal from 'tasksV2/projects/modals/CreateProjectModal'
import RoadmapAssignmentsModal from './modals/RoadmapAssignmentsModal'
import { associations } from 'tasksV2/api/queryKeys'
import RoadmapProject from './components/RoadmapProject'
import { cn } from 'helpers'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}).format

export default function RoadmapScreen() {
    const { roadmap_id } = useParams()
    const navigate = useNavigate()
    const roadmapQuery = useGetRoadmap(roadmap_id!)
    const roadmapProjectsQuery = useGetRoadmapProjects(roadmap_id!)
    const roadmap = roadmapQuery.data?.roadmap
    const roadmapEventsQuery = useGetRoadmapEvents(roadmap_id!)
    const updateRoadmapMutation = useUpdateRoadmap(roadmap_id!)
    const deleteRoadmapMutation = useDeleteRoadmap(roadmap_id!)
    const roadmapEvents = roadmapEventsQuery.data?.events || []
    const projects = roadmapProjectsQuery.data?.projects || []
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateProjectModal = searchParams.get('showCreateProjectModal') === 'true'
    const [showDelete, setShowDelete] = useState(false)
    const [showAssignments, setShowAssignments] = useState(false)
    const [editedFields, setEditedFields] = useState<any>({})
    const edited = Object.keys(editedFields).length > 0

    const toggleCreateProjectModal = () => {
        if (showCreateProjectModal) {
            searchParams.delete('showCreateProjectModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateProjectModal', 'true')
        setSearchParams(searchParams)
    }
    const handleDeleteRoadmap = () => {
        deleteRoadmapMutation.mutate(undefined, {
            onSuccess: () => navigate('/tasksv2/projects'),
        })
    }
    const handleChangeStatus = ({ target }: any) => {
        updateRoadmapMutation.mutate({
            status: target.value,
        })
    }
    const handleChangePinned = () => {
        updateRoadmapMutation.mutate({
            pinned: roadmap.pinned === 1 ? 0 : 1,
        })
    }
    const handleChangePrivate = () => {
        updateRoadmapMutation.mutate({
            private: roadmap.private === 1 ? 0 : 1,
        })
    }
    const handleSave = () => {
        const parsedUpdates: any = {}
        if (editedFields.associations) {
            const associations = editedFields.associations.filter(
                (association: any) =>
                    !JSON.parse(roadmap.associations).find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            const disassociations = JSON.parse(roadmap.associations).filter(
                (association: any) =>
                    !editedFields.associations.find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            parsedUpdates.associations = associations
            parsedUpdates.disassociations = disassociations
        }
        updateRoadmapMutation.mutate({ ...editedFields, ...parsedUpdates }, { onSuccess: () => setEditedFields({}) })
    }
    const toggleAssociations = (association: any) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.associations) {
                newState.associations = JSON.parse(roadmap.associations || '[]')
            }
            if (!newState.disassociations) {
                newState.disassociations = []
            }
            if (
                newState.associations.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.disassociations.push(association)
                return newState
            } else {
                newState.disassociations = newState.disassociations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.associations.push(association)
                return newState
            }
        })
    }
    const handleDisassociation = (association: any) => {
        updateRoadmapMutation.mutate({
            id: roadmap.id,
            disassociations: [association],
        })
    }
    const handleChange = ({ target }: any) => {
        setEditedFields({
            ...editedFields,
            [target.name]: target.value,
        })
    }
    return (
        <div className='text-sm'>
            {roadmapQuery.isLoading ? (
                <p>Loading...</p>
            ) : !roadmap ? (
                <NotFound />
            ) : (
                <div className='flex gap-4 max-w-[1000px] mx-auto'>
                    <div className='w-3/4'>
                        <div>
                            <Button className='mb-2 h-fit p-1 text-sm' onClick={() => navigate('/tasksv2/projects')}>
                                &lt;- Back
                            </Button>
                            <div className='flex justify-between mb-4 border-b pb-2'>
                                <div className='flex gap-1 items-center'>
                                    <h2 className='text-2xl font-bold whitespace-nowrap'>Roadmap {roadmap_id}</h2>
                                    <Select
                                        className='p-0'
                                        id='status'
                                        name='status'
                                        value={roadmap.status}
                                        onChange={handleChangeStatus}
                                    >
                                        <option value=''>Select a status</option>
                                        {TASK_STATUSES.map((status) => (
                                            <option value={status}>{status}</option>
                                        ))}
                                    </Select>
                                </div>
                                <Button variant='outline' onClick={toggleCreateProjectModal}>
                                    + New Project
                                </Button>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label
                                htmlFor='title'
                                className='text-xs font-bold leading-none text-darkgrey dark:text-offwhite uppercase'
                            >
                                Title
                            </label>
                            <input
                                className='p-1 border border-lightgrey dark:border-darkgrey w-full rounded'
                                id='title'
                                name='title'
                                value={editedFields.title ?? roadmap.title}
                                onChange={handleChange}
                                type='text'
                            />
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label
                                htmlFor='description'
                                className='text-xs font-bold leading-none text-darkgrey dark:text-offwhite uppercase'
                            >
                                Description
                            </label>
                            <textarea
                                className='h-[150px] overflow-auto p-1 border border-lightgrey dark:border-darkgrey w-full rounded'
                                id='description'
                                name='description'
                                onChange={handleChange}
                                value={editedFields.description ?? roadmap.description}
                            ></textarea>
                        </div>
                        <div className='flex gap-4 mb-4'>
                            <Button
                                className={cn(
                                    'mt-2 opacity-50 pointer-events-none',
                                    edited && 'opacity-100 pointer-events-auto'
                                )}
                                variant={'outline'}
                                onClick={handleSave}
                            >
                                {updateRoadmapMutation.isPending ? 'Saving Changes..' : 'Save Changes'}
                            </Button>
                            <Button
                                className={cn(
                                    'mt-2 opacity-50 pointer-events-none',
                                    edited && 'opacity-100 pointer-events-auto'
                                )}
                                variant={'outline'}
                                onClick={() => setEditedFields({})}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div
                            className={
                                !roadmapProjectsQuery.isLoading && projects.length === 0
                                    ? 'mb-4 pb-2 border-b border-lightgrey dark:border-darkgrey'
                                    : 'mb-4'
                            }
                        >
                            <h2 className='font-bold mb-2 uppercase text-xs text-darkgrey dark:text-offwhite'>
                                Projects
                            </h2>
                            {!roadmapProjectsQuery.isLoading && projects.length === 0 ? (
                                <p>This roadmap has no projects.</p>
                            ) : (
                                <div className='grid grid-cols-5'>
                                    <div className='grid grid-cols-subgrid col-span-full p-2 gap-2 bg-accent1 dark:bg-darkbg1 rounded-t text-white font-bold'>
                                        <p>Status</p>
                                        <p>Title</p>
                                        <p>Assigned To</p>
                                        <p>Start Date</p>
                                        <p>Target Date</p>
                                    </div>
                                    {projects.map((project: any) => (
                                        <RoadmapProject project={project} />
                                    ))}
                                </div>
                            )}
                        </div>
                        {showCreateProjectModal && (
                            <CreateProjectModal
                                prePopulatedValues={{
                                    associations: [
                                        {
                                            resource_name: 'roadmap',
                                            resource_id: roadmap_id,
                                            readable_name: roadmap.title,
                                        },
                                    ],
                                }}
                                closeModal={toggleCreateProjectModal}
                            />
                        )}
                        {roadmapEvents.map((event: any) => (
                            <div className='relative p-2 pl-4 ml-4 border-l-2 border-slate flex gap-4 justify-between'>
                                <div className='absolute top-1/2 translate-y-[-50%] h-2 w-2 rounded-full bg-bg1 dark:bg-darkbg2 left-[-5px] border-2 border-slate'></div>
                                <span>{event.message}</span>
                                <span className='shrink-0 whitespace-nowrap'>
                                    {dateFormatter(new Date(event.created_at))}
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className='w-1/4 rounded bg-lightgrey dark:bg-darkbg1 p-4 h-fit'>
                        <div className='flex justify-between'>
                            <div className='flex gap-4 items-center'>
                                <h2 className='font-semibold mb-2 text-base'>Roadmap Details</h2>
                            </div>
                            <div className='flex gap-1 text-xl'>
                                <button
                                    title={roadmap.pinned === 1 ? 'Unpinned project' : 'Pin project'}
                                    onClick={handleChangePinned}
                                >
                                    {roadmap.pinned === 1 ? <FaStar className='text-fire' /> : <FaRegStar />}
                                </button>
                                <button
                                    title={roadmap.private === 1 ? 'Project is private' : 'Make project private'}
                                    onClick={handleChangePrivate}
                                >
                                    {roadmap.private === 1 ? (
                                        <RiGitRepositoryPrivateFill className='text-red dark:text-lightred' />
                                    ) : (
                                        <RiGitRepositoryPrivateLine />
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <h3 className='font-bold'>Start Date</h3>
                            <ProjectDatePicker
                                dateType='start_date'
                                initialDate={roadmap.start_date}
                                mutation={updateRoadmapMutation}
                            />
                        </div>
                        <div className='mb-2'>
                            <h3 className='font-bold'>Target Date</h3>
                            <ProjectDatePicker
                                dateType='target_date'
                                initialDate={roadmap.target_date}
                                mutation={updateRoadmapMutation}
                            />
                        </div>
                        <div className='flex flex-col gap-1 mb-2'>
                            <label className='text-xs font-bold uppercase leading-none'>Roadmap Members</label>
                            <div className='flex gap-1 flex-wrap'>
                                {JSON.parse(roadmap.associations || '[]')?.filter(
                                    (association: any) => association.resource_name === 'user'
                                ).length === 0 && <p>No Assignments</p>}
                                {JSON.parse(roadmap.associations || '[]')
                                    ?.filter((association: any) => association.resource_name === 'user')
                                    .map((association: any) => (
                                        <div className='flex items-center gap-2 p-1 rounded bg-bg1 dark:bg-darkbg2'>
                                            <span>{association.readable_name}</span>
                                            <button
                                                onClick={() => {
                                                    handleDisassociation(association)
                                                }}
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}
                            </div>
                            <div className='flex flex-wrap'>
                                <Button
                                    className='h-fit p-1 mt-1'
                                    variant='outline'
                                    onClick={() => setShowAssignments(true)}
                                >
                                    + Add / Edit Assignments
                                </Button>
                            </div>
                        </div>
                        <div className='flex gap-1 items-center'>
                            {showDelete ? (
                                <>
                                    <Button
                                        onClick={handleDeleteRoadmap}
                                        variant={'destructive'}
                                        className='h-fit p-1 block ml-auto text-xs'
                                    >
                                        Delete roadmap?
                                    </Button>
                                    <Button
                                        onClick={() => setShowDelete(false)}
                                        variant={'outline'}
                                        className='h-fit p-1 block text-xs'
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <button onClick={() => setShowDelete(true)} className='h-fit p-1 block ml-auto'>
                                    <FaTrash className='text-red dark:text-lightred' />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {showAssignments && (
                <RoadmapAssignmentsModal
                    closeModal={() => {
                        setShowAssignments(false)
                        handleSave()
                    }}
                    associations={
                        editedFields.associations?.filter((a: any) => a.resource_name === 'user') ??
                        JSON.parse(roadmap.associations || '[]').filter((a: any) => a.resource_name === 'user')
                    }
                    toggleAssociation={toggleAssociations}
                />
            )}
        </div>
    )
}

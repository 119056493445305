import { Button } from 'custom_components/component_Basics/Button'
import LabelWithInfo from 'productSetupV2/components/LabelWithInfo'
import React, { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useCreateOption, useGetTaskOptions } from 'tasksV2/api/useQueries'
import TaskOptionRow from '../component/TaskOptionRow'
import { cn } from 'helpers'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'

export default function TaskOptionsModal({
    optionName,
    closeModal,
}: {
    optionName: 'estimates' | 'types'
    closeModal: () => void
}) {
    const existingOptionsQuery = useGetTaskOptions({ type: optionName })
    const existingOptions = (existingOptionsQuery.data || {})[optionName] || []
    const modalRef = useRef<HTMLDivElement>(null)
    const createOptionMutation = useCreateOption(optionName)
    const [newOptionValue, setNewOptionValue] = useState('')

    const handleCreateOption = () => {
        createOptionMutation.mutate(
            {
                value: newOptionValue,
            },
            {
                onSuccess: () => {
                    setNewOptionValue('')
                },
            }
        )
    }
    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md w-full max-w-[500px]'>
                <button onClick={() => closeModal()} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <div className='mb-4 flex items-center gap-1'>
                    <h2 className='text-lg font-bold'>Edit Task {optionName}</h2>
                    <LabelWithInfo
                        label=''
                        htmlFor=''
                        info='Changes made here will affect all tasks!'
                        labelClassName='!text-sm !font-normal'
                    />
                </div>
                <div className='flex mb-1'>
                    <input
                        className='w-full p-2 focus:outline-none'
                        placeholder='New Option'
                        value={newOptionValue}
                        onChange={({ target }) => setNewOptionValue(target.value)}
                    />
                    <div className='flex gap-2 items-center'>
                        <FetchingSpinner isFetching={createOptionMutation.isPending} />
                        <Button
                            disabled={newOptionValue.length === 0}
                            className={cn(
                                'min-w-[74px]',
                                createOptionMutation.isPending && 'opacity-70 pointer-events-none'
                            )}
                            size={'sm'}
                            variant={'outline'}
                            onClick={() => handleCreateOption()}
                        >
                            {createOptionMutation.isPending ? 'Saving..' : 'Save'}
                        </Button>
                    </div>
                </div>
                <div className='flex flex-col gap-1 max-h-[400px] overflow-y-auto'>
                    {existingOptions.map((option: any) => (
                        <React.Fragment key={option.id}>
                            <TaskOptionRow optionName={optionName} option={option} />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    )
}

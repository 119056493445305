export default function RecurringTaskActive({
    active,
    handleChange,
}: {
    active?: 1 | 0
    handleChange: (...args: any) => void
}) {
    return (
        <select
            className='border border-lightgrey dark:border-darkgrey rounded p-1 w-full'
            id='active'
            name='active'
            value={active?.toString() ?? ''}
            onChange={handleChange}
        >
            <option value='1'>Active</option>
            <option value='0'>Inactive</option>
        </select>
    )
}

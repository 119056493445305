import { useUpdateTask } from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'

export default function TaskStatus({ taskId, status, mutation }: { taskId: number; status?: string; mutation: any }) {
    const updateTaskMutation = useUpdateTask(taskId.toString())
    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        mutation.mutate({
            id: taskId,
            status: target.value,
        })
    }
    return (
        <select
            className='border border-lightgrey dark:border-darkgrey rounded p-1 w-full'
            id='task_estimate_id'
            name='task_estimate_id'
            value={status}
            onChange={handleChange}
        >
            <option value=''>No Status Selected</option>
            {TASK_STATUSES.map((status: any) => (
                <option value={status}>{status}</option>
            ))}
        </select>
    )
}

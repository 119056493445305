import { ChangeEvent, useEffect, useState } from 'react'
import { ProcessingTimesRow, ProductModalProps } from '../../ProcessingTimesScreen.types'
import PreviewBox from '../PreviewBox'
import { LoadingGear, TimeHandler, vFetch } from '../../../helpers'
const timeHandler = new TimeHandler()

export default function ProductModal({ selectedProduct, setSelectedProduct, setNeedsRefresh }: ProductModalProps) {
    const defaultProcessingTime: ProcessingTimesRow = {
        id: '',
        name: '',
        type: 'PDP',
        pdp_line_1: '',
        pdp_line_2: '',
        cart_line_1: '',
        starts_on: '',
        ends_on: '',
        affected_resources: '',
        updated_at: '',
        created_at: '',
    }
    const [processingTimesMap, setProcessingTimesMap] = useState<any>({})
    const [loadingProcessingTimesMap, setLoadingProcessingTimesMap] = useState(true)
    const [activeProcessingTime, setActiveProcessingTime] = useState<ProcessingTimesRow>(defaultProcessingTime)
    const [selectedProcessingTime, setSelectedProcessingTime] = useState<ProcessingTimesRow>(defaultProcessingTime)
    const [editableProcessingTime, setEditableProcessingTime] = useState<ProcessingTimesRow>(defaultProcessingTime)
    const [isNotNewProcessingTime, setIsNotNewProcessingTime] = useState(false)
    const [edited, setEdited] = useState(false)
    const [newActive, setNewActive] = useState(false)
    const [productConnectionType, setProductConnectionType] = useState<'PDP' | 'CART'>('PDP')

    useEffect(() => {
        if (selectedProduct) {
            setLoadingProcessingTimesMap(true)
            getProcessingTimes().then((res) => {
                setProcessingTimesMap(res)
                setSelectedProcessingTime(
                    res.product?.processingTimesConnections.find((ptc: ProcessingTimesRow) => ptc.type === 'PDP') || {
                        ...defaultProcessingTime,
                        type: 'PDP',
                    }
                )
                setActiveProcessingTime(
                    res.product?.processingTimesConnections.find((ptc: ProcessingTimesRow) => ptc.type === 'PDP') || {
                        ...defaultProcessingTime,
                        type: 'PDP',
                    }
                )
                setEditableProcessingTime(
                    res.product?.processingTimesConnections.find((ptc: ProcessingTimesRow) => ptc.type === 'PDP') || {
                        ...defaultProcessingTime,
                        type: 'PDP',
                    }
                )
                setLoadingProcessingTimesMap(false)
            })
        }
    }, [selectedProduct])

    useEffect(() => {
        if (selectedProcessingTime && editableProcessingTime) {
            if (
                JSON.stringify(selectedProcessingTime).split('').sort().join('') ===
                JSON.stringify(editableProcessingTime).split('').sort().join('')
            ) {
                setEdited(false)
            } else {
                setEdited(true)
            }
        } else {
            setEdited(false)
        }
    }, [selectedProcessingTime, editableProcessingTime])

    useEffect(() => {
        if (activeProcessingTime?.id !== selectedProcessingTime?.id) {
            setNewActive(true)
        } else {
            setNewActive(false)
        }
    }, [activeProcessingTime, selectedProcessingTime])

    useEffect(() => {
        setEditableProcessingTime({ ...selectedProcessingTime } as ProcessingTimesRow)
    }, [selectedProcessingTime])

    useEffect(() => {
        if (productConnectionType === 'PDP') {
            setSelectedProcessingTime(
                processingTimesMap.product?.processingTimesConnections.find(
                    (ptc: ProcessingTimesRow) => ptc.type === 'PDP'
                ) || { ...defaultProcessingTime, type: 'PDP' }
            )
            setActiveProcessingTime(
                processingTimesMap.product?.processingTimesConnections.find(
                    (ptc: ProcessingTimesRow) => ptc.type === 'PDP'
                ) || { ...defaultProcessingTime, type: 'PDP' }
            )
            setEditableProcessingTime(
                processingTimesMap.product?.processingTimesConnections.find(
                    (ptc: ProcessingTimesRow) => ptc.type === 'PDP'
                ) || { ...defaultProcessingTime, type: 'PDP' }
            )
        } else {
            setSelectedProcessingTime(
                processingTimesMap.product?.processingTimesConnections.find(
                    (ptc: ProcessingTimesRow) => ptc.type === 'CART'
                ) || { ...defaultProcessingTime, type: 'CART' }
            )
            setActiveProcessingTime(
                processingTimesMap.product?.processingTimesConnections.find(
                    (ptc: ProcessingTimesRow) => ptc.type === 'CART'
                ) || { ...defaultProcessingTime, type: 'CART' }
            )
            setEditableProcessingTime(
                processingTimesMap.product?.processingTimesConnections.find(
                    (ptc: ProcessingTimesRow) => ptc.type === 'CART'
                ) || { ...defaultProcessingTime, type: 'CART' }
            )
        }
    }, [productConnectionType])

    const clearProduct = (refresh?: boolean) => {
        if (refresh) {
            setNeedsRefresh(true)
        }
        setSelectedProduct(null)
        setSelectedProcessingTime(defaultProcessingTime)
        setProductConnectionType('PDP')
        setProcessingTimesMap({})
    }

    const handleChangeEditableProcessingTime = (
        event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
    ) => {
        setEditableProcessingTime(
            (previousState) =>
                ({
                    ...previousState,
                    [event.target.name]: event.target.value,
                }) as ProcessingTimesRow
        )
    }

    async function getProcessingTimes() {
        const result = await vFetch(`/v1/processingTimes/products/${selectedProduct?.product_gid?.split('/')[4]}`)
        if (result.success) return result.processingTimesMap
        return {
            product: {},
            vendor: {},
            variants: [],
        }
    }

    const [syncState, setSyncState] = useState<'Sync' | 'Syncing' | 'Synced' | 'Failed'>('Sync')
    const syncToShopify = () => {
        if (selectedProduct) {
            setSyncState('Syncing')
            vFetch(`/v1/processingTimes/sync/${encodeURIComponent(selectedProduct.product_gid)}`, {
                cb: (res: any) => setSyncState('Synced'),
                catchCb: (err: any) => setSyncState('Failed'),
            })
        }
    }

    const handleCreate = () => {
        if (editableProcessingTime) {
            const startsOnSplit = editableProcessingTime.starts_on ? editableProcessingTime.starts_on.split('-') : null
            let startsOn
            if (startsOnSplit) {
                const [year, month, date] = startsOnSplit
                startsOn = timeHandler.getISOString(parseInt(year), parseInt(month), parseInt(date))
            }
            const endsOnSplit = editableProcessingTime.ends_on ? editableProcessingTime.ends_on.split('-') : null
            let endsOn
            if (endsOnSplit) {
                const [year, month, date] = endsOnSplit
                endsOn = timeHandler.getISOString(parseInt(year), parseInt(month), parseInt(date))
            }
            const name =
                editableProcessingTime.name === selectedProcessingTime.name
                    ? editableProcessingTime.name + ' Copy'
                    : editableProcessingTime.name

            vFetch(`/v1/processingTimes`, {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    type: editableProcessingTime.type,
                    pdp_line_1: editableProcessingTime.pdp_line_1,
                    pdp_line_2: editableProcessingTime.pdp_line_2,
                    cart_line_1: editableProcessingTime.cart_line_1,
                    starts_on: startsOn,
                    ends_on: endsOn,
                    affected_resources: [selectedProduct?.product_gid],
                }),
                cb: (res: any) => {
                    if (res.success) clearProduct()
                },
            })
        }
    }
    const handleUpdate = () => {
        if (selectedProcessingTime) {
            vFetch(`/v1/processingTimes/connections`, {
                method: 'PUT',
                body: JSON.stringify({
                    id: selectedProcessingTime.id,
                }),
                cb: (res: any) => {
                    if (res.success) clearProduct()
                },
            })
        }
    }
    const handleDelete = () => {
        if (editableProcessingTime) {
            vFetch(`/v1/processingTimes/connections`, {
                method: 'DELETE',
                body: JSON.stringify({
                    id: editableProcessingTime.id,
                }),
                cb: (res: any) => {
                    if (res.success) clearProduct()
                },
            })
        }
    }
    return (
        <>
            {selectedProduct && (
                <div className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'>
                    <div className='p-[32px] w-[90vw] h-[90vh] flex gap-[32px] overflow-auto bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative'>
                        <button
                            onClick={() => clearProduct(false)}
                            className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                        >
                            &times;
                        </button>
                        <div className='flex flex-col gap-[16px] w-1/2'>
                            <div className='flex'>
                                <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mt-[-16px] ml-[-16px]'>
                                    {selectedProduct?.product_title}
                                </h2>
                                <button
                                    onClick={syncToShopify}
                                    className='h-fit ml-auto uppercase leading-[1]  font-bold text-[14px] py-[4px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkness shadow-small dark:text-offwhite'
                                    disabled={syncState !== 'Sync'}
                                >
                                    {syncState}
                                </button>
                            </div>
                            <div className='flex flex-col gap-[8px] w-full'>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Name
                                    </label>
                                    <input
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='name'
                                        name='name'
                                        onChange={handleChangeEditableProcessingTime}
                                        value={editableProcessingTime?.name}
                                    />
                                </div>
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Starts On
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            type='date'
                                            id='starts_on'
                                            name='starts_on'
                                            onChange={handleChangeEditableProcessingTime}
                                            value={editableProcessingTime?.starts_on?.split('T')[0]}
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Ends On
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            type='date'
                                            id='ends_on'
                                            name='ends_on'
                                            onChange={handleChangeEditableProcessingTime}
                                            value={editableProcessingTime?.ends_on?.split('T')[0]}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Type
                                    </label>
                                    <select
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='type'
                                        name='type'
                                        onChange={handleChangeEditableProcessingTime}
                                        value={editableProcessingTime?.type}
                                    >
                                        <option value='PDP'>PDP</option>
                                        <option value='CART'>CART</option>
                                    </select>
                                </div>
                                {editableProcessingTime?.type !== 'CART' ? (
                                    <>
                                        <div className='flex flex-col gap-[4px]'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Line 1
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                id='pdp_line_1'
                                                name='pdp_line_1'
                                                onChange={handleChangeEditableProcessingTime}
                                                value={editableProcessingTime?.pdp_line_1}
                                            />
                                        </div>
                                        <div className='flex flex-col gap-[4px]'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Line 2
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                id='pdp_line_2'
                                                name='pdp_line_2'
                                                onChange={handleChangeEditableProcessingTime}
                                                value={editableProcessingTime?.pdp_line_2}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='flex flex-col gap-[4px]'>
                                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                Dynamic Cart Message
                                            </label>
                                            <input
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                id='cart_line_1'
                                                name='cart_line_1'
                                                onChange={handleChangeEditableProcessingTime}
                                                value={editableProcessingTime?.cart_line_1}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <PreviewBox processingTime={editableProcessingTime} />
                            <div className='flex gap-[16px] mt-auto justify-center'>
                                {editableProcessingTime.id && (
                                    <button
                                        onClick={handleDelete}
                                        className='py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-red dark:text-white dark:bg-lightred uppercase'
                                    >
                                        Delete
                                    </button>
                                )}
                                {(edited || newActive) && (
                                    <button
                                        onClick={newActive && !edited ? handleUpdate : handleCreate}
                                        className='py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-blue dark:text-darkness dark:bg-accent uppercase'
                                    >
                                        {newActive && !edited ? 'Set As Active' : 'Submit'}
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className='w-1/2 h-full flex flex-col'>
                            <div className='h-full flex flex-col'>
                                <div className='flex gap-[4px] items-center mb-[4px]'>
                                    <h3 className='uppercase text-[12px] font-bold text-blue dark:text-offwhite'>
                                        Product Processing Times
                                    </h3>
                                    <select
                                        onChange={({ target }) =>
                                            setProductConnectionType(target.value as 'PDP' | 'CART')
                                        }
                                        value={productConnectionType}
                                        className='text-[12px] uppercase font-bold focus:outline-none bg-lightgrey dark:text-offwhite dark:bg-darkness rounded-[4px]'
                                    >
                                        <option value='PDP'>PDP</option>
                                        <option value='CART'>CART</option>
                                    </select>
                                </div>
                                <div className='flex flex-col gap-[8px] p-[8px] h-full shadow-[inset_0_0_8px_rgb(0,0,0,0.1)] dark:bg-darkness overflow-auto'>
                                    {loadingProcessingTimesMap ? (
                                        <div className='w-full h-full grid place-items-center'>
                                            <LoadingGear width={'100px'} height={'100px'} />
                                        </div>
                                    ) : (
                                        <>
                                            {processingTimesMap?.product?.processingTimesConnections
                                                ?.filter((ptc: ProcessingTimesRow) =>
                                                    productConnectionType === 'PDP'
                                                        ? ptc.type === 'PDP'
                                                        : ptc.type === 'CART'
                                                )
                                                .map((ptc: ProcessingTimesRow) => {
                                                    return (
                                                        <div
                                                            key={`product-ptc-${ptc.id}`}
                                                            onClick={() => {
                                                                setSelectedProcessingTime(ptc)
                                                                setEditableProcessingTime(ptc)
                                                            }}
                                                            className={`
                                                        ${
                                                            selectedProcessingTime?.id === ptc.id
                                                                ? 'border-2 border-fire'
                                                                : ''
                                                        }
                                                        p-[8px] shadow-small grid dark:bg-darkaccent grid-cols-[100px_1fr_100px] gap-[16px] cursor-pointer hover:bg-lightgrey dark:hover:bg-darkness
                                                    `}
                                                        >
                                                            <p className='text-[12px] font-bold uppercase text-fire break-all'>
                                                                {ptc.name} - {ptc.type}
                                                            </p>
                                                            <div className='text-darkgrey font-bold dark:text-offwhite text-[14px]'>
                                                                <p>{ptc.pdp_line_1}</p>
                                                                <p>{ptc.pdp_line_2}</p>
                                                                <p>{ptc.cart_line_1}</p>
                                                            </div>
                                                            {ptc.starts_on ||
                                                                (ptc.ends_on && (
                                                                    <div className='text-[12px] uppercase font-bold dark:text-offwhite'>
                                                                        <p>
                                                                            Starts:{' '}
                                                                            {ptc.starts_on &&
                                                                                new Date(ptc.starts_on).toDateString()}
                                                                        </p>
                                                                        <p>
                                                                            Ends:{' '}
                                                                            {ptc.ends_on &&
                                                                                new Date(ptc.ends_on).toDateString()}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    )
                                                })}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <h3 className='uppercase text-[12px] font-bold text-blue dark:text-offwhite my-[4px]'>
                                    Active Vendor Processing Time
                                </h3>
                                <div className='flex flex-col gap-[8px] p-[8px] h-full shadow-[inset_0_0_8px_rgb(0,0,0,0.1)] dark:bg-darkness overflow-auto'>
                                    {loadingProcessingTimesMap ? (
                                        <div className='w-full h-full grid place-items-center'>
                                            <LoadingGear width={'100px'} height={'100px'} />
                                        </div>
                                    ) : (
                                        <>
                                            {(() => {
                                                const vendorPT = (processingTimesMap?.vendor
                                                    ?.processingTimesConnections || [])[0]
                                                if (vendorPT) {
                                                    return (
                                                        <div className='p-[8px] min-h-[80px] shadow-small dark:bg-darkaccent grid grid-cols-[100px_1fr_100px] gap-[16px]'>
                                                            <p className='text-[12px] font-bold uppercase text-fire break-all'>
                                                                {vendorPT.name} - {vendorPT.type}
                                                            </p>
                                                            <div className='text-darkgrey font-bold dark:text-offwhite text-[14px]'>
                                                                <p>{vendorPT.pdp_line_1}</p>
                                                                <p>{vendorPT.pdp_line_2}</p>
                                                                <p>{vendorPT.cart_line_1}</p>
                                                            </div>
                                                            {vendorPT.starts_on ||
                                                                (vendorPT.ends_on && (
                                                                    <div className='text-[12px] uppercase font-bold dark:text-offwhite'>
                                                                        <p>
                                                                            Starts:{' '}
                                                                            {vendorPT.starts_on &&
                                                                                new Date(
                                                                                    vendorPT.starts_on
                                                                                ).toDateString()}
                                                                        </p>
                                                                        <p>
                                                                            Ends:{' '}
                                                                            {vendorPT.ends_on &&
                                                                                new Date(
                                                                                    vendorPT.ends_on
                                                                                ).toDateString()}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    )
                                                } else {
                                                    return (
                                                        <p className='dark:text-offwhite'>No Vendor Processing Time</p>
                                                    )
                                                }
                                            })()}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className='h-full flex flex-col'>
                                <h3 className='uppercase text-[12px] font-bold text-blue dark:text-offwhite my-[4px]'>
                                    Active Variant Processing Times
                                </h3>
                                <div className='flex flex-col gap-[8px] p-[8px] h-full shadow-[inset_0_0_8px_rgb(0,0,0,0.1)] dark:bg-darkness overflow-auto'>
                                    {loadingProcessingTimesMap ? (
                                        <div className='w-full h-full grid place-items-center'>
                                            <LoadingGear width={'100px'} height={'100px'} />
                                        </div>
                                    ) : (
                                        <>
                                            {processingTimesMap?.variants?.map((variant: any) => {
                                                const variantPT = (variant.processingTimesConnections || [])[0]
                                                if (variantPT) {
                                                    return (
                                                        <div className='p-[8px] shadow-small dark:bg-darkaccent grid grid-cols-[100px_1fr_100px] gap-[16px]'>
                                                            <p className='text-[12px] font-bold uppercase text-fire break-all'>
                                                                <span className='text-blue dark:text-accent'>
                                                                    {variant.sku}
                                                                </span>
                                                                <br />
                                                                {variantPT.name} - {variantPT.type}
                                                            </p>
                                                            <div className='text-darkgrey font-bold dark:text-offwhite text-[14px]'>
                                                                <p>{variantPT.pdp_line_1}</p>
                                                                <p>{variantPT.pdp_line_2}</p>
                                                                <p>{variantPT.cart_line_1}</p>
                                                            </div>
                                                            {variantPT.starts_on ||
                                                                (variantPT.ends_on && (
                                                                    <div className='text-[12px] uppercase font-bold dark:text-offwhite'>
                                                                        <p>
                                                                            Starts:{' '}
                                                                            {variantPT.starts_on &&
                                                                                new Date(
                                                                                    variantPT.starts_on
                                                                                ).toDateString()}
                                                                        </p>
                                                                        <p>
                                                                            Ends:{' '}
                                                                            {variantPT.ends_on &&
                                                                                new Date(
                                                                                    variantPT.ends_on
                                                                                ).toDateString()}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

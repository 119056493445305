import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FaRegCalendarCheck } from 'react-icons/fa'
import { MdLocalShipping, MdLocationPin } from 'react-icons/md'
import { TbCircle } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Calendar from '../../custom_components/Calendar'
import { UserlistDropdown } from '../../custom_components/UserlistDropdown'
import DraftNewNoteField from '../../draftOrders/components/DraftNewNoteField'
import EmailModal from '../../emailModal/EmailModal'
import { EmailInit } from '../../emails/email.types'
import {
    DayMap,
    MonthMap,
    WebsocketProvider,
    createOrderNotesArray,
    dayMap,
    fetchActiveUsers,
    formatMoney,
    fpdashRegex,
    monthMap,
    parseResObject,
    sendToast,
    taskIdRegex,
    timestampRegex,
    vFetch,
} from '../../helpers'
import { renderModalDiv } from '../../layout/ModalDiv'
import NewNoteField from '../../orders/components/NewNoteField'
import { OrderNote } from '../../orders/components/OrderNote'
import ReturnsBlock from '../../orders/components/ReturnsBlock'
import {
    FinancialStatusMap,
    FulfillmentLineItemInit,
    FulfillmentOrderInit,
    OrderCustomer,
    OrderInit,
    defaultOrder,
    financialStatusMap,
    orderCustomerDefaultAddress,
} from '../../orders/orders.types'
import { setAreYouSure } from '../../redux/appFunctions'
import { taskVariables } from '../TaskVariables'
import { TaskScreenEvents } from '../components/TaskScreenEvents'
import TrackingInformationBlock from '../components/TrackingInformationBlock'
import UsersOnPathNotifier from '../components/UsersOnPathNotifier'
import { STATE_CODE_LIST } from '../constants'
import { getTaskEventDate, taskDateFormatter, taskDateFormatterTLEOptions } from '../tasks.helpers'
import {
    OrderButtonPropsInit,
    TaskEditInit,
    TaskEventType,
    TaskTimelineEvent,
    defaultTaskCreate,
    defaultTaskCustomer,
    topicMap,
} from '../tasks.types'
import './TaskScreen.css'
import PrivateEventModal from '../components/PrivateEventModal'
import PrivateEvent from '../components/PrivateEvent'

export default function TaskScreen() {
    const dispatch = useDispatch()
    const { user, settings } = useSelector<any, any>((state) => state)
    const { taskId } = useParams()
    const navigate = useNavigate()
    const { priorityLevels } = taskVariables

    const [yesImSure, setYesImSure] = useState(false)
    const [showAreYouSureUpdate, setShowAreYouSureUpdate] = useState(false)
    const [userList, setUserList] = useState([])

    const [edited, setEdited] = useState(false)
    const dueAtRef = useRef<HTMLInputElement>(null)
    const [customer, setCustomer] = useState<OrderCustomer>(defaultTaskCustomer)
    const [timelineEvents, setTimelineEvents] = useState<TaskTimelineEvent[]>([])
    const [showCustomer, setShowCustomer] = useState<string[]>([])
    const [comment, setComment] = useState('')
    const [selectedProducts, setSelectedProducts] = useState([])
    const [filteredOrders, setFilteredOrders] = useState([])
    const [orderSearch, setOrderSearch] = useState('')
    const [order, setOrder] = useState<OrderInit>(defaultOrder)
    const [showAttachedOrder, setShowAttachedOrder] = useState(false)
    const [attachedOrder, setAttachedOrder] = useState<OrderInit | any>(defaultOrder)
    const [attachedFulfillmentOrders, setAttachedFulfillmentOrders] = useState([])
    const [showDiscount, setShowDiscount] = useState<string>('')
    const [showTaxRates, setShowTaxRates] = useState(false)
    const defaultTask: TaskEditInit = {
        ...defaultTaskCreate,
        id: 0,
        assigned_to: [user.email.split('@')[0]],
        due_at: new Date(),
        customer: customer,
        due_at_revisions: 0,
        priority: 'Low',
        project: 'FactoryPure',
        status: 'OPEN',
        task_type: 'Customer Service',
        topic: 'Return',
        created_at: new Date(),
        updated_at: '',
        timeline_events: [],
    }
    const [originalTask, setOriginalTask] = useState<TaskEditInit>(defaultTask)
    const [task, setTask] = useState<TaskEditInit>(defaultTask)
    const [taskEventLogged, setTaskEventLogged] = useState(false)
    const [taskEvents, setTaskEvents] = useState<TaskEventType[]>([])
    const [latestTaskEvent, setLatestTaskEvent] = useState<TaskEventType | null>(null)
    const [taskEventDate, setTaskEventDate] = useState('')
    const [viewModal, setViewModal] = useState(false)
    const [publicTaskEvents, setPublicTaskEvents] = useState([])
    const [events, setEvents] = useState<any>([])
    const [emails, setEmails] = useState<EmailInit[]>([])

    const [mode, setMode] = useState<'customer' | 'manufacturer' | null>(null)
    const [showOrderDropdown, setShowOrderDropdown] = useState(false)
    const searchInput = useRef<HTMLInputElement>(null)

    const eventSource: {
        foreign_id: any
        foreign_table: string
    } = {
        foreign_id: taskId,
        foreign_table: 'tasks',
    }

    const locationMap: any = {
        '649429017': 'FactoryPure - 13814 Lookout Rd.',
        '68461527229': 'Dropship',
    }

    const handleChangeCustomer = ({ target }: MouseEvent) => {
        const inputTarget = target as HTMLInputElement
        const address = { ...orderCustomerDefaultAddress }
        if (inputTarget.name.includes('shipping_address')) {
            setCustomer({
                ...customer,
                shipping_address: {
                    ...address,
                    ...customer.shipping_address,
                    [inputTarget.name.replace('shipping_address.', '')]: inputTarget.value,
                },
            })
        } else if (inputTarget.name.includes('billing_address')) {
            setCustomer({
                ...customer,
                billing_address: {
                    ...address,
                    ...customer?.billing_address,
                    [inputTarget.name.replace('billing_address.', '')]: inputTarget.value,
                },
            })
        } else if (inputTarget.name.includes('default_address')) {
            setCustomer({
                ...customer,
                default_address: {
                    ...address,
                    ...customer.default_address,
                    [inputTarget.name.replace('default_address.', '')]: inputTarget.value,
                },
            })
        } else {
            setCustomer({
                ...customer,
                [inputTarget.name]: inputTarget.value,
            })
        }
    }

    const handleChangeTask = ({ target }: MouseEvent) => {
        const inputTarget = target as HTMLInputElement
        if (inputTarget.type === 'checkbox') {
            setTask({
                ...task,
                [inputTarget.name]: inputTarget.checked,
            })
        } else if (inputTarget.name === 'task_type') {
            setTask({
                ...task,
                [inputTarget.name]: inputTarget.value,
            })
        } else {
            setTask({
                ...task,
                [inputTarget.name]: inputTarget.value,
            })
        }
    }
    function handleChangeComment({ target }: ChangeEvent) {
        const minHeight = parseInt(
            window.getComputedStyle(target, null).getPropertyValue('min-height').replace('px', '')
        )
        const inputTarget = target as HTMLInputElement
        if (inputTarget.scrollHeight > minHeight) {
            inputTarget.style.height = '0'
            inputTarget.style.height = `${inputTarget.scrollHeight + 16}px`
        }

        setComment(inputTarget.value)
    }

    function logTaskEvent() {
        if (!taskEventLogged) {
            if (user && task) {
                vFetch(`/v1/taskEvents`, {
                    skipToast: true,
                    method: 'POST',
                    body: JSON.stringify({
                        task_id: taskId,
                        user_id: user.id,
                        event_type: 'viewed',
                    }),
                    cb: (res: any) => {
                        if (res.success) {
                            setTaskEventLogged(true)
                        }
                    },
                })
            }
        }
    }

    function refresh() {
        vFetch(`/v1/tasks/${taskId}`, {
            cb: (res: any) => {
                if (res.success) {
                    const returnedTask: any = parseResObject(res.task)
                    res.task.due_at = new Date(res.task.due_at.toISOString().slice(0, -1))
                    returnedTask['customer'] = returnedTask.customer || {}

                    vFetch(`/v1/taskEventsPublic?id=${taskId}`, {
                        cb: (res: any) => {
                            if (res.success)
                                setPublicTaskEvents(
                                    res.events.map((e: any) => {
                                        return { ...parseResObject(e), normalized_event_type: 'Task Events Public' }
                                    })
                                )
                        },
                    })

                    if (returnedTask.order_id) {
                        navigate(`/orders/${returnedTask.order_id}`)
                    } else if (returnedTask.draft_order_id) {
                        navigate(`/draftOrders/${returnedTask.draft_order_id}`)

                        vFetch(`/v1/draftOrders/${returnedTask.draft_order_id}`, {
                            cb: (dRes: any) => {
                                if (dRes.success) {
                                    if (dRes.draft_order) {
                                        const resOrder = parseResObject(dRes.draft_order)
                                        if (resOrder.order_id) {
                                            vFetch(`/v1/orders/${resOrder.order_id}`, {
                                                cb: (res: any) => {
                                                    if (res.success) {
                                                        const resAttachedOrder: any = parseResObject(res.order)
                                                        vFetch(
                                                            `/v1/products/limited/handles?gid=${resAttachedOrder.line_items.map(
                                                                (i: any) => `gid://shopify/Product/${i.product_id}`
                                                            )}`,
                                                            {
                                                                cb: (pRes: any) => {
                                                                    if (pRes.success) {
                                                                        pRes.products.forEach(
                                                                            (p: any) =>
                                                                                (resAttachedOrder.line_items.find(
                                                                                    (i: any) =>
                                                                                        i.product_id ==
                                                                                        p.gid.replace(
                                                                                            'gid://shopify/Product/',
                                                                                            ''
                                                                                        )
                                                                                ).handle = p.handle)
                                                                        )
                                                                        let unparsedNote = resAttachedOrder.note
                                                                        let stringArray = []
                                                                        if (unparsedNote) {
                                                                            while (unparsedNote.includes('\n')) {
                                                                                const index = unparsedNote.indexOf('\n')
                                                                                unparsedNote = unparsedNote.replace(
                                                                                    '\n',
                                                                                    ''
                                                                                )
                                                                                const section = unparsedNote.slice(
                                                                                    0,
                                                                                    index
                                                                                )
                                                                                stringArray.push(section)
                                                                                unparsedNote = unparsedNote.slice(index)
                                                                            }
                                                                            stringArray.push(unparsedNote)
                                                                            resAttachedOrder.parsed_note = stringArray
                                                                        } else {
                                                                            resAttachedOrder.parsed_note = [
                                                                                'There are no notes on this order.',
                                                                            ]
                                                                        }
                                                                        setAttachedOrder(resAttachedOrder)
                                                                    }
                                                                },
                                                            }
                                                        )
                                                    }
                                                },
                                            })
                                            vFetch(`/v1/orders/${resOrder.order_id}/fulfillment-orders`, {
                                                cb: (res: any) => setAttachedFulfillmentOrders(res.fulfillment_orders),
                                            })
                                        }
                                        setOrder(resOrder)
                                    } else {
                                        sendToast({
                                            message:
                                                'Draft Order could not be found. It may have been deleted from Shopify.',
                                        })
                                    }
                                }
                            },
                        })
                    }

                    for (let key of Object.keys(returnedTask['customer'])) {
                        if (returnedTask.customer[key] === null) {
                            returnedTask.customer[key] = ''
                        }
                    }
                    setCustomer(returnedTask.customer)
                    setTimelineEvents(returnedTask.timeline_events)
                    setTask({ ...returnedTask, new_order_note: '' })
                    setOriginalTask({ ...returnedTask, new_order_note: '' })
                    logTaskEvent()
                }
            },
        })
        vFetch(`/v1/taskEvents?task_id=${taskId}`, {
            cb: (res: any) => {
                if (res.taskEvents) {
                    setTaskEvents(res.taskEvents)
                    setLatestTaskEvent(res.taskEvents.find((te: TaskEventType) => te.event_type === 'viewed'))
                    getTaskEventDate(res.taskEvents[0], setTaskEventDate)
                }
            },
        })

        // fetches the user list for populating the "Assigned To" select element
        if (user.type == 'Admin' || user.type == 'SuperAdmin') fetchActiveUsers(setUserList)
    }
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            const target = e.target as HTMLElement
            if (!searchInput.current!.contains(target as HTMLElement)) {
                setShowOrderDropdown(false)
            }
        }
        window.addEventListener('click', listenToWindow)

        refresh()
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    useEffect(() => {
        setOrderSearch(order.name)
        setTask({ ...task, order_notes: order.note || '' })
    }, [order])

    useEffect(() => {
        setTask({
            ...task,
            customer: customer,
        })
    }, [customer])

    useEffect(() => {
        setEvents([...publicTaskEvents, ...emails])
    }, [publicTaskEvents, emails])

    useEffect(() => {
        vFetch(`/v1/email?table_id=tasks=${taskId}`, {
            cb: (res: any) =>
                setEmails(
                    res.emails.map((email: any) => {
                        return { ...parseResObject(email), normalized_event_type: 'Email' }
                    })
                ),
        })
    }, [])

    useEffect(() => {
        setTask({
            ...task,
            timeline_events: timelineEvents,
        })
    }, [timelineEvents])

    useEffect(() => {
        setEdited(JSON.stringify({ ...task, order_notes: '' }) !== JSON.stringify({ ...originalTask, order_notes: '' }))

        const textAreas = document.getElementsByTagName('textarea') as any
        for (let element of textAreas) {
            const minHeight = parseInt(
                window.getComputedStyle(element, null).getPropertyValue('min-height').replace('px', '')
            )

            if (element.scrollHeight > minHeight) {
                element.style.height = '0'
                element.style.height = `${element.scrollHeight + 16}px`
            }
        }
        dueAtRef.current!.value = [task.due_at.getMonth() + 1, task.due_at.getDate(), task.due_at.getFullYear()].join(
            '/'
        )
    }, [task])

    const useDebounce = (value: string = '', delay: number) => {
        const [debouncedValue, setDebouncedValue] = useState(value)
        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value.toLowerCase())
            }, delay)
            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])
        return debouncedValue
    }
    const debouncedSearch = useDebounce(orderSearch, 300)
    const abortController = new AbortController()
    useEffect(() => {
        vFetch(`/v1/draftOrders?fields=name,admin_graphql_api_id,id&search=${debouncedSearch.replace('#D', '')}`, {
            abortController,
            cb: (res: any) => {
                if (res.success) setFilteredOrders(res.draft_orders)
            },
        })
        if (
            debouncedSearch &&
            debouncedSearch.length > 0 &&
            (!order.name || (order.name && debouncedSearch !== order.name.toLowerCase()))
        )
            setShowOrderDropdown(true)
        else setShowOrderDropdown(false)

        return () => abortController.abort()
    }, [debouncedSearch])

    const OrderButton = ({ currentOrder }: OrderButtonPropsInit) => {
        const handleChangeOrder = () => {
            vFetch(`/v1/orders/${currentOrder.id}`, {
                cb: (res: any) => {
                    if (res.success) {
                        const resOrder: any = parseResObject(res.order)
                        setOrder(resOrder)
                        setTask({
                            ...task,
                            order_id: res.order.id,
                            order_name: res.order.name,
                            order_notes: res.order.note,
                        })
                    }
                },
            })
            setShowOrderDropdown(false)
        }
        return (
            <div
                onClick={handleChangeOrder}
                className='js-dropdown__option flex px-[8px] py-[2px] gap-[8px] items-center cursor-pointer hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent'
            >
                <div
                    className={`w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-dropdown__checkbox${
                        order.name === currentOrder.name ? ' js-dropdown__checkbox--checked' : ''
                    }  pointer-events-none`}
                >
                    &nbsp;
                </div>
                <p>{currentOrder.name}</p>
            </div>
        )
    }

    const DraftButton = ({ currentOrder }: OrderButtonPropsInit) => {
        const handleChangeOrder = () => {
            vFetch(`/v1/tasks?draft_order_name=${encodeURIComponent(currentOrder.name)}`, {
                cb: (res: any) => {
                    if (res.success && res.tasks && res.tasks.length > 0) {
                        sendToast({
                            message: `Task already exists for this draft! fpdash.com/tasks/${res.tasks[0].id}`,
                        })
                    } else {
                        vFetch(`/v1/draftOrders/${currentOrder.id}`, {
                            cb: (res: any) => {
                                if (res.success) {
                                    const resDraftOrder: any = parseResObject(res.draft_order)
                                    setOrder(resDraftOrder)
                                    setTask({
                                        ...task,
                                        draft_order_id: res.draft_order.id,
                                        draft_order_name: res.draft_order.name,
                                        order_notes: res.draft_order
                                            ? res.draft_order.note
                                                ? res.draft_order.note
                                                : ''
                                            : '',
                                    })
                                }
                            },
                        })
                    }
                },
            })
            setShowOrderDropdown(false)
        }
        return (
            <div
                onClick={handleChangeOrder}
                className='js-dropdown__option flex px-[8px] py-[2px] gap-[8px] items-center cursor-pointer hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent'
            >
                <div
                    className={`w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-dropdown__checkbox${
                        order.name === currentOrder.name ? ' js-dropdown__checkbox--checked' : ''
                    }  pointer-events-none`}
                >
                    &nbsp;
                </div>
                <p>{currentOrder.name}</p>
            </div>
        )
    }

    const handleSubmit = async (arg?: any, value?: any, reassignTo?: any, skip?: any) => {
        if (task.assigned_to.length == 0) {
            sendToast({ message: 'Please assign someone to this task.' })
            return { success: false }
        }
        if (!skip && task.title === originalTask.title && !yesImSure) {
            setShowAreYouSureUpdate(true)
            return 'cancel'
        }

        let lastTimestamp = new Date(order.created_at)

        let taskOrderNotes =
            createOrderNotesArray(order.note, userList)
                ?.map((n: any) => {
                    let abbreviatedType = ''
                    if (n.from.name !== 'Shopify') {
                        for (let word of n.from.name.split(' ')) {
                            abbreviatedType += word.slice(0, 1).toUpperCase()
                        }
                    }

                    const t = n.created_at
                    let noteEnd = ''

                    const timestampIncluded = n.note.match(timestampRegex)
                    if (t) {
                        lastTimestamp = t
                    }

                    if (!timestampIncluded && t) {
                        noteEnd += ` (${((t.getMonth() + 1) / 10).toFixed(1).replace('.', '')}-${(t.getDate() / 10)
                            .toFixed(1)
                            .replace('.', '')}-${t.getFullYear().toString().slice(2, 4)} ${
                            t.getHours() === 0
                                ? '12'
                                : ((t.getHours() - (t.getHours() > 12 ? 12 : 0)) / 10).toFixed(1).replace('.', '')
                        }:${(t.getMinutes() / 10).toFixed(1).replace('.', '')}${t.getHours() >= 12 ? 'pm' : 'am'}) ${
                            n.author
                        } ${abbreviatedType}\n\n`
                    } else if (!timestampIncluded && !t) {
                        noteEnd += ` (${((lastTimestamp.getMonth() + 1) / 10).toFixed(1).replace('.', '')}-${(
                            lastTimestamp.getDate() / 10
                        )
                            .toFixed(1)
                            .replace('.', '')}-${lastTimestamp.getFullYear().toString().slice(2, 4)} ${
                            lastTimestamp.getHours() === 0
                                ? '12'
                                : ((lastTimestamp.getHours() - (lastTimestamp.getHours() > 12 ? 12 : 0)) / 10)
                                      .toFixed(1)
                                      .replace('.', '')
                        }:${(lastTimestamp.getMinutes() / 10).toFixed(1).replace('.', '')}${
                            lastTimestamp.getHours() >= 12 ? 'pm' : 'am'
                        }) -- ${abbreviatedType}\n\n`
                    } else {
                        noteEnd += ` ${abbreviatedType}`
                    }

                    return n.note.trim() + noteEnd
                })
                .join('') || ''
        let abbreviatedType = ''
        for (let word of task.task_type.split(' ')) {
            abbreviatedType += word.slice(0, 1).toUpperCase()
        }

        const livingTasks = task.order_notes?.match(taskIdRegex)
        if (livingTasks) {
            livingTasks[0].includes(`${abbreviatedType}:${task.id}\n`)
                ? (taskOrderNotes = livingTasks[0].trim() + '\n\n' + taskOrderNotes)
                : (taskOrderNotes = `${abbreviatedType}:${task.id}\n` + livingTasks[0].trim() + '\n\n' + taskOrderNotes)
        } else {
            taskOrderNotes = `${abbreviatedType}:${task.id}\n\n` + taskOrderNotes
        }

        const taskChanges: any = {}
        if (arg) taskChanges[arg] = value
        Object.keys(task).forEach((property: any) => {
            if (
                task[property as keyof TaskEditInit] !== originalTask[property as keyof TaskEditInit] &&
                property !== 'timeline_events'
            ) {
                taskChanges[property] = task[property as keyof TaskEditInit]
                if (property === 'task_type') {
                    taskChanges['topic'] = (document.getElementById('topic') as HTMLInputElement).value
                }
                if (task.order_id || task.draft_order_id) {
                    if (property === 'new_order_note') {
                        if (taskOrderNotes.match(fpdashRegex)) {
                            taskOrderNotes = taskOrderNotes.replace(fpdashRegex, '')
                        }

                        const timestampIncluded = task.new_order_note.match(timestampRegex)
                        let newNote = `\n\n${task.new_order_note}`

                        if (!timestampIncluded) {
                            newNote += ` (${((new Date().getMonth() + 1) / 10).toFixed(1).replace('.', '')}-${(
                                new Date().getDate() / 10
                            )
                                .toFixed(1)
                                .replace('.', '')}-${new Date().getFullYear().toString().slice(2, 4)} ${
                                new Date().getHours() === 0
                                    ? '12'
                                    : ((new Date().getHours() - (new Date().getHours() > 12 ? 12 : 0)) / 10)
                                          .toFixed(1)
                                          .replace('.', '')
                            }:${(new Date().getMinutes() / 10).toFixed(1).replace('.', '')}${
                                new Date().getHours() >= 12 ? 'pm' : 'am'
                            }) ${user.user_id} ${abbreviatedType}\n\n`
                        } else {
                            newNote += ` ${abbreviatedType}\n\n`
                        }
                        taskChanges['order_notes'] = (taskOrderNotes.trim() + newNote).trim()
                        taskOrderNotes = (taskOrderNotes.trim() + newNote).trim()
                    }
                }
            }
        })
        if (reassignTo && task['assigned_to'] === originalTask['assigned_to'])
            taskChanges['assigned_to'] = [user.user_id]
        if (task.order_id || task.draft_order_id) {
            if (!taskChanges['order_notes'] && taskOrderNotes !== task.order_notes)
                taskChanges['order_notes'] = taskOrderNotes.trim()
        }

        const success = await vFetch(`/v1/tasks/${task.id}`, {
            method: 'PUT',
            body: JSON.stringify(taskChanges),
            cb: (res: any) => {
                if (res.success) {
                    if (task.order_notes && task.order_notes.trim() !== taskOrderNotes.trim()) {
                        if (task.order_id) {
                            vFetch(`/v1/orders/${task.order_id}`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    note: taskOrderNotes.trim(),
                                    task_id: task.id,
                                    task_type: task.task_type,
                                }),
                            })
                        } else if (task.draft_order_id) {
                            vFetch(`/v1/draftOrders/${task.draft_order_id}`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    note: taskOrderNotes.trim(),
                                    task_id: task.id,
                                    task_type: task.task_type,
                                }),
                            })
                        }
                    }

                    return true
                }
                return { success: false }
            },
            catchCb: (err: any) => {
                return { success: false }
            },
        })

        const newTLE = {
            created_at: Intl.DateTimeFormat('en-US', taskDateFormatterTLEOptions).format(new Date()),
            event_type: 'edit',
            message: `${user.firstName} ${user.lastName} (${user.user_id}) edited this task (${Object.keys(taskChanges)
                .filter((key) => !['undefined', 'new_order_note'].includes(key))
                .join(', ')})`,
            user: {
                email: user.email,
                first_name: user.firstName,
                last_name: user.lastLame,
                type: user.type,
                user_id: user.user_id,
            },
            previous_state: originalTask,
        }
        return { success: true, taskOrderNotes: taskOrderNotes, newTLE: newTLE }
    }
    const handleUpdate = async () => {
        const updateResponse: any = await handleSubmit()

        if (updateResponse === 'cancel') return
        if (updateResponse.success) refresh()
    }

    const handleComplete = async () => {
        dispatch(
            setAreYouSure({
                func: 'markTaskCompleted',
                name: 'MARK AS COMPLETED',
                purpose: 'REDIRECT',
                args: {
                    task: task,
                },
            })
        )

        const updateResponse = await handleSubmit(undefined, undefined, false, true)
        if (!updateResponse) {
            sendToast({ message: 'Something went wrong!' })
        } else {
            renderModalDiv()
        }
    }

    const handleReopen = async () => {
        const updateResponse = await handleSubmit('status', 'OPEN', true, true)

        if (updateResponse) refresh()
        else sendToast({ message: 'Something went wrong!' })
    }

    const handleSubmitComment = async (event: MouseEvent) => {
        const commentTrimmed = comment.trim()
        if (commentTrimmed) {
            vFetch(`/v1/tasks/comment`, {
                method: 'POST',
                body: JSON.stringify({
                    task_id: task.id,
                    message: commentTrimmed,
                    event_type: 'comment',
                }),
                cb: (res: any) => {
                    if (res.success) {
                        const newTLE: TaskTimelineEvent = {
                            created_at: taskDateFormatter(new Date()),
                            event_type: 'comment',
                            message: comment,
                            user: {
                                email: user.email,
                                first_name: user.firstName,
                                last_name: user.lastName,
                                type: user.type,
                                user_id: user.user_id,
                            },
                        }

                        refresh()

                        setComment('')
                        ;(event.target as HTMLInputElement).value = ''

                        setTask({
                            ...task,
                            timeline_events: [newTLE, ...task.timeline_events],
                        })
                        setOriginalTask({
                            ...originalTask,
                            timeline_events: [newTLE, ...task.timeline_events],
                        })
                    }
                },
            })
        } else {
            sendToast({ message: 'Please enter a comment before clicking "add comment".' })
        }
    }

    function onEmailSubmit(): any {
        vFetch(`/v1/email?table_id=tasks=${taskId}`, {
            cb: (res: any) => {
                if (res.success) {
                    setEmails(
                        res.emails.map((email: any) => {
                            return { ...parseResObject(email), normalized_event_type: 'Email' }
                        })
                    )
                }
            },
        })
    }
    const handleDelete = async () => {
        dispatch(
            setAreYouSure({
                func: 'deleteTask',
                name: 'DELETE THIS TASK',
                purpose: 'DANGER',
                args: {
                    task: task,
                },
            })
        )
        renderModalDiv()
    }

    const handleChangeDueAt = (startDate: Date) => {
        setTask({ ...task, due_at: startDate })
    }

    useEffect(() => {
        if (yesImSure) {
            handleUpdate()
        }
    }, [yesImSure])

    const buttonStyle = 'px-[8px] py-[2px] uppercase text-fire border-[2px] border-fire font-semibold rounded'

    return (
        <>
            <div className='relative z-[0] new-task text-black dark:text-offwhite'>
                <style>{`
                .content {
                    background-color: #fafafa;
                }
            `}</style>
                {showAreYouSureUpdate && (
                    <div className='fixed grid justify-center items-center z-index-5 left-[216px] top-[51px] h-[calc(100%-51px)] w-[calc(100%-216px)] bg-black/20'>
                        <div className='grid justify-center items-center bg-white dark:bg-darkaccent p-[16px] rounded gap-[16px] dark:text-white'>
                            <p>
                                You didn't update <strong>LATEST ACTIVITY</strong>.
                            </p>
                            <p>Are you sure you want to submit this update?</p>
                            <div className='flex justify-center gap-[24px]'>
                                <button
                                    className='p-[8px] bg-blue hover:bg-blue/70 font-bold text-white rounded w-[45px] text-center'
                                    onClick={() => {
                                        setYesImSure(true)
                                        setShowAreYouSureUpdate(false)
                                    }}
                                >
                                    Yes
                                </button>
                                <button
                                    className='p-[8px] bg-blue hover:bg-blue/70 font-bold text-white rounded w-[45px] text-center'
                                    onClick={() => {
                                        setShowAreYouSureUpdate(false)
                                    }}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {task.status == 'OPEN' ? (
                    <button
                        className='block fixed z-index-1 top-[51px] right-0 py-[8px] px-[16px] font-bold bg-blue dark:bg-accent text-white dark:text-black shadow-small dark:shadow-cool text-center uppercase cursor-pointer'
                        onClick={handleComplete}
                    >
                        Mark As Completed
                    </button>
                ) : (
                    <button
                        className='block fixed z-index-1 top-[51px] right-0 py-[8px] px-[16px] font-bold bg-blue dark:bg-accent text-white dark:text-black shadow-small dark:shadow-cool text-center uppercase cursor-pointer'
                        onClick={handleReopen}
                    >
                        Reopen Task
                    </button>
                )}

                <div className='flex w-[100%] h-[40px] font-bold justify-between items-center px-[8px] mt-[8px] mb-[4px] dark:text-offwhite'>
                    <div className='flex-col items-center gap-8'>
                        <h1 className='text-[24px] font-light tracking-[2px] uppercase font-bai'>
                            Task {task.id} {task.status == 'COMPLETED' ? '- COMPLETED' : ''}
                        </h1>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && taskEvents.length > 0 && (
                            <PrivateEvent events={taskEvents} setViewModal={setViewModal} userList={userList} />
                        )}
                    </div>
                    <WebsocketProvider>
                        <UsersOnPathNotifier />
                    </WebsocketProvider>
                </div>
                <div className='flex gap-[32px]'>
                    <div className='flex flex-col gap-[8px] w-[35%] z-index-1'>
                        <div className='sticky grid gap-[16px] top-[90px]'>
                            <div className='grid gap-[16px] overflow-auto max-h-[calc(100vh-206px)] p-[8px]'>
                                <div className='relative p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                                    {/* Saving this for later */}
                                    {/* <div className='absolute right-[52px] top-[8px] w-[20px] h-[20px] [&>*]:fill-blue [&>*]:dark:fill-accent cursor-pointer z-index-2' onClick={() => {setRemindMe(!remindMe)}}>
                                    {remindMe ? <BsStarFill className='w-[20px] h-[20px] pointer-events-none' /> : 
                                    <BsStar className=' w-[20px] h-[20px] pointer-events-none' />}
                                    <div className='flex w-[20px] opacity-0 hover:opacity-100 absolute left-0 top-0 delay-300 duration-300'>
                                        <p className='relative top-[-36px] left-[-20px] p-[2px] px-[4px] dark:text-offwhite whitespace-nowrap bg-white dark:bg-darkness border-[1px] w-auto border-black dark:border-darkgrey cursor-auto'>Remind{remindMe ? "ing" : ""} Me</p>
                                    </div>
                                </div>
                                <div className='absolute right-[16px] top-[8px] w-[20px] h-[20px] [&>*]:fill-blue [&>*]:dark:fill-accent cursor-pointer z-index-2' onClick={() => {setSubscribedToTask(!subscribedToTask)}}>
                                    {subscribedToTask ? <AiFillBell className='w-[22px] h-[22px] pointer-events-none' /> : 
                                    <AiOutlineBell className=' w-[22px] h-[22px] pointer-events-none' />}
                                    <div className='flex w-[20px] opacity-0 hover:opacity-100 absolute left-0 top-0 delay-300 duration-300'>
                                        <p className='relative top-[-36px] left-[-20px] p-[2px] px-[4px] dark:text-offwhite whitespace-nowrap bg-white dark:bg-darkness border-[1px] w-auto border-black dark:border-darkgrey cursor-auto'>Subscribe{subscribedToTask ? "d" : ""} To Task Notifcations</p>
                                    </div>
                                </div> */}
                                    <h3 className='font-bai absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content]  items-center'>
                                        Task Settings
                                    </h3>
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='task_title'
                                            >
                                                LATEST ACTIVITY
                                            </label>
                                            <textarea
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='title'
                                                name='title'
                                                defaultValue={task.title}
                                                onChange={(e: any) => handleChangeTask(e)}
                                            />
                                        </div>
                                        <div className='relative w-[49%]'>
                                            <label
                                                className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='task_due_at'
                                            >
                                                DUE AT
                                            </label>
                                            <div className='flex relative'>
                                                <input
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    type='text'
                                                    id='due_at'
                                                    name='due_at'
                                                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                                    value={[
                                                        task.due_at.getMonth() + 1,
                                                        task.due_at.getDate(),
                                                        task.due_at.getFullYear(),
                                                    ].join('/')}
                                                />
                                                <input
                                                    className='absolute w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] text-transparent focus:text-black focus:dark:text-offwhite bg-transparent focus:bg-white dark:focus:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    type='text'
                                                    id='due_at'
                                                    name='due_at'
                                                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                                    onBlur={({ target }) => {
                                                        const splitDate = target.value
                                                            .replaceAll(/[a-zA-Z]/g, '')
                                                            .match(/\d+/g)
                                                        if (splitDate) {
                                                            const dueAt = new Date(
                                                                Number(splitDate[2]),
                                                                Number(splitDate[0]) - 1,
                                                                Number(splitDate[1])
                                                            )
                                                            if (
                                                                !isNaN(dueAt.getTime()) &&
                                                                dueAt.getFullYear() < new Date().getFullYear() + 3 &&
                                                                dueAt.getFullYear() > new Date().getFullYear() - 1
                                                            ) {
                                                                target.value = [
                                                                    dueAt.getMonth() + 1,
                                                                    dueAt.getDate(),
                                                                    dueAt.getFullYear(),
                                                                ].join('/')
                                                                return setTask({ ...task, due_at: dueAt })
                                                            } else {
                                                                target.value = [
                                                                    Number(task.due_at.getMonth()) + 1,
                                                                    task.due_at.getDate(),
                                                                    task.due_at.getFullYear(),
                                                                ].join('/')
                                                                return sendToast({
                                                                    message: `${dueAt.toLocaleDateString()} is out of the acceptable range.`,
                                                                })
                                                            }
                                                        }
                                                        target.value = [
                                                            Number(task.due_at.getMonth()) + 1,
                                                            task.due_at.getDate(),
                                                            task.due_at.getFullYear(),
                                                        ].join('/')
                                                        sendToast({
                                                            message: `Invalid date format: ${target.value}. \n\n\n\n Try m/d/year`,
                                                        })
                                                    }}
                                                    ref={dueAtRef}
                                                    defaultValue={[
                                                        Number(task.due_at.getMonth()) + 1,
                                                        task.due_at.getDate(),
                                                        task.due_at.getFullYear(),
                                                    ].join('/')}
                                                />
                                                <div className='absolute right-[4px] top-[4px]'>
                                                    <Calendar
                                                        startDate={task.due_at}
                                                        setStartDate={handleChangeDueAt}
                                                        endDate={task.due_at}
                                                        acceptsTextStartDate={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='task_type'
                                            >
                                                TYPE
                                            </label>
                                            {task.status === 'OPEN' && (
                                                <input
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='task_type'
                                                    name='task_type'
                                                    value={task.task_type}
                                                    disabled
                                                />
                                            )}
                                            {task.status === 'COMPLETED' && (
                                                <select
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='task_type'
                                                    name='task_type'
                                                    defaultValue={task.task_type}
                                                    onChange={(e: any) => handleChangeTask(e)}
                                                >
                                                    {Object.keys(topicMap).map((t) => (
                                                        <option value={t}>{t}</option>
                                                    ))}
                                                </select>
                                            )}
                                        </div>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='task_topic'
                                            >
                                                TOPIC
                                            </label>
                                            <select
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='topic'
                                                name='topic'
                                                value={task.topic}
                                                onChange={(e: any) => handleChangeTask(e)}
                                            >
                                                {topicMap[task.task_type].map((t: string) => (
                                                    <option value={t}>{t}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {task.task_type === 'Sales' ? (
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='draft_order_name'
                                                >
                                                    DRAFT NUMBER
                                                </label>
                                                <input
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    type='text'
                                                    id='draft_order_name'
                                                    name='draft_order_name'
                                                    value={orderSearch}
                                                    onChange={({ target }) => setOrderSearch(target.value)}
                                                    ref={searchInput}
                                                />
                                                {showOrderDropdown && (
                                                    <div className='js-dropdown absolute top-[100%] border-[1px] border-[#cdcdcd] border-t-0 dark:border-darkgrey bg-[white] dark:text-offwhite dark:bg-darkness flex flex-col gap-[8px] overflow-hidden left-[-1px] min-w-[calc(100%-6px)] z-index-5'>
                                                        {filteredOrders.slice(0, 10).map((o) => (
                                                            <DraftButton currentOrder={o} />
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='task_order_name'
                                                >
                                                    PO NUMBER
                                                </label>
                                                <input
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    type='text'
                                                    id='task_order_name'
                                                    name='order_name'
                                                    defaultValue={task.order_name || ''}
                                                    disabled
                                                    ref={searchInput}
                                                />
                                                {showOrderDropdown && (
                                                    <div className='js-dropdown absolute top-[100%] border-[1px] border-[#cdcdcd] border-t-0 dark:border-darkgrey bg-[white] dark:text-offwhite dark:bg-darkness flex flex-col gap-[8px] overflow-hidden left-[-1px] min-w-[calc(100%-6px)] z-index-5'>
                                                        {filteredOrders.slice(0, 10).map((o) => (
                                                            <OrderButton currentOrder={o} />
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div style={{ marginTop: '4px' }} className='flex gap-[8px] w-[100%]'>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='task_assign'
                                            >
                                                Assigned To
                                            </label>
                                            {user.type === 'Admin' || user.type === 'SuperAdmin' ? (
                                                <UserlistDropdown
                                                    users={userList}
                                                    selectedUsers={task.assigned_to}
                                                    setSelectedUsers={(assigned_to: string[]) =>
                                                        setTask({ ...task, assigned_to })
                                                    }
                                                />
                                            ) : (
                                                <input
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    type='text'
                                                    id='assigned_to'
                                                    name='assigned_to'
                                                    value={`${task.assigned_to}`}
                                                    disabled
                                                />
                                            )}
                                        </div>
                                        <div className='relative w-[100%]'>
                                            <label
                                                className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                htmlFor='task_priority'
                                            >
                                                Priority
                                            </label>
                                            <select
                                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                id='priority'
                                                name='priority'
                                                value={task.priority}
                                                onChange={(e: any) => handleChangeTask(e)}
                                            >
                                                {priorityLevels.map((l) => (
                                                    <option value={l}>{l}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='flex items-center justify-center border-[1px] border-blue rounded mt-auto relative w-[100%] h-fit py-[4px]'>
                                            <input
                                                className='absolute top-0 left-0 w-[100%] h-[100%] appearance-none m-0 cursor-pointer checked:bg-blue dark:checked:bg-accent peer rounded'
                                                type='checkbox'
                                                id='is_escalated'
                                                name='is_escalated'
                                                checked={task.is_escalated}
                                                onChange={(e: any) => handleChangeTask(e)}
                                            />
                                            <label
                                                className='pointer-events-none relative z-index-2 uppercase text-[15px] dark:text-offwhite peer-checked:text-white dark:peer-checked:text-black peer-checked:extrabold'
                                                htmlFor='task_escalated'
                                            >
                                                {task.is_escalated ? 'Escalated' : 'Escalate?'}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {order.line_items &&
                                    order.line_items.filter((item: any) => item.fulfillment_status === 'fulfilled')
                                        .length > 0 && <TrackingInformationBlock order={order} />}
                                {order.order_number && <ReturnsBlock order={order} />}

                                {task.customer.shipping_address && (
                                    <div className='relative p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                                        <h3
                                            className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                                            onClick={() => {
                                                showCustomer.includes('Shipping Address')
                                                    ? setShowCustomer(
                                                          showCustomer.filter((s) => s !== 'Shipping Address')
                                                      )
                                                    : setShowCustomer([...showCustomer, 'Shipping Address'])
                                            }}
                                            style={
                                                showCustomer.includes('Shipping Address')
                                                    ? { color: 'white' }
                                                    : {
                                                          width: '100%',
                                                          background: 'none',
                                                          color: '',
                                                          marginBottom: '12px',
                                                          fontSize: '16px',
                                                          position: 'static',
                                                      }
                                            }
                                        >
                                            Shipping Address
                                            {!showCustomer.includes('Shipping Address') && (
                                                <span className='inline-block ml-[auto] text-[24px] leading-[1]'>
                                                    +
                                                </span>
                                            )}
                                        </h3>
                                        <div
                                            className={`overflow-hidden ${
                                                showCustomer.includes('Shipping Address') ? 'max-h-none' : 'max-h-[0px]'
                                            }`}
                                        >
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='flex gap-[8px] w-[100%]'>
                                                    <div className='relative w-[100%]'>
                                                        <label
                                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                            htmlFor='first_name'
                                                        >
                                                            First Name
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                            id='first_name'
                                                            name='shipping_address.first_name'
                                                            value={task?.customer?.shipping_address?.first_name || ''}
                                                            onChange={(e: any) => handleChangeCustomer(e)}
                                                        />
                                                    </div>
                                                    <div className='relative w-[100%]'>
                                                        <label
                                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                            htmlFor='last_name'
                                                        >
                                                            Last Name
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                            id='last_name'
                                                            name='shipping_address.last_name'
                                                            value={task?.customer?.shipping_address?.last_name || ''}
                                                            onChange={(e: any) => handleChangeCustomer(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='company'
                                                    >
                                                        Company
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='company'
                                                        name='shipping_address.company'
                                                        value={task?.customer?.shipping_address?.company || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='address1'
                                                    >
                                                        Address
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='address1'
                                                        name='shipping_address.address1'
                                                        value={task?.customer?.shipping_address?.address1 || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='city'
                                                    >
                                                        City
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='city'
                                                        name='shipping_address.city'
                                                        value={task?.customer?.shipping_address?.city || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='province_code'
                                                    >
                                                        State
                                                    </label>
                                                    <select
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='province_code'
                                                        name='shipping_address.province_code'
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                        value={task?.customer?.shipping_address?.province_code || ''}
                                                    >
                                                        {STATE_CODE_LIST.map((stateCode) => (
                                                            <option value={stateCode}>{stateCode}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='zip'
                                                    >
                                                        Zip
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='zip'
                                                        name='shipping_address.zip'
                                                        value={task?.customer?.shipping_address?.zip || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='phone'
                                                    >
                                                        Phone
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='phone'
                                                        name='shipping_address.phone'
                                                        value={task?.customer?.shipping_address?.phone || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {task.customer.billing_address && (
                                    <div className='relative p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                                        <h3
                                            className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                                            onClick={() => {
                                                showCustomer.includes('Billing Address')
                                                    ? setShowCustomer(
                                                          showCustomer.filter((s) => s !== 'Billing Address')
                                                      )
                                                    : setShowCustomer([...showCustomer, 'Billing Address'])
                                            }}
                                            style={
                                                showCustomer.includes('Billing Address')
                                                    ? { color: 'white' }
                                                    : {
                                                          width: '100%',
                                                          background: 'none',
                                                          color: '',
                                                          marginBottom: '12px',
                                                          fontSize: '16px',
                                                          position: 'static',
                                                      }
                                            }
                                        >
                                            Billing Information
                                            {!showCustomer.includes('Billing Address') && (
                                                <span className='inline-block ml-[auto] text-[24px] leading-[1]'>
                                                    +
                                                </span>
                                            )}
                                        </h3>
                                        <div
                                            className={`overflow-hidden ${
                                                showCustomer.includes('Billing Address') ? 'max-h-none' : 'max-h-[0px]'
                                            }`}
                                        >
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='flex gap-[8px] w-[100%]'>
                                                    <div className='relative w-[100%]'>
                                                        <label
                                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                            htmlFor='first_name'
                                                        >
                                                            First Name
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                            id='first_name'
                                                            name='billing_address.first_name'
                                                            value={task?.customer?.billing_address?.first_name || ''}
                                                            onChange={(e: any) => handleChangeCustomer(e)}
                                                        />
                                                    </div>
                                                    <div className='relative w-[100%]'>
                                                        <label
                                                            className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                            htmlFor='last_name'
                                                        >
                                                            Last Name
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                            id='last_name'
                                                            name='billing_address.last_name'
                                                            value={task?.customer?.billing_address?.last_name || ''}
                                                            onChange={(e: any) => handleChangeCustomer(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='company'
                                                    >
                                                        Company
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='company'
                                                        name='billing_address.company'
                                                        value={task?.customer?.billing_address?.company || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='address1'
                                                    >
                                                        Address
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='address1'
                                                        name='billing_address.address1'
                                                        value={task?.customer?.billing_address?.address1 || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='city'
                                                    >
                                                        City
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='city'
                                                        name='billing_address.city'
                                                        value={task?.customer?.billing_address?.city || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='province_code'
                                                    >
                                                        State
                                                    </label>
                                                    <select
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='province_code'
                                                        name='billing_address.province_code'
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                        value={task?.customer?.billing_address?.province_code || ''}
                                                    >
                                                        {STATE_CODE_LIST.map((stateCode) => (
                                                            <option value={stateCode}>{stateCode}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='zip'
                                                    >
                                                        Zip
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='zip'
                                                        name='billing_address.zip'
                                                        value={task?.customer?.billing_address?.zip || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='phone'
                                                    >
                                                        Phone
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='phone'
                                                        name='billing_address.phone'
                                                        value={task?.customer?.billing_address?.phone || ''}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='relative p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small'>
                                    <h3
                                        className='absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                                        onClick={() => {
                                            showCustomer.includes('Customer Information')
                                                ? setShowCustomer(
                                                      showCustomer.filter((s) => s !== 'Customer Information')
                                                  )
                                                : setShowCustomer([...showCustomer, 'Customer Information'])
                                        }}
                                        style={
                                            showCustomer.includes('Customer Information')
                                                ? { color: 'white' }
                                                : {
                                                      width: '100%',
                                                      background: 'none',
                                                      color: '',
                                                      marginBottom: '12px',
                                                      fontSize: '16px',
                                                      position: 'static',
                                                  }
                                        }
                                    >
                                        Customer Information
                                        {!showCustomer.includes('Customer Information') && (
                                            <span className='inline-block ml-[auto] text-[24px] leading-[1]'>+</span>
                                        )}
                                    </h3>
                                    <div
                                        className={`overflow-hidden ${
                                            showCustomer.includes('Customer Information') ? 'max-h-none' : 'max-h-[0px]'
                                        }`}
                                    >
                                        <div className='flex gap-[8px] w-[100%]'>
                                            <div className='flex gap-[8px] w-[100%]'>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='customer_first_name'
                                                    >
                                                        First Name
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='customer_first_name'
                                                        name='first_name'
                                                        defaultValue={task.customer.first_name}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                                <div className='relative w-[100%]'>
                                                    <label
                                                        className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                        htmlFor='customer_last_name'
                                                    >
                                                        Last Name
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                        id='customer_last_name'
                                                        name='last_name'
                                                        defaultValue={task.customer.last_name}
                                                        onChange={(e: any) => handleChangeCustomer(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex gap-[8px] w-[100%]'>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='customer_email'
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type='email'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='customer_email'
                                                    name='email'
                                                    defaultValue={task.customer.email}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex gap-[8px] w-[100%]'>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='customer_company'
                                                >
                                                    Company
                                                </label>
                                                <input
                                                    type='text'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='customer_company'
                                                    name='default_address.company'
                                                    defaultValue={task?.customer?.default_address?.company || ''}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex gap-[8px] w-[100%]'>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='customer_company'
                                                >
                                                    Address
                                                </label>
                                                <input
                                                    type='text'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='customer_address'
                                                    name='default_address.address1'
                                                    defaultValue={task?.customer?.default_address?.address1 || ''}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex gap-[8px] w-[100%]'>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='customer_company'
                                                >
                                                    City
                                                </label>
                                                <input
                                                    type='text'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='customer_city'
                                                    name='default_address.city'
                                                    defaultValue={task?.customer?.default_address?.city || ''}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='customer_company'
                                                >
                                                    State
                                                </label>
                                                <select
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    value={task?.customer?.default_address?.province_code || ''}
                                                    id='province_code'
                                                    name='default_address.province_code'
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                >
                                                    {STATE_CODE_LIST.map((stateCode) => (
                                                        <option value={stateCode}>{stateCode}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='customer_company'
                                                >
                                                    Zip
                                                </label>
                                                <input
                                                    type='text'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='customer_zip'
                                                    name='default_address.zip'
                                                    defaultValue={task?.customer?.default_address?.zip || ''}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex gap-[8px] w-[100%]'>
                                            <div className='relative w-[100%]'>
                                                <label
                                                    className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'
                                                    htmlFor='customer_company'
                                                >
                                                    Phone
                                                </label>
                                                <input
                                                    type='text'
                                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                                    id='customer_phone'
                                                    name='default_address.phone'
                                                    defaultValue={task?.customer?.default_address?.phone || ''}
                                                    onChange={(e: any) => handleChangeCustomer(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex gap-[8px]'>
                                <button
                                    type='button'
                                    onClick={() => setMode('customer')}
                                    className='py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                                >
                                    Email Customer
                                </button>

                                {order.name && !order.name.includes('D') && (
                                    <button
                                        type='button'
                                        onClick={() => setMode('manufacturer')}
                                        className='py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                                    >
                                        Email Manu
                                    </button>
                                )}
                            </div>
                            <div className=''>
                                {edited && (
                                    <button
                                        onClick={task.status === 'OPEN' ? handleUpdate : handleReopen}
                                        className='block py-[8px] px-[16px] bg-blue text-white border-0 font-bold text-[24px] uppercase mt-[16px] mx-[auto] cursor-pointer shadow-small dark:shadow-cool'
                                    >
                                        {task.status === 'OPEN' ? 'Update Task' : 'Reopen Task'}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='relative w-[65%]'>
                        <div className='absolute flex gap-[16px] top-[-40px]'>
                            {task.order_id && (
                                <NavLink className={buttonStyle} to={`/orders/${task.order_id}`}>
                                    Order {task.order_name}
                                </NavLink>
                            )}
                            {task.draft_order_id && (
                                <NavLink className={buttonStyle} to={`/draftOrders/${task.draft_order_id}`}>
                                    Draft Order {task.draft_order_name}
                                </NavLink>
                            )}
                        </div>

                        {/* Checkmark for attached order, but first need to find a draft order with an attached order */}
                        {/* <svg className="w-[26px] h-[auto] fill-[#16a679] dark:fill-[rgb(150,255,175)] border-[3px] border-[#a1edd0] dark:border-[rgba(100,225,150,0.3)] rounded-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" role="img" focusable="false" aria-hidden="true">
                        <path d="M8.5 17a8.36 8.36 0 1 1 8.35-8.36A8.35 8.35 0 0 1 8.5 17zm0-15a6.69 6.69 0 1 0 6.68 6.68A6.68 6.68 0 0 0 8.5 2z"/>
                        <path d="M7.66 11.09a.82.82 0 0 1-.59-.24L5.4 9.18A.84.84 0 0 1 5.45 8a.82.82 0 0 1 1.13 0l1.08 1.08 2.75-2.75a.83.83 0 0 1 1.18 1.18l-3.34 3.34a.82.82 0 0 1-.59.24z"/>
                    </svg> */}

                        {/* Attached Order */}
                        {attachedFulfillmentOrders.length > 0 && (
                            <div
                                className={`grid gap-[8px] mb-[16px] dark:bg-darkaccent p-[8px] py-[16px] rounded shadow-small border-[3px] border-[#a1edd0]`}
                            >
                                <div
                                    className='relative flex cursor-pointer justify-between gap-[8px]'
                                    onClick={() => {
                                        setShowAttachedOrder(!showAttachedOrder)
                                    }}
                                >
                                    <div className='flex gap-[16px] items-center'>
                                        <h3 className='text-[rgb(74,74,74)] text-[18px] font-extrabold dark:text-offwhite'>
                                            Attached Order
                                        </h3>
                                        {attachedOrder.id && (
                                            <p
                                                onContextMenu={() => {
                                                    window.open(`/orders/${attachedOrder.id}`)
                                                }}
                                                onClick={() => {
                                                    ;(window.event as MouseEvent).ctrlKey
                                                        ? window.open(`/orders/${attachedOrder.id}`)
                                                        : navigate(`/orders/${attachedOrder.id}`)
                                                }}
                                                className='text-blue dark:text-accent hover:underline cursor-pointer'
                                            >
                                                https://fpdash.com/orders/{attachedOrder.id}
                                            </p>
                                        )}
                                    </div>
                                    <div className='relative'>
                                        <span className='absolute right-0 top-[42%] translate-y-[-50%] text-[32px] font-bold dark:text-offwhite'>
                                            {showAttachedOrder ? '-' : '+'}
                                        </span>
                                    </div>
                                </div>
                                {showAttachedOrder && (
                                    <>
                                        {attachedFulfillmentOrders &&
                                            attachedFulfillmentOrders.length > 0 &&
                                            attachedFulfillmentOrders
                                                .map((order: FulfillmentOrderInit) => order.line_items.length)
                                                .reduce((acc: number, cur: number) => acc + cur, 0) !==
                                                attachedOrder.fulfillments
                                                    .map((f: FulfillmentOrderInit) => f.line_items.length)
                                                    .reduce((acc: number, cur: number) => acc + cur, 0) &&
                                            attachedFulfillmentOrders.map(
                                                (f: FulfillmentOrderInit) =>
                                                    !f.line_items.every((item: any) => item.quantity === 0) &&
                                                    (f.status === 'open' ||
                                                        f.status === 'in_progress' ||
                                                        f.status === 'in progess') && (
                                                        <div className='grid gap-[24px] bg-white dark:bg-darkness p-[16px] shadow-small rounded dark:text-offwhite'>
                                                            <div className='flex justify-between'>
                                                                <div className='flex items-center gap-[8px] text-[14px]'>
                                                                    <svg
                                                                        className='fill-[rgb(205,105,0)] dark:fill-fire stroke-[rgba(255,205,165,1)] dark:stroke-[rgba(255,185,150,0.7)] border-[3px] border-[rgba(255,205,165,1)] dark:border-[rgba(255,185,150,0.7)] rounded-full w-[26px] h-[auto]'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        viewBox='0 0 17 17'
                                                                        role='img'
                                                                        focusable='false'
                                                                        aria-hidden='true'
                                                                    >
                                                                        <circle
                                                                            cx='8.5'
                                                                            cy='8.5'
                                                                            r='9'
                                                                            strokeWidth='3px'
                                                                            fill='none'
                                                                        />
                                                                        <path
                                                                            stroke='none'
                                                                            d='M10.09 16.41a.8.8 0 0 1-.18-1.58 6.46 6.46 0 0 0 2-.81L12 14a.79.79 0 0 1 1.07.35.8.8 0 0 1-.3 1.05 7.89 7.89 0 0 1-2.46 1 .55.55 0 0 1-.22.01zm-3.2 0h-.18a7.89 7.89 0 0 1-2.47-1A.8.8 0 0 1 5.09 14a6.49 6.49 0 0 0 2 .82.8.8 0 0 1 .6 1 .81.81 0 0 1-.78.62zm7.7-3.18a.8.8 0 0 1-.8-.8.79.79 0 0 1 .12-.42 6.27 6.27 0 0 0 .83-2 .8.8 0 0 1 1.56.36 7.89 7.89 0 0 1-1 2.47.77.77 0 0 1-.71.39zm-12.19 0a.78.78 0 0 1-.67-.37 8.14 8.14 0 0 1-1-2.46.8.8 0 0 1 1.53-.4 6.19 6.19 0 0 0 .82 2 .8.8 0 0 1-.68 1.23zm13.12-5.4a.81.81 0 0 1-.78-.63 6.46 6.46 0 0 0-.81-2 .81.81 0 0 1 .24-1.11.79.79 0 0 1 1.1.24 8 8 0 0 1 1 2.47.8.8 0 0 1-.6 1h-.18zm-14 0a.58.58 0 0 1-.19 0 .79.79 0 0 1-.6-1 8.22 8.22 0 0 1 1-2.47.82.82 0 0 1 1.11-.26.8.8 0 0 1 .25 1.11 6.49 6.49 0 0 0-.82 2 .78.78 0 0 1-.77.62zM12.33 3.3a.83.83 0 0 1-.43-.13 6.49 6.49 0 0 0-2-.82.79.79 0 0 1-.63-.93.8.8 0 0 1 .94-.64 8.15 8.15 0 0 1 2.48 1A.8.8 0 0 1 13 2.92a.78.78 0 0 1-.68.37zm-7.65 0A.82.82 0 0 1 4 2.93a.8.8 0 0 1 .22-1.1l.1-.06a7.93 7.93 0 0 1 2.39-1 .8.8 0 0 1 1 .61.79.79 0 0 1-.6 1 6.43 6.43 0 0 0-2 .82.82.82 0 0 1-.43.12z'
                                                                        />
                                                                    </svg>
                                                                    <h2 className='font-bold text-[20px] whitespace-nowrap'>
                                                                        {f.status === 'open' ||
                                                                        f.status === 'in_progress'
                                                                            ? 'Unfulfilled'
                                                                            : f.line_items.every(
                                                                                    (item: any) => item.quantity === 0
                                                                                )
                                                                              ? 'Removed'
                                                                              : 'Please tell the developers you saw this message here'}{' '}
                                                                        (
                                                                        {
                                                                            f.line_items.filter(
                                                                                (item: any) =>
                                                                                    item.fulfillable_quantity > 0
                                                                            ).length
                                                                        }
                                                                        )
                                                                    </h2>
                                                                </div>
                                                                <button className='relative w-[35px] h-[35px] text-grey hover:text-darkgrey dark:hover:text-offwhite'>
                                                                    <span
                                                                        className='js-order__dot-button absolute text-[20px] font-black bottom-[8px] left-[11px] right-[11px] tracking-wider'
                                                                        onClick={() => {
                                                                            window.alert(
                                                                                "This doesn't work yet, but I'm sure the developers are working hard to make it do something."
                                                                            )
                                                                        }}
                                                                    >
                                                                        ...
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className='grid grid-rows-[40px] items-center gap-[16px]'>
                                                                <div className='grid grid-cols-[25px_1fr] gap-[10px] items-center text-[14px]'>
                                                                    <MdLocationPin className='h-[25px] w-[auto]' />
                                                                    <div>
                                                                        <h3 className='text-darkgrey dark:text-grey text-[12px]'>
                                                                            Location
                                                                        </h3>
                                                                        <span>
                                                                            {locationMap[f.assigned_location_id]}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='grid [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                                                                {f.line_items
                                                                    .filter(
                                                                        (item: FulfillmentLineItemInit) =>
                                                                            item.fulfillable_quantity > 0
                                                                    )
                                                                    .map((item: FulfillmentLineItemInit) => {
                                                                        const oItemIndex =
                                                                            attachedOrder.line_items.findIndex(
                                                                                (orderItem: any) =>
                                                                                    orderItem.id === item.line_item_id
                                                                            )
                                                                        if (oItemIndex < 0) return

                                                                        return (
                                                                            <div
                                                                                key={item.id}
                                                                                className='flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]'
                                                                            >
                                                                                <div className='flex gap-[16px]'>
                                                                                    <div className='grid w-[100px] items-center relative'>
                                                                                        <img
                                                                                            className='object-contain min-w-[100px] min-h-[100px]'
                                                                                            src={
                                                                                                window.IMAGE_MAP[
                                                                                                    `gid://shopify/Product/${attachedOrder.line_items[oItemIndex].product_id}`
                                                                                                ]
                                                                                            }
                                                                                            alt={
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    oItemIndex
                                                                                                ].name
                                                                                            }
                                                                                        />
                                                                                        <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                                            {item.quantity}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='my-[16px]'>
                                                                                        <a
                                                                                            href={`https://factorypure.com/products/${attachedOrder.line_items[oItemIndex].handle}`}
                                                                                            target='_blank'
                                                                                            className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                                        >
                                                                                            {
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    oItemIndex
                                                                                                ].title
                                                                                            }
                                                                                        </a>
                                                                                        {item.variant_title && (
                                                                                            <p className='pt-[4px]'>
                                                                                                <strong className='text-darkgrey dark:text-grey'>
                                                                                                    Variant:
                                                                                                </strong>{' '}
                                                                                                {
                                                                                                    attachedOrder
                                                                                                        .line_items[
                                                                                                        oItemIndex
                                                                                                    ].variant_title
                                                                                                }
                                                                                            </p>
                                                                                        )}
                                                                                        <p className='pt-[4px]'>
                                                                                            <strong className='text-darkgrey dark:text-grey'>
                                                                                                SKU:
                                                                                            </strong>{' '}
                                                                                            {
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    oItemIndex
                                                                                                ].sku
                                                                                            }
                                                                                        </p>
                                                                                        <ul className='list-disc ml-[16px] mt-[8px]'>
                                                                                            {attachedOrder.line_items[
                                                                                                oItemIndex
                                                                                            ].discount_allocations.map(
                                                                                                (discount: any) => (
                                                                                                    <li>
                                                                                                        {attachedOrder
                                                                                                            .discount_applications[
                                                                                                            discount
                                                                                                                .discount_application_index
                                                                                                        ].title
                                                                                                            ? attachedOrder
                                                                                                                  .discount_applications[
                                                                                                                  discount
                                                                                                                      .discount_application_index
                                                                                                              ].title
                                                                                                            : attachedOrder
                                                                                                                  .discount_applications[
                                                                                                                  discount
                                                                                                                      .discount_application_index
                                                                                                              ]
                                                                                                                  .code}{' '}
                                                                                                        discount applied
                                                                                                    </li>
                                                                                                )
                                                                                            )}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='flex gap-[32px]'>
                                                                                        <div className='relative my-[16px] flex'>
                                                                                            {attachedOrder.line_items[
                                                                                                oItemIndex
                                                                                            ].discount_allocations
                                                                                                .length > 0 && (
                                                                                                <s
                                                                                                    onClick={() => {
                                                                                                        !showDiscount
                                                                                                            ? setShowDiscount(
                                                                                                                  attachedOrder
                                                                                                                      .line_items[
                                                                                                                      oItemIndex
                                                                                                                  ].sku
                                                                                                              )
                                                                                                            : setShowDiscount(
                                                                                                                  ''
                                                                                                              )
                                                                                                    }}
                                                                                                    className='js-attachedOrder-discounts mr-[8px] text-grey hover:underline cursor-pointer'
                                                                                                >
                                                                                                    {formatMoney(
                                                                                                        Number(
                                                                                                            attachedOrder
                                                                                                                .line_items[
                                                                                                                oItemIndex
                                                                                                            ].price
                                                                                                        )
                                                                                                    )}
                                                                                                </s>
                                                                                            )}
                                                                                            <span className='flex gap-[4px]'>
                                                                                                {formatMoney(
                                                                                                    Number(
                                                                                                        attachedOrder
                                                                                                            .line_items[
                                                                                                            oItemIndex
                                                                                                        ].pre_tax_price
                                                                                                    ) /
                                                                                                        Number(
                                                                                                            attachedOrder
                                                                                                                .line_items[
                                                                                                                oItemIndex
                                                                                                            ].quantity
                                                                                                        )
                                                                                                )}
                                                                                                <span className='top-0'>
                                                                                                    x
                                                                                                </span>
                                                                                                {
                                                                                                    attachedOrder
                                                                                                        .line_items[
                                                                                                        oItemIndex
                                                                                                    ].quantity
                                                                                                }
                                                                                            </span>
                                                                                            {showDiscount ===
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    oItemIndex
                                                                                                ].sku && (
                                                                                                <div className='js-attachedOrder-discounts absolute grid gap-[16px] top-[24px] shadow-small p-[16px] left-[-20px] rounded bg-white dark:bg-darkness z-index-4'>
                                                                                                    <h3 className='js-attachedOrder-discounts font-semibold text-[16px]'>
                                                                                                        {attachedOrder
                                                                                                            .line_items[
                                                                                                            oItemIndex
                                                                                                        ]
                                                                                                            .discount_allocations
                                                                                                            .length > 1
                                                                                                            ? 'Discounts'
                                                                                                            : 'Discount'}
                                                                                                    </h3>
                                                                                                    {attachedOrder.line_items[
                                                                                                        oItemIndex
                                                                                                    ].discount_allocations.map(
                                                                                                        (
                                                                                                            discount: any
                                                                                                        ) => (
                                                                                                            <div className='js-attachedOrder-discounts'>
                                                                                                                <h4 className='js-attachedOrder-discounts font-semibold'>
                                                                                                                    {attachedOrder
                                                                                                                        .discount_applications[
                                                                                                                        discount
                                                                                                                            .discount_application_index
                                                                                                                    ]
                                                                                                                        .allocation_method ===
                                                                                                                        'across' ||
                                                                                                                    attachedOrder
                                                                                                                        .discount_applications[
                                                                                                                        discount
                                                                                                                            .discount_application_index
                                                                                                                    ]
                                                                                                                        .allocation_method ===
                                                                                                                        'each'
                                                                                                                        ? 'Per Item'
                                                                                                                        : 'Please tell the developers if you see this message.'}
                                                                                                                </h4>
                                                                                                                <p className='js-attachedOrder-discounts text-[16px]'>
                                                                                                                    {formatMoney(
                                                                                                                        discount.amount /
                                                                                                                            Number(
                                                                                                                                attachedOrder
                                                                                                                                    .line_items[
                                                                                                                                    oItemIndex
                                                                                                                                ]
                                                                                                                                    .quantity
                                                                                                                            )
                                                                                                                    )}
                                                                                                                </p>
                                                                                                                <h4 className='js-attachedOrder-discounts font-semibold mt-[16px]'>
                                                                                                                    Reason
                                                                                                                </h4>
                                                                                                                <p className='js-attachedOrder-discounts text-[16px]'>
                                                                                                                    {
                                                                                                                        attachedOrder
                                                                                                                            .discount_applications[
                                                                                                                            discount
                                                                                                                                .discount_application_index
                                                                                                                        ]
                                                                                                                            .title
                                                                                                                    }
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        )
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        <span className='my-[16px]'>
                                                                                            {formatMoney(
                                                                                                Number(
                                                                                                    attachedOrder
                                                                                                        .line_items[
                                                                                                        oItemIndex
                                                                                                    ].pre_tax_price
                                                                                                )
                                                                                            )}
                                                                                        </span>
                                                                                    </div>
                                                                                    <div className='flex justify-end'>
                                                                                        <a
                                                                                            className='p-[8px] font-semibold text-white dark:text-black bg-[#007a5c] hover:bg-[#125443] dark:bg-fire rounded dark:hover:bg-[darkorange]'
                                                                                            href={`https://factorypure.myshopify.com/admin/products/${attachedOrder.line_items[oItemIndex].product_id}`}
                                                                                        >
                                                                                            View On Shopify
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>
                                                        </div>
                                                    )
                                            )}

                                        {/* fulfilled items*/}
                                        {attachedOrder.fulfillments &&
                                            attachedOrder.fulfillments.length > 0 &&
                                            attachedOrder.fulfillments.map(
                                                (f: any) =>
                                                    f.status !== 'cancelled' && (
                                                        <div
                                                            key={f.id}
                                                            className='grid gap-[24px] bg-white dark:bg-darkness p-[16px] shadow-small rounded dark:text-offwhite'
                                                        >
                                                            <div className='flex justify-between'>
                                                                <div className='flex items-center gap-[8px] text-[14px]'>
                                                                    <svg
                                                                        className='w-[26px] h-[auto] fill-[#16a679] dark:fill-[rgb(150,255,175)] border-[3px] border-[#a1edd0] dark:border-[rgba(100,225,150,0.3)] rounded-full'
                                                                        xmlns='http://www.w3.org/2000/svg'
                                                                        viewBox='0 0 17 17'
                                                                        role='img'
                                                                        focusable='false'
                                                                        aria-hidden='true'
                                                                    >
                                                                        <path d='M8.5 17a8.36 8.36 0 1 1 8.35-8.36A8.35 8.35 0 0 1 8.5 17zm0-15a6.69 6.69 0 1 0 6.68 6.68A6.68 6.68 0 0 0 8.5 2z' />
                                                                        <path d='M7.66 11.09a.82.82 0 0 1-.59-.24L5.4 9.18A.84.84 0 0 1 5.45 8a.82.82 0 0 1 1.13 0l1.08 1.08 2.75-2.75a.83.83 0 0 1 1.18 1.18l-3.34 3.34a.82.82 0 0 1-.59.24z' />
                                                                    </svg>
                                                                    <h2 className='font-bold text-[20px]'>
                                                                        Fulfilled ({f.line_items.length})
                                                                    </h2>
                                                                    <span className='text-darkgrey dark:text-grey'>
                                                                        {f.name.replace('.', '-F')}
                                                                    </span>
                                                                </div>
                                                                <button className='relative w-[35px] h-[35px] text-grey hover:text-darkgrey dark:hover:text-offwhite'>
                                                                    <span
                                                                        className='js-order__dot-button absolute text-[20px] font-black bottom-[8px] left-[11px] right-[11px] tracking-wider'
                                                                        onClick={() => {
                                                                            window.alert(
                                                                                "This doesn't work yet, but I'm sure the developers are working hard to make it do something."
                                                                            )
                                                                        }}
                                                                    >
                                                                        ...
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className='grid grid-rows-[40px] items-center gap-[16px]'>
                                                                <div className='grid grid-cols-[25px_1fr] gap-[10px] items-center text-[14px]'>
                                                                    <MdLocationPin className='h-[25px] w-[auto]' />
                                                                    <div>
                                                                        <h3 className='text-darkgrey dark:text-grey text-[12px]'>
                                                                            Location
                                                                        </h3>
                                                                        {/* <span>{locationMap[f.location_id]}</span> */}
                                                                        {/* <span>{locationMap[f.location_id]}</span> */}
                                                                    </div>
                                                                </div>
                                                                <div className='grid grid-cols-[25px_1fr] gap-[10px] items-center text-[14px]'>
                                                                    <FaRegCalendarCheck className='w-[20px] h-[auto] justify-self-center' />
                                                                    <div>
                                                                        <h3 className='text-darkgrey dark:text-grey text-[12px]'>
                                                                            Fulfilled
                                                                        </h3>
                                                                        <span>
                                                                            {new Date(Date.parse(f.created_at))
                                                                                .toDateString()
                                                                                .split(' ')
                                                                                .map((dateItem) =>
                                                                                    dayMap[dateItem as keyof DayMap]
                                                                                        ? `${
                                                                                              dayMap[
                                                                                                  dateItem as keyof DayMap
                                                                                              ]
                                                                                          }, `
                                                                                        : monthMap[
                                                                                                dateItem as keyof MonthMap
                                                                                            ]
                                                                                          ? `${
                                                                                                monthMap[
                                                                                                    dateItem as keyof MonthMap
                                                                                                ]
                                                                                            }`
                                                                                          : Number(dateItem) < 32
                                                                                            ? `${dateItem},`
                                                                                            : dateItem
                                                                                )
                                                                                .join(' ')}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {f.tracking_numbers.length > 0 && (
                                                                    <div className='grid grid-cols-[25px_1fr] gap-[10px] items-center text-[14px]'>
                                                                        <MdLocalShipping className='w-[25px] h-[auto]' />
                                                                        <div>
                                                                            <div className='flex gap-[8px]'>
                                                                                <h3 className='text-darkgrey dark:text-grey text-[12px]'>
                                                                                    {f.tracking_company === 'Other'
                                                                                        ? 'Other tracking'
                                                                                        : f.tracking_company}
                                                                                </h3>
                                                                                {f.status === 'success' &&
                                                                                    f.shipment_status && (
                                                                                        <span className='bg-[rgb(190,255,200)] dark:text-black font-semibold rounded-full px-[8px] text-[10px] my-auto capitalize'>
                                                                                            {f.shipment_status.replaceAll(
                                                                                                '_',
                                                                                                ' '
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                            </div>
                                                                            {f.tracking_numbers &&
                                                                            f.tracking_numbers.length > 0
                                                                                ? f.tracking_numbers.map(
                                                                                      (t: string) => {
                                                                                          const tIndex =
                                                                                              f.tracking_numbers.indexOf(
                                                                                                  t
                                                                                              )
                                                                                          return (
                                                                                              <span
                                                                                                  key={t}
                                                                                                  className='hover:underline hover:text-blue dark:hover:text-accent cursor-pointer'
                                                                                                  onClick={() => {
                                                                                                      if (
                                                                                                          !f.tracking_urls[
                                                                                                              tIndex
                                                                                                          ].includes(
                                                                                                              'ups.com'
                                                                                                          )
                                                                                                      ) {
                                                                                                          navigator.clipboard.writeText(
                                                                                                              t
                                                                                                          )
                                                                                                          setTimeout(
                                                                                                              () => {
                                                                                                                  alert(
                                                                                                                      'Tracking number copied: ' +
                                                                                                                          t +
                                                                                                                          '\n\nYou can right-click and paste the tracking number into the tracking website.\n\nClick OK to continue to the tracking website. \n(If the next page does not appear, it may have been blocked by your popup blocker. Try again.)'
                                                                                                                  )
                                                                                                                  window.open(
                                                                                                                      f
                                                                                                                          .tracking_urls[
                                                                                                                          tIndex
                                                                                                                      ],
                                                                                                                      '_blank'
                                                                                                                  )
                                                                                                              },
                                                                                                              0
                                                                                                          )
                                                                                                      } else {
                                                                                                          window.open(
                                                                                                              f.tracking_url,
                                                                                                              '_blank'
                                                                                                          )
                                                                                                      }
                                                                                                  }}
                                                                                              >
                                                                                                  {t}
                                                                                              </span>
                                                                                          )
                                                                                      }
                                                                                  )
                                                                                : f.tracking_number && (
                                                                                      <span>{f.tracking_number}</span>
                                                                                  )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='grid [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                                                                {f.line_items.map((item: any) => {
                                                                    return (
                                                                        <div
                                                                            key={item.id}
                                                                            className='flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]'
                                                                        >
                                                                            <div className='flex gap-[16px] '>
                                                                                <div className='grid w-[100px] items-center relative'>
                                                                                    <img
                                                                                        className='object-contain min-w-[100px] min-h-[100px]'
                                                                                        src={
                                                                                            window.IMAGE_MAP[
                                                                                                `gid://shopify/Product/${item.product_id}`
                                                                                            ]
                                                                                        }
                                                                                        alt={item.name}
                                                                                    />
                                                                                    <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                                        {item.quantity}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='my-[16px]'>
                                                                                    <a
                                                                                        href={`https://factorypure.com/products/${
                                                                                            attachedOrder.line_items.find(
                                                                                                (i: any) =>
                                                                                                    item.product_id ===
                                                                                                    i.product_id
                                                                                            )!.handle
                                                                                        }`}
                                                                                        target='_blank'
                                                                                        className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                                    >
                                                                                        {item.title}
                                                                                    </a>
                                                                                    {item.variant_title && (
                                                                                        <p className='pt-[4px]'>
                                                                                            <strong className='text-darkgrey dark:text-grey'>
                                                                                                Variant:
                                                                                            </strong>{' '}
                                                                                            {item.variant_title}
                                                                                        </p>
                                                                                    )}
                                                                                    <p className='pt-[4px]'>
                                                                                        <strong className='text-darkgrey dark:text-grey'>
                                                                                            SKU:
                                                                                        </strong>{' '}
                                                                                        {item.sku}
                                                                                    </p>
                                                                                    <ul className='list-disc ml-[16px] mt-[8px]'>
                                                                                        {item.discount_allocations.map(
                                                                                            (discount: any) => (
                                                                                                <li>
                                                                                                    {attachedOrder
                                                                                                        .discount_applications[
                                                                                                        discount
                                                                                                            .discount_application_index
                                                                                                    ].title
                                                                                                        ? attachedOrder
                                                                                                              .discount_applications[
                                                                                                              discount
                                                                                                                  .discount_application_index
                                                                                                          ].title
                                                                                                        : attachedOrder
                                                                                                              .discount_applications[
                                                                                                              discount
                                                                                                                  .discount_application_index
                                                                                                          ].code}{' '}
                                                                                                    discount applied
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className='flex gap-[32px]'>
                                                                                    <span className='my-[16px] flex gap-[4px]'>
                                                                                        {formatMoney(item.price)}
                                                                                        <span className='top-0'>x</span>
                                                                                        {item.quantity}
                                                                                    </span>
                                                                                    <span className='my-[16px]'>
                                                                                        {formatMoney(item.price)}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='flex justify-end'>
                                                                                    <a
                                                                                        className='p-[8px] font-semibold text-white dark:text-black bg-[#007a5c] hover:bg-[#125443] dark:bg-fire rounded dark:hover:bg-[darkorange]'
                                                                                        href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                                                    >
                                                                                        View On Shopify
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                            )}

                                        {/* Removed Items */}
                                        {attachedFulfillmentOrders &&
                                            attachedFulfillmentOrders.length > 0 &&
                                            attachedFulfillmentOrders
                                                .map((order: FulfillmentOrderInit) => order.line_items.length)
                                                .reduce((acc: number, cur: number) => acc + cur, 0) !==
                                                attachedOrder.fulfillments
                                                    .map((f: any) => f.line_items.length)
                                                    .reduce((acc: number, cur: number) => acc + cur, 0) &&
                                            attachedFulfillmentOrders.map(
                                                (f: FulfillmentOrderInit) =>
                                                    f.line_items.every(
                                                        (item: FulfillmentLineItemInit) => item.quantity === 0
                                                    ) && (
                                                        <div className='grid gap-[24px] bg-white dark:bg-darkness p-[16px] shadow-small rounded dark:text-offwhite opacity-[0.5]'>
                                                            <div className='flex justify-between'>
                                                                <div className='flex items-center gap-[8px] text-[14px]'>
                                                                    <h2 className='font-bold text-[20px] whitespace-nowrap'>
                                                                        {f.status === 'open' ||
                                                                        f.status === 'in_progress'
                                                                            ? 'Unfulfilled'
                                                                            : f.line_items.every(
                                                                                    (item: any) => item.quantity === 0
                                                                                )
                                                                              ? 'Removed'
                                                                              : 'Please tell the developers you saw this message'}{' '}
                                                                        ({f.line_items.length})
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                            <div className='grid [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                                                                {f.line_items.map((item: any) => {
                                                                    const oItemIndex =
                                                                        attachedOrder.line_items.findIndex(
                                                                            (orderItem: any) =>
                                                                                orderItem.id === item.line_item_id
                                                                        )
                                                                    if (oItemIndex < 0) return

                                                                    return (
                                                                        <div
                                                                            key={item.id}
                                                                            className='flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]'
                                                                        >
                                                                            <div className='flex gap-[16px]'>
                                                                                <div className='grid w-[100px] items-center relative'>
                                                                                    <img
                                                                                        className='object-contain min-w-[100px] min-h-[100px]'
                                                                                        src={
                                                                                            window.IMAGE_MAP[
                                                                                                `gid://shopify/Product/${attachedOrder.line_items[oItemIndex].product_id}`
                                                                                            ]
                                                                                        }
                                                                                        alt={
                                                                                            attachedOrder.line_items[
                                                                                                oItemIndex
                                                                                            ].name
                                                                                        }
                                                                                    />
                                                                                    <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                                        {item.quantity}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='my-[16px]'>
                                                                                    <a
                                                                                        href={`https://factorypure.com/products/${attachedOrder.line_items[oItemIndex].handle}`}
                                                                                        target='_blank'
                                                                                        className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                                    >
                                                                                        {
                                                                                            attachedOrder.line_items[
                                                                                                oItemIndex
                                                                                            ].title
                                                                                        }
                                                                                    </a>
                                                                                    {item.variant_title && (
                                                                                        <p className='pt-[4px]'>
                                                                                            <strong className='text-darkgrey dark:text-grey'>
                                                                                                Variant:
                                                                                            </strong>{' '}
                                                                                            {
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    oItemIndex
                                                                                                ].variant_title
                                                                                            }
                                                                                        </p>
                                                                                    )}
                                                                                    <p className='pt-[4px]'>
                                                                                        <strong className='text-darkgrey dark:text-grey'>
                                                                                            SKU:
                                                                                        </strong>{' '}
                                                                                        {
                                                                                            attachedOrder.line_items[
                                                                                                oItemIndex
                                                                                            ].sku
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className='flex gap-[32px]'>
                                                                                    <span className='my-[16px] flex gap-[4px]'>
                                                                                        {formatMoney(
                                                                                            Number(
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    oItemIndex
                                                                                                ].price
                                                                                            )
                                                                                        )}
                                                                                        <span>x</span>
                                                                                        {
                                                                                            attachedOrder.line_items[
                                                                                                oItemIndex
                                                                                            ].quantity
                                                                                        }
                                                                                    </span>
                                                                                    <span className='my-[16px]'>
                                                                                        {formatMoney(
                                                                                            Number(
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    oItemIndex
                                                                                                ].price
                                                                                            )
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='flex justify-end'>
                                                                                    <a
                                                                                        className='p-[8px] font-semibold text-white dark:text-black bg-[#007a5c] hover:bg-[#125443] dark:bg-fire rounded dark:hover:bg-[darkorange]'
                                                                                        href={`https://factorypure.myshopify.com/admin/products/${attachedOrder.line_items[oItemIndex].product_id}`}
                                                                                    >
                                                                                        View On Shopify
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    )
                                            )}

                                        {attachedFulfillmentOrders.length > 0 && (
                                            <div className='grid gap-[24px] bg-white dark:bg-darkness p-[16px] shadow-small rounded dark:text-offwhite'>
                                                <div className='flex items-center gap-[8px] text-[14px]'>
                                                    {attachedOrder.financial_status &&
                                                    financialStatusMap[
                                                        attachedOrder.financial_status as keyof FinancialStatusMap
                                                    ] &&
                                                    financialStatusMap[
                                                        attachedOrder.financial_status as keyof FinancialStatusMap
                                                    ].status === 'Paid' ? (
                                                        <>
                                                            {
                                                                financialStatusMap[
                                                                    attachedOrder.financial_status as keyof FinancialStatusMap
                                                                ].symbol
                                                            }
                                                            <h2 className='font-bold text-[20px]'>
                                                                {
                                                                    financialStatusMap[
                                                                        attachedOrder.financial_status as keyof FinancialStatusMap
                                                                    ].status
                                                                }
                                                            </h2>
                                                        </>
                                                    ) : (
                                                        <div
                                                            className={`${
                                                                attachedOrder.financial_status &&
                                                                financialStatusMap[
                                                                    attachedOrder.financial_status as keyof FinancialStatusMap
                                                                ]
                                                                    ? financialStatusMap[
                                                                          attachedOrder.financial_status as keyof FinancialStatusMap
                                                                      ].bg
                                                                    : 'bg-fire'
                                                            } w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey  rounded-full`}
                                                        >
                                                            {attachedOrder.financial_status &&
                                                            financialStatusMap[
                                                                attachedOrder.financial_status as keyof FinancialStatusMap
                                                            ] &&
                                                            financialStatusMap[
                                                                attachedOrder.financial_status as keyof FinancialStatusMap
                                                            ].symbol ? (
                                                                financialStatusMap[
                                                                    attachedOrder.financial_status as keyof FinancialStatusMap
                                                                ].symbol
                                                            ) : (
                                                                <TbCircle />
                                                            )}
                                                            <h2 className='inline-block font-bold text-[20px] text-center'>
                                                                {attachedOrder.financial_status &&
                                                                financialStatusMap[
                                                                    attachedOrder.financial_status as keyof FinancialStatusMap
                                                                ]
                                                                    ? financialStatusMap[
                                                                          attachedOrder.financial_status as keyof FinancialStatusMap
                                                                      ].status
                                                                    : 'U'}
                                                            </h2>
                                                        </div>
                                                    )}
                                                    {attachedOrder.financial_status === 'authorized' && (
                                                        <span className='text-[13px] text-darkgrey dark:text-grey'>
                                                            Expires on{' '}
                                                            {new Date(
                                                                attachedOrder.processed_at_nice.getFullYear(),
                                                                attachedOrder.processed_at_nice.getMonth(),
                                                                attachedOrder.processed_at_nice.getDate() + 7,
                                                                attachedOrder.processed_at_nice.getHours(),
                                                                0,
                                                                0,
                                                                0
                                                            )
                                                                .toDateString()
                                                                .split(' ')
                                                                .map((dateItem) =>
                                                                    dayMap[dateItem as keyof DayMap]
                                                                        ? `${dayMap[dateItem as keyof DayMap]}, `
                                                                        : monthMap[dateItem as keyof MonthMap]
                                                                          ? `${monthMap[dateItem as keyof MonthMap]}`
                                                                          : Number(dateItem) < 32
                                                                            ? `${dateItem},`
                                                                            : dateItem
                                                                )
                                                                .join(' ')}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className='grid gap-[8px]'>
                                                    <div className='flex justify-between'>
                                                        <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                                            <span className='w-[140px]'>Subtotal</span>
                                                            <span>
                                                                {attachedFulfillmentOrders
                                                                    .map((attachedOrder: FulfillmentOrderInit) =>
                                                                        attachedOrder.line_items
                                                                            .map((item: any) => item.quantity)
                                                                            .reduce(
                                                                                (acc: number, cur: number) => acc + cur,
                                                                                0
                                                                            )
                                                                    )
                                                                    .reduce(
                                                                        (acc: number, cur: number) => acc + cur,
                                                                        0
                                                                    )}{' '}
                                                                items
                                                            </span>
                                                        </div>
                                                        <span>
                                                            {attachedFulfillmentOrders.length > 0 &&
                                                                formatMoney(
                                                                    attachedFulfillmentOrders
                                                                        .map((attachedOrder: FulfillmentOrderInit) =>
                                                                            attachedOrder.line_items.filter(
                                                                                (item: any) => item.quantity > 0
                                                                            )
                                                                        )
                                                                        .filter(
                                                                            (attachedOrder) => attachedOrder.length > 0
                                                                        )
                                                                        .map((fOrder) =>
                                                                            fOrder
                                                                                .map((fItem) => {
                                                                                    const itemIndex =
                                                                                        attachedOrder.line_items.findIndex(
                                                                                            (item: any) =>
                                                                                                item.id ===
                                                                                                fItem.line_item_id
                                                                                        )
                                                                                    return (
                                                                                        fItem.quantity *
                                                                                            Number(
                                                                                                attachedOrder
                                                                                                    .line_items[
                                                                                                    itemIndex
                                                                                                ].price
                                                                                            ) -
                                                                                        Number(
                                                                                            attachedOrder.line_items[
                                                                                                itemIndex
                                                                                            ].total_discount
                                                                                        )
                                                                                    )
                                                                                })
                                                                                .reduce(
                                                                                    (acc: number, cur: number) =>
                                                                                        acc + cur,
                                                                                    0
                                                                                )
                                                                        )
                                                                        .reduce(
                                                                            (acc: number, cur: number) => acc + cur,
                                                                            0
                                                                        )
                                                                )}
                                                        </span>
                                                    </div>

                                                    {attachedOrder.discount_codes.length > 0 && (
                                                        <div className='flex justify-between'>
                                                            <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                                                <span className='w-[140px]'>Discount</span>
                                                                {attachedOrder.discount_codes.map((discount: any) => (
                                                                    <span key={discount.code}>{discount.code}</span>
                                                                ))}
                                                            </div>
                                                            {attachedOrder.discount_codes.map((discount: any) => (
                                                                <span>-{formatMoney(parseFloat(discount.amount))}</span>
                                                            ))}
                                                        </div>
                                                    )}

                                                    {attachedOrder.shipping_lines.length > 0 && (
                                                        <div className='flex justify-between'>
                                                            <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                                                <span className='w-[140px]'>Shipping</span>

                                                                <span>
                                                                    {attachedOrder.shipping_lines[0].code} (
                                                                    {(
                                                                        Number(attachedOrder.total_weight) / 453.6
                                                                    ).toFixed(1)}{' '}
                                                                    lb)
                                                                </span>
                                                            </div>
                                                            <span>
                                                                {formatMoney(
                                                                    Number(attachedOrder.shipping_lines[0].price)
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {attachedOrder.tax_lines.length > 0 && (
                                                        <div className='flex justify-between'>
                                                            <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                                                <span className='w-[140px]'>Tax</span>
                                                                {attachedOrder.tax_lines.length === 1 ? (
                                                                    <span>
                                                                        {attachedOrder.tax_lines[0].title}{' '}
                                                                        {Number(
                                                                            (
                                                                                attachedOrder.tax_lines[0].rate! * 100
                                                                            ).toFixed(4)
                                                                        )}
                                                                        %
                                                                    </span>
                                                                ) : (
                                                                    <>
                                                                        <span
                                                                            className='font-semibold hover:underline hover:text-blue dark:hover:text-accent cursor-pointer'
                                                                            onClick={() => {
                                                                                setShowTaxRates(true)
                                                                            }}
                                                                        >
                                                                            Show Tax Rates
                                                                        </span>
                                                                        {showTaxRates && (
                                                                            <div className='js-show-tax-rates-close fixed flex p-[32px] top-[50px] left-[216px] bg-[rgba(0,0,0,0.3)] w-[calc(100%-216px)] h-[calc(100%-50px)] z-50'>
                                                                                <div className='grid absolute self-center left-[50%] translate-x-[-50%] w-[calc(65%-128px)] min-w-[250px] bg-white dark:bg-darkness rounded-lg'>
                                                                                    <div className='flex justify-between items-center border-b-[1px] dark:border-b-darkgrey'>
                                                                                        <h2 className='p-[16px] text-[20px] font-bold'>
                                                                                            Tax Rates
                                                                                        </h2>
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                setShowTaxRates(false)
                                                                                            }}
                                                                                            className='font-bold mr-[16px] hover:bg-offwhite dark:hover:bg-darkness h-[32px] w-[32px] rounded-lg'
                                                                                        >
                                                                                            <span>X</span>
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className='p-[16px] flex flex-col gap-[16px] border-b-[1px] dark:border-b-darkgrey'>
                                                                                        {attachedOrder.tax_lines.map(
                                                                                            (line: any) => (
                                                                                                <div
                                                                                                    key={line.title}
                                                                                                    className='flex justify-between'
                                                                                                >
                                                                                                    <span>
                                                                                                        {line.title} (
                                                                                                        {Number(
                                                                                                            (
                                                                                                                line.rate *
                                                                                                                100
                                                                                                            ).toFixed(4)
                                                                                                        )}
                                                                                                        %)
                                                                                                    </span>{' '}
                                                                                                    <span>
                                                                                                        {formatMoney(
                                                                                                            line.price
                                                                                                        )}
                                                                                                    </span>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                        <div className='flex justify-between'>
                                                                                            <span className='font-semibold'>
                                                                                                Total Tax
                                                                                            </span>
                                                                                            <span>
                                                                                                {formatMoney(
                                                                                                    attachedOrder.total_tax
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <button
                                                                                        className='ml-[auto] mr-[16px] my-[16px] py-[4px] px-[16px] border-[1px] rounded hover:bg-offwhite dark:hover:bg-darkness'
                                                                                        onClick={() => {
                                                                                            setShowTaxRates(false)
                                                                                        }}
                                                                                    >
                                                                                        Close
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                            <span>
                                                                {formatMoney(Number(attachedOrder.current_total_tax))}
                                                            </span>
                                                        </div>
                                                    )}

                                                    <div className='flex justify-between'>
                                                        <span className='w-[140px] font-semibold'>Total</span>
                                                        <span>
                                                            {formatMoney(Number(attachedOrder.current_total_price))}
                                                        </span>
                                                    </div>

                                                    <div className='flex justify-between py-[16px] border-t-[1px] dark:border-darkgrey'>
                                                        <span className='text-darkgrey dark:text-offwhite'>
                                                            Paid by customer
                                                        </span>
                                                        <span>
                                                            {attachedOrder.financial_status === 'paid'
                                                                ? formatMoney(Number(attachedOrder.total_price))
                                                                : attachedOrder.financial_status === 'partially_paid'
                                                                  ? formatMoney(
                                                                        Number(attachedOrder.total_price) -
                                                                            Number(attachedOrder.total_outstanding)
                                                                    )
                                                                  : formatMoney(0)}
                                                        </span>
                                                    </div>

                                                    {attachedOrder.financial_status === 'authorized' && (
                                                        <div className='flex p-[16px] pb-[0] w-[calc(100%+32px)] relative left-[-16px] border-t-[1px] dark:border-darkgrey'>
                                                            <button
                                                                onClick={() => {
                                                                    window.open(
                                                                        `https://factorypure.myshopify.com/admin/orders/${attachedOrder.id}`,
                                                                        '_blank'
                                                                    )
                                                                }}
                                                                className='ml-auto bg-[#007a5c] hover:bg-[#125443] dark:bg-fire dark:hover:bg-[darkorange] rounded py-[8px] px-[16px] items-center justify-center text-[14px] font-semibold text-white dark:text-black !border-[1px] border-darkgrey'
                                                            >
                                                                Capture Payment
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                        {task.order_id ||
                            (task.draft_order_id && (
                                <div className='w-[100%] dark:bg-darkaccent relative mb-[16px] p-[8px] pt-[18px] bg-white rounded shadow-small'>
                                    <h3 className='font-bai absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content]  items-center'>
                                        {task.task_type === 'Sales' ? 'Draft ' : ''}Order Notes
                                    </h3>
                                    {task.draft_order_id && (
                                        <DraftNewNoteField
                                            order={order}
                                            setOrder={setOrder}
                                            tasks={[task]}
                                            task_type={'Sales'}
                                        />
                                    )}
                                    {task.order_id && (
                                        <NewNoteField
                                            order={order}
                                            setOrder={setOrder}
                                            tasks={[task]}
                                            task_type={task.task_type}
                                        />
                                    )}
                                    <div className='grid gap-[8px] mt-[8px]'>
                                        {createOrderNotesArray(order.note, userList)
                                            ?.map((n, i) => (
                                                <OrderNote
                                                    index={i}
                                                    userList={userList}
                                                    note={n}
                                                    state={order}
                                                    setState={setOrder}
                                                />
                                            ))
                                            .reverse()}
                                    </div>
                                </div>
                            ))}
                        {task.task_type === 'Sales' && (
                            <div className='w-[100%] dark:bg-darkaccent relative mb-[16px] p-[8px] pt-[18px] bg-white rounded shadow-small'>
                                <h3 className='font-bai absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content]  items-center'>
                                    Task Notes
                                </h3>
                                <textarea
                                    className='min-h-[175px] task-screen__notes__textarea block w-[100%] p-[8px] m-0 max-w-none border-[1px] border-[#cdcdcd] rounded mt-[8px] dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue '
                                    name='task_notes'
                                    defaultValue={task.task_notes}
                                    onChange={(e: any) => handleChangeTask(e)}
                                    maxLength={4940}
                                ></textarea>
                            </div>
                        )}
                        <div className=''>
                            {edited && (
                                <button
                                    onClick={task.status === 'OPEN' ? handleUpdate : handleReopen}
                                    className='block py-[8px] px-[16px] bg-blue text-white border-0 font-bold text-[24px] uppercase my-[8px] mx-[auto] cursor-pointer shadow-small dark:shadow-cool'
                                >
                                    {task.status === 'OPEN' ? 'Update Task' : 'Reopen Task'}
                                </button>
                            )}
                        </div>

                        <div className='w-[100%] dark:bg-darkaccent relative mb-[16px] p-[8px] pt-[18px] bg-white rounded shadow-small'>
                            <h3 className='font-bai absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white py-[2px] px-[4px] mb-[8px] w-[fit-content]  items-center'>
                                Task History
                            </h3>
                            <div className='relative dark:bg-darkaccent'>
                                <textarea
                                    className='h-[75px] rounded-br-none min-h-[75px] task-screen__notes__textarea block w-[100%] p-[8px] m-0 max-w-none border-[1px] border-[#cdcdcd] rounded mt-[8px] dark:focus:outline-1 dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue'
                                    name='new_comment'
                                    placeholder='Add a comment...'
                                    value={comment}
                                    onChange={handleChangeComment}
                                    required
                                />
                                <button
                                    className='block relative mr-0 ml-[auto] top-0 py-[8px] px-[16px] bg-blue text-white font-bold dark:bg-accent dark:text-darkness border-none text-center uppercase rounded-b cursor-pointer'
                                    onClick={(e: any) => handleSubmitComment(e)}
                                >
                                    Add Comment
                                </button>
                            </div>
                            {/* <ul className="after:content-[''] after:absolute after:w-[2px] after:h-[calc(100%)] after:bg-[rgb(0,60,90)] after:left-[7px] after:top-[-36px] flex dark:bg-darkaccent relative flex-wrap w-[100%] p-[16px] pl-0 list-none gap-[16px] z-[0]">
                                {task.timeline_events.map((event, eventIndex) => {
                                    return renderTaskTimelineEvent(event, originalTask, eventIndex)
                                })}
                            </ul> */}
                            <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                                <TaskScreenEvents events={events} userList={userList} task={task} />
                            </div>
                        </div>
                    </div>
                </div>
                {(user.type == 'SuperAdmin' || user.type == 'Admin') && (
                    <div className='grid mt-[32px] text-center font-bold justify-center'>
                        <h2 className='text-[24px] font-bold tracking-[2px] uppercase dark:text-fire'>DANGER ZONE</h2>
                        <div className='my-[16px]'>
                            <h3 className='dark:text-fire'>WARNING: THIS BUTTON COMPLETELY DELETES THE TASK</h3>
                            <button
                                className='text-black text-[12px] font-semibold p-[5px] bg-[rgb(255,200,200)] border-[2px] border-[rgb(255,0,0)] rounded my-[16px] cursor-pointer'
                                onClick={handleDelete}
                            >
                                DELETE TASK
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {customer && order && (
                <EmailModal
                    eventSource={eventSource}
                    mode={mode}
                    setMode={setMode}
                    handleSubmitEmailEvent={onEmailSubmit}
                    customer={customer}
                    order={order}
                />
            )}
            {taskEvents && viewModal && (
                <PrivateEventModal origin='task' userList={userList} setViewModal={setViewModal} events={taskEvents} />
            )}
        </>
    )
}

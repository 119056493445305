import { Button } from 'custom_components/component_Basics/Button'
import { useEffect, useState } from 'react'
import { useUpdateTask } from 'tasksV2/api/useQueries'

const HTMLInputFormatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
}).format

export default function TaskAlert({
    taskId,
    alertOffset,
    sendEmailAlert,
    sendSlackAlert,
    mutation,
}: {
    taskId: number
    alertOffset: string
    sendEmailAlert: 1 | 0
    sendSlackAlert: 1 | 0
    mutation: any
}) {
    const [alertDetails, setAlertDetails] = useState<any>({
        alert_offset: alertOffset,
        send_slack_alert: sendSlackAlert,
        send_email_alert: sendEmailAlert,
    })

    const originalInput = {
        alert_offset: alertOffset,
        send_slack_alert: sendSlackAlert,
        send_email_alert: sendEmailAlert,
    }

    const edited = !!Object.keys(alertDetails).find((key) => {
        return alertDetails[key as keyof typeof alertDetails] !== originalInput[key as keyof typeof originalInput]
    })

    function parseDateToPretendISO(date: Date) {
        const dateString = HTMLInputFormatter(date)
        const [month, day, year] = dateString.split('/')
        return new Date(
            `${`${year}-${month}-${day}`}T${`${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
                -2
            )}`}:00Z`
        ).toISOString()
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement }) => {
        if (target.name === 'alert_offset' && !target.value) {
            return setAlertDetails({
                ...alertDetails,
                alert_offset: null,
            })
        }
        setAlertDetails({
            ...alertDetails,
            [target.name]: target.value,
        })
    }
    const handleSaveAlert = () => {
        mutation.mutate({
            id: taskId,
            ...alertDetails,
            sent_alert: 0,
            sent_alert_at: 0,
        })
    }

    useEffect(() => {
        setAlertDetails({
            alert_offset: alertOffset,
            send_slack_alert: sendSlackAlert,
            send_email_alert: sendEmailAlert,
        })
    }, [alertOffset, sendSlackAlert, sendEmailAlert])
    return (
        <div className='flex gap-2'>
            <div className='flex flex-col gap-1 items-center'>
                <label htmlFor='send_slack_alert' className='text-xs font-bold leading-none cursor-pointer'>
                    Slack
                </label>
                <input
                    className='cursor-pointer mt-[5px]'
                    type='checkbox'
                    id='send_slack_alert'
                    name='send_slack_alert'
                    checked={alertDetails.send_slack_alert === 1}
                    onChange={handleChange}
                />
            </div>
            <div className='flex flex-col gap-1 items-center'>
                <label htmlFor='send_email_alert' className='text-xs font-bold leading-none cursor-pointer'>
                    Email
                </label>
                <input
                    className='cursor-pointer mt-[5px]'
                    type='checkbox'
                    id='send_email_alert'
                    name='send_email_alert'
                    checked={alertDetails.send_email_alert === 1}
                    onChange={handleChange}
                />
            </div>
            <div className='flex flex-col gap-1 w-full'>
                <label htmlFor='alert_offset' className='text-xs font-bold leading-none cursor-pointer'>
                    When
                </label>
                <select
                    className='p-1 rounded leading-none text-sm'
                    id='alert_offset'
                    name='alert_offset'
                    value={alertDetails.alert_offset}
                    onChange={handleChange}
                >
                    <option value=''>Do not remind</option>
                    <option value='0'>When Due</option>
                    <option value='15'>15 Minutes Before</option>
                    <option value='30'>30 Minutes Before</option>
                    <option value='60'>1 Hour Before</option>
                    <option value='1440'>1 Day Before</option>
                </select>
            </div>
            {edited && (
                <Button onClick={handleSaveAlert} className='h-fit p-1 self-end'>
                    Confirm
                </Button>
            )}
        </div>
    )
}
